import "./formTrab.scss";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row, Spin, DatePicker, Select, Tooltip, Radio, Table, Icon, notification, Descriptions, Modal } from "antd";
import apiConfig from '../../../../../config/api'
import { FormLayout } from "../../../../../layouts";
import { withRouter } from "react-router-dom";
import { getFormPromise, getParamsPromise, sendFormPromise, signCDIFormPromise, saveFormCDItrabPromise, getParamsUboFinderPromise } from "../../../promises";
import moment from "moment";
import Logo from "../../../../../layouts/commonComponent/components/Logo/Logo";

import { validateRutHelper, validateCompanyRutHelper, } from "../../../../../helpers";
import HelloSign from 'hellosign-embedded';

const FormTrab = ({ form, match, formId }) => {
  const { t } = useTranslation();
  const { getFieldDecorator, validateFields, setFields, getFieldsError, setFieldsValue } = form;
  const [isValidate, setIsValidate] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [format, setFormat] = useState("html");

  const [date, setDate] = useState(0);
  const [user, setUser] = useState({});
  const [subclienteId, setSubclienteId] = useState("0");
  const [apiForm, _setApiForm] = useState(null);
  const [params, setParams] = useState({});
  const [colLogo, setColLogo] = useState(4);
  const [openContent, setopenContent] = useState(false);
  const [validarRegistros, setValidarRegistros] = useState(false);
  const [sentErrors, setSentErrors] = useState(0);
  const [clientes, setClientes] = useState(null);

  const [signed, setSigned] = useState(false);
  const [openSigner, setOpenSigner] = useState(false);
  const [hasSign, setHasSign] =  useState(true);
  const [signData, setSignData] = useState(null);
  const apiFormRef = useRef(apiForm);

  const setApiForm = data => {
    apiFormRef.current = data;
    _setApiForm(data);
  };

  let registersStop = {
    family: false,
    relProvCont: false,
    relLabAnt: false,
    outsideActivities: false,
    relCompetitor: false,
    relRelationship: false,
    relPepFp: false,
    relFundPjsfl: false,
    othersCDI: false,
  };

  const toDescriptionsPdf = (collection, stateObject) => (
    <>
      {collection.map((collectionObject, index) => (
        <>
          <div className="descriptions-pdf">
            <h4 className="descriptions-numeral">#{index + 1}</h4>
            <Descriptions title="" column={1} bordered size="small">
              {Object.keys(stateObject).map((key) => {
                return (
                  <Descriptions.Item label={stateObject[key].title}>
                    {collectionObject[stateObject[key].key]}
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          </div>
          <br />
        </>
      ))}
    </>
  );

  const handleOnClear = (section)=>{
    switch (section) {
      case 'malla':
        setMalla({...mallaIS})
        setFieldsValue(Object.keys(mallaIS).reduce((acu,key)=>{
          return {...acu,[key]:null}
        },{}))
        break;

      case 'relationProveedor':
        setRelationProveedor({...relationProveedorIS})
        setFieldsValue(Object.keys(relationProveedorIS).reduce((acu,key)=>{
          return {...acu,[key]:null}
        },{}))
        break;

      case 'laboral':
        setLaboral({...laboralIS})
        setFieldsValue(Object.keys(laboralIS).reduce((acu,key)=>{
          return {...acu,[key]:null}
        },{}))
        break;

      case 'outdoor':
        setOutdoor({...outdoorIS})
        setFieldsValue(Object.keys(outdoorIS).reduce((acu,key)=>{
          return {...acu,[key]:null}
        },{}))
        break;

      case 'competencia':
        setCompetencia({...competenciaIS})
        setFieldsValue(Object.keys(competenciaIS).reduce((acu,key)=>{
          return {...acu,[key]:null}
        },{}))
        break;

      case 'relationParentesco':
        setRelationParentesco({...relationParentescoIS})
        setFieldsValue(Object.keys(relationParentescoIS).reduce((acu,key)=>{
          return {...acu,[key]:null}
        },{}))
        break;

      case 'relationPep':
        setRelationPep({...relationPepIS})
        setFieldsValue(Object.keys(relationPepIS).reduce((acu,key)=>{
          return {...acu,[key]:null}
        },{}))
        break;

      case 'relationFundaciones':
        setRelationFundaciones({...relationFundacionesIS})
        setFieldsValue(Object.keys(relationFundacionesIS).reduce((acu,key)=>{
          return {...acu,[key]:null}
        },{}))
        break;

      case 'relationOtros':
        setRelationOtros({...relationOtrosIS})
        setFieldsValue(Object.keys(relationOtrosIS).reduce((acu,key)=>{
          return {...acu,[key]:null}
        },{}))
        break;

      default:
        break;
    }
  }

  const baseParamsIS = {
      gerencia:{key:'gerencia', max:100, id:'gerencia', readOnly:true, type:'input', placeholder: "Ingrese la gerencia"},
      cargo:{key:'cargo', max:100, id:'cargo', readOnly:true, type:'input', placeholder: "Ingrese el cargo que ocupa"},
      relationOtrosDescripcion:{key:'relationOtrosDescripcion', max:4000, id:'relationOtrosDescripcion', readOnly:true , type:'input'},
      fechaCargo:{key:'fechaCargo', max:10, id:'fechaCargo', readOnly:true , type:'date', placeholder: "Ingrese la fecha del cargo"},
  }
  const [baseParams, setBaseParams]=useState(baseParamsIS)

  const validateLengthBaseParam = (field)=>
  {
    return {
      max: field.max,
      message: "Debe tener un máximo de "+ field.max + " caracteres"
    }
  }

  const handleOnChangeIntro = async (fieldObj, value) => {
    let formToUpdate = { ...apiForm, [fieldObj.key]: value };
    let ret = await saveFormCDItrabPromiseLocal(formToUpdate);
    //if(!ret.success) { setFieldsValue({[field]: ret.form[field]}) }
  };


  const mallaIS = {
    mallaType: { key: "type", val: "", id:"mallaType",title:'Parentesco'},
    mallaName: { key: "name", val: "", id:"mallaName", maxLength:255, readOnly:true, type:'input', title:'Nombre' },
    mallaTipoDoc: { key: "tipoDoc", val: "", id:"mallaTipoDoc", title: "Tipo de documento de identidad"},
    mallaRut: { key: "rut", val: "", id:"mallaRut", maxLength:20, readOnly:true, type:'input',title:"Documento de Identidad" },
  }
  const [malla, setMalla] = useState(mallaIS);

  // SECTION I RELATION
  const relationProveedorIS = {
    provCompanyType: { key: "companyType", val: "", title: "Tipo de Empresa", id:"provCompanyType" },
    provCompanyName: { key: "companyName", val: "", title: "Nombre Empresa", maxLength:150, readOnly:true, type:'input', id:"provCompanyName" },
    provCompanyTipoDoc: { key: "companyTipoDoc", val: "", title: "Tipo de documento de la Empresa", id:"provCompanyTipoDoc" },
    provCompanyRut: { key: "companyRut", val: "", title: "Documento de identidad de la Empresa", maxLength:20, readOnly:true, type:'input', id:"provCompanyRut" },
    provRelationship: { key: "relationship", val: "", title: "Parentesco", id:"provRelationship" },
    provName: { key: "name", val: "", title: "Nombre de la Persona", maxLength:150, readOnly:true, type:'input',id:"provName" },
    provTipoDoc: { key: "tipoDoc", val: "", title: "Tipo de documento de la Persona",id:"provTipoDoc" },
    provRut: { key: "rut", val: "", title: "Documento de identidad de la Persona", maxLength:20, readOnly:true, type:'input',id:"provRut" },
    provPosition: { key: "position", val: "", title: "Cargo en la Empresa",id:"provPosition" }
  }
  const [relationProveedor, setRelationProveedor] = useState(relationProveedorIS);

  // SECTION II LABORAL
  const laboralIS = {
    laboralCompanyName: { key: "name",id:"laboralCompanyName", val: "", maxLength:150, readOnly:true, type:'input', title: "Nombre de Empresa" },
    laboralCompanyTipoDoc: { key: "tipoDoc", id:"laboralCompanyTipoDoc", val: "", title: "Tipo de Documento de la Empresa" },
    laboralCompanyRut: { key: "rut", id:"laboralCompanyRut", val: "", maxLength:20, readOnly:true, type:'input', title: "Documento de identidad de la Empresa" },
    laboralPosition: { key: "position", id:"laboralPosition", val: "", maxLength:150, readOnly:true, type:'input', title: "Cargo Ejercido" },
    laboralStartDate: { key: "startDate", id:"laboralStartDate", val: null, title: "Fecha de Ingreso",maxLength:10 },
    laboralEndDate: { key: "endDate", id:"laboralEndDate", val: null, title: "Fecha de Salida",maxLength:10 },
  }
  const [laboral, setLaboral] = useState(laboralIS);

  // SECTION III outdoor
  const outdoorIS = {
    outdoorCompanyName: { key: "companyName", id:"outdoorCompanyName", val: "", maxLength:150, readOnly:true, type:'input', title: "Nombre Sociedad o Institución" },
    outdoorCompanyTipoDoc: { key: "companyTipoDoc", id:"outdoorCompanyTipoDoc", val: "", title:"Tipo de documento de la empresa" },
    outdoorCompanyRut: { key: "companyRut", id:"outdoorCompanyRut", val: "", maxLength:20, readOnly:true, type:'input',title: "Documento de identidad de la Empresa" },
    outdoorActivity: { key: "activity", id:"outdoorActivity", val: "", maxLength:150, readOnly:true, type:'input', title:"Trabajo o Actividad laboral realizada" },
  }
  const [outdoor, setOutdoor] = useState(outdoorIS);

  // SECTION IV competencia
  const competenciaIS = {
    competenciaCompanyName: { key: "companyName",id:"competenciaCompanyName", val: "", maxLength:150, readOnly:true, type:'input',title: "Nombre Empresa de la Competencia" },
    competenciaCompanyTipoDoc: { key: "companyTipoDoc", id:"competenciaCompanyTipoDoc", val: "", title: "Tipo de documento de Empresa Competidora"},
    competenciaCompanyRut: { key: "companyRut", id:"competenciaCompanyRut", val: "", maxLength:20, readOnly:true, type:'input', title: "Documento de identidad de la Empresa Competidora" },
    competenciaRelationship: { key: "relationship", id:"competenciaRelationship", val: "", maxLength:20, readOnly:true, type:'input', title: "Parentesco"  },
    competenciaName: { key: "name",  id:"competenciaName", val: "",  maxLength:150, readOnly:true, type:'input', title: "Nombre de la Persona"  },
    competenciaTipoDoc: { key: "tipoDoc", id:"competenciaTipoDoc", val: "", title: "Tipo de documento de la Persona" },
    competenciaRut: { key: "rut", id:"competenciaRut", val: "", maxLength:20, readOnly:true, type:'input', title: "Documento de identidad de la Persona"  },
    competenciaPosition: { key: "position", id:"competenciaPosition", val: "", maxLength:50, readOnly:true, type:'input', title: "Cargo en la Empresa"},
  }
  const [competencia, setCompetencia] = useState(competenciaIS);

  // SECTION V relationParentesco
  const relationParentescoIS = {
    relationParentescoRelationship: { key: "relationship", id:"relationParentescoRelationship", val: "", title: "Parentesco" },
    relationParentescoName: { key: "name", id:"relationParentescoName", val: "", maxLength:150, readOnly:true, type:'input', title: "Nombre" },
    relationParentescoTipoDoc: { key: "tipoDoc", id:"relationParentescoTipoDoc", val: "", title: "Tipo de documento" },
    relationParentescoRut: { key: "rut", id:"relationParentescoRut", val: "", maxLength:20, readOnly:true, type:'input', title: "Documento de identidad"  },
    relationParentescoPosition: { key: "position", id:"relationParentescoPosition", val: "", maxLength:50, readOnly:true, type:'input', title: "Cargo" },
  }
  const [relationParentesco, setRelationParentesco] = useState(relationParentescoIS);

  // SECTION VI relatioPEP
  const relationPepIS = {
    relationPepRelationship: { key: "field1", id:"relationPepRelationship", val: "", title: "Parentesco", },
    relationPepName: { key: "field2", id:"relationPepName", val: "", maxLength:150, readOnly:true, type:'input', title: "Nombre del PEP o Funcionario Público "  },
    relationPepTipoDoc: { key: "field3", id:"relationPepTipoDoc", val: "", title: "Tipo de documento" },
    relationPepRut: { key: "field4", id:"relationPepRut", val: "", maxLength:20, readOnly:true, type:'input', title: "Documento de identidad"  },
    relationPepCategory: { key: "field5", id:"relationPepCategory", val: "", title: "Categoría" },
    relationPepInstitution: { key: "field6", id:"relationPepInstitution", val: "", maxLength:100, readOnly:true, type:'input', title: "Institución u Organismo Público "  },
    relationPepPosition: { key: "field7", id:"relationPepPosition", val: "", maxLength:50, readOnly:true, type:'input', title: "Cargo" },
    relationPepEndDate: { key: "field8", id:"relationPepEndDate", val: null, title: "Fecha de Término", },
  }
  const [relationPep, setRelationPep] = useState(relationPepIS);

  // SECTION VII relationFundaciones
  const relationFundacionesIS = {
    relationFundacionesCompanyName: { key: "companyName", id:"relationFundacionesCompanyName", val: "", maxLength:150, readOnly:true, type:'input', title: "Nombre de la Institución"  },
    relationFundacionesCompanyTipoDoc: { key: "companyTipoDoc", id:"relationFundacionesCompanyTipoDoc", val: "", title: "Tipo de documento de la Institución", },
    relationFundacionesCompanyRut: { key: "companyRut", id:"relationFundacionesCompanyRut", val: "", maxLength:20, readOnly:true, type:'input', title: "Documento de identidad de la Institución"  },
    relationFundacionesCompanyType: { key: "companyType", id:"relationFundacionesCompanyType", val: "", title: "Tipo de Institución", },
    relationFundacionesPosition: { key: "position", id:"relationFundacionesPosition", val: "", title: "Cargo en la Institución", },
    relationFundacionesRelationship: { key: "relationship", id:"relationFundacionesRelationship",  val: "", title: "Parentesco", },
    relationFundacionesName: { key: "name", id:"relationFundacionesName", val: "", maxLength:150, readOnly:true, type:'input', title: "Nombre de la Persona"  },
    relationFundacionesTipoDoc: { key: "tipoDoc", id:"relationFundacionesTipoDoc", val: "", title: "Tipo de documento de la Persona" },
    relationFundacionesRut: { key: "rut", val: "", id:"relationFundacionesRut", maxLength:20, readOnly:true, type:'input', title: "Documento de identidad de la Persona"  },
    // relationFundacionesArea: { key: "area", id:"relationFundacionesArea", val: "", maxLength:50, readOnly:true, type:'input', title: "Área con la que se relaciona"},
  }
  const [relationFundaciones, setRelationFundaciones] = useState(relationFundacionesIS);

  const relationOtrosIS = {
    relationOtrosDescripcion: ""
  }
  const [relationOtros, setRelationOtros] = useState(relationOtrosIS);


  const validateLengthFieldWithInnerLength = (section)=>{
    return {
      max: section.maxLength,
      message: "Debe tener un máximo de "+ section.maxLength  + " caracteres"
    }
  }

  useEffect(() => {
    let idForm = formId ? formId : match.params.id;
    setIsloading(true);

    getFormPromise(idForm).then((response) => {
      if (
        response.data !== null &&
        response.data !== "" &&
        response.data.status !== undefined
      ) {
        let responseData = response.data;
        setApiForm(responseData);
        setUser(response.data.recipient.request.createUser);
        setClientes(responseData.recipient.request.createUser.cliente.clientes)
        if(response.data.recipient.record.subcliente && responseData.recipient.request.createUser.cliente && responseData.recipient.request.createUser.cliente.outsourcer) {
          setSubclienteId(response.data.recipient.record.subcliente.id)
        }

        let clientId = response.data.recipient.request.createUser.cliente.id
        getParamsPromise(clientId).then((response) => {
          setParams(response.data);
        });

        getParamsUboFinderPromise(clientId).then((response) => {
          setHasSign(response.data.firma !== "N");
        });

        if(responseData.status === "SENT") {
          setDate(moment(responseData.receiveDate).format("DD-MM-YYYY"));
        }else {
          setDate(moment().format("DD-MM-YYYY"));
        }
      }
      setIsloading(false);
      if (match.params.view === "pdf") {
        setColLogo(5);
        setFormat("pdf");
      }
    });


    const handleMessage = (message) => {
      if(message.data && typeof message.data === 'string' && message.data.startsWith('DOCUSIGN') && apiConfig.url.startsWith(message.origin)) {
        const data = message.data.split('|')
        if(data.length === 4) {
          if(data[1] === "signing_complete") {
            const reqId = data[2] + "|" + data[3]
            sendDocument(reqId, apiFormRef.current)
          }else {
            closeHandlerSigner();
            notification["warning"]({
              message: "Firma cancelada",
            });
          }
        }
      }
    }

    window.addEventListener("message", handleMessage);
    return function () {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const closeHandlerSigner = () => {
    setSignData(null)
    setOpenSigner(false)
  }

  const saveFormCDItrabPromiseLocal = async (form) => {
    let response = await saveFormCDItrabPromise(form);
    if(response.code !== 'OK' && response.form !== undefined && response.form !== null) {
      setApiForm(response.form)
      if(response.code === 'ERROR') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
        });
      }else if(response.code === 'E01') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de la declaración'
        });
      }else if(response.code === 'E02') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de la declaración'
        });
      }else if(response.code === 'E03') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de la declaración'
        });
      }else if(response.code === 'E04') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al limpiar datos'
        });
      }else if(response.code === 'E05') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Participación en Otras Sociedades'
        });
      }else if(response.code === 'E06') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Relaciones laborales anteriores'
        });
      }else if(response.code === 'E07') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Participación en la Propiedad'
        });
      }else if(response.code === 'E08') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de PEP/FP'
        });
      }else if(response.code === 'E09') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Otros conflictos de interés'
        });
      }else if(response.code === 'E10') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Malla Parental'
        });
      }else if(response.code === 'E11') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Actividades'
        });
      }else if(response.code === 'E12') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Otras sociedades o personas jurídicas'
        });
      }else if(response.code === 'E13') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Personas juridicas sin fines de lucro'
        });
      }else if(response.code === 'E14') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Servicios de asesoria y consultoria'
        });
      }else if(response.code === 'E15') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Partes relacionadas'
        });
      }else if(response.code === 'E16') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Partes relacionadas'
        });
      }else if(response.code === 'E17') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Interes en la propiedad'
        });
      }else if(response.code === 'E18') {
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: 'Error al grabar datos de Responsabilidad penal'
        });
      }
      return { success: false, form: response.form}
    }else {
      setApiForm(form)
      return { success: true }
    }
  }

  const hancleOnChangeRadioButton = async (field, value) => {
    if(!signed) {
      let formToUpdate = { ...apiForm, [field]: value };
      let ret = await saveFormCDItrabPromiseLocal(formToUpdate);
      if(!ret.success) {
        setFieldsValue({[field]: ret.form[field]})
      }
    }
  };

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  const sendDocument = (requestId = "", formObj = apiForm) => {
    sendFormPromise(match.params.id, requestId).then((response) => {
      if (response.code === "OK") {
        let formSend = { ...formObj, status: "SENT" };
        setApiForm(formSend);
      } else {
        let errores = sentErrors+1
        setSentErrors(errores)

        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: errores === 1 ? 'Error Interno: Actualice y reintente.' : 'Comuníquese con soporte.aml@gesintel.cl'
        });
        setApiForm(response.form);
      }
    });
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    setValidarRegistros(true);
    setIsValidate(true);
    validateFields([
      "gerencia",
      "cargo",
      "fechaCargo",
      "hasRelProvCont",
      "hasOutsideActivities",
      "hasRelCompetitor",
      "hasRelRelationship",
      "hasRelPepFp",
      "hasRelFundPjsfl",
      "hasOthersCDI",
      "hasRelLabAnt",
    ]);

    /*
    if (apiForm.family.length < 2) {
      validateFields(Object.keys(malla));
      registersStop = { ...registersStop, family: true };
    }
    */

    if (apiForm.hasRelProvCont && apiForm.relProvCont.length === 0) {
      validateFields(Object.keys(relationProveedor));
      registersStop = { ...registersStop, relProvCont: true };
    }

    if (apiForm.hasRelLabAnt && apiForm.relLabAnt.length === 0) {
      validateFields(Object.keys(laboral));
      registersStop = { ...registersStop, relLabAnt: true };
    }

    if (
      apiForm.hasOutsideActivities &&
      apiForm.outsideActivities.length === 0
    ) {
      validateFields(Object.keys(outdoor));
      registersStop = { ...registersStop, outsideActivities: true };
    }

    if (apiForm.hasRelCompetitor && apiForm.relCompetitor.length === 0) {
      validateFields(Object.keys(competencia));
      registersStop = { ...registersStop, relCompetitor: true };
    }

    if (apiForm.hasRelRelationship && apiForm.relRelationship.length === 0) {
      validateFields(Object.keys(relationParentesco));
      registersStop = { ...registersStop, relRelationship: true };
    }

    if (getRadioButtonValue('PEP') && getFilteredRegistersByType('PEP').length === 0) {
      validateFields(Object.keys(relationPep));
      registersStop = { ...registersStop, relPepFp: true };
    }

    if (apiForm.hasRelFundPjsfl && apiForm.relFundPjsfl.length === 0) {
      validateFields(Object.keys(relationFundaciones));
      registersStop = { ...registersStop, relFundPjsfl: true };
    }

    if (apiForm.hasOthersCDI && apiForm.othersCDI.length === 0) {
      validateFields(Object.keys(relationOtros));
      registersStop = { ...registersStop, othersCDI: true };
    }

    if (
      hasErrors(getFieldsError()) ||
      Object.values(registersStop).find((value) => value === true) !== undefined
    ) {
      notification["warning"]({
        message: t("messages.aml.missingRequiredField"),
      });
    } else {
      setOpenSigner(true)
      if(hasSign) {
        let record = apiForm.recipient.record
        let _emails = ["no@email.com"];
  			if(record.email != null) {
  					_emails = record.email.split(",");
  			}
  			var _email = _emails[0].trim();
        let signParams = await signCDIFormPromise(apiForm.id, _email)
        setSignData(signParams)
        if(signParams.embedUrl == "signed") {
          notification["warning"]({
            message: "Ya esta firmado"
          })
          setSigned(true)
          sendDocument(signParams.requestId)
        }else {
          if(signParams.embedUrl) {
            if(signParams.provider == "HELLOSIGN") {
              if(signParams.clientId != "") {
                const client = new HelloSign({
                  clientId: signParams.clientId
                });
                let testMode = !apiConfig.url.startsWith('https://api.amlupdate')
                client.open(signParams.embedUrl, {
                  testMode
                });
                client.on('sign', () => {
                  setSigned(true)
                  sendDocument(signParams.requestId)
                });
              }else {
                let errores = sentErrors+1
                setSentErrors(errores)

                notification["error"]({
                  message: "Error al iniciar servicio de Firma Electronica",
                  description: errores === 1 ? 'Error Interno: Actualice y reintente.' : 'Comuníquese con soporte.aml@gesintel.cl'
                })
              }
            }
          } else {
            notification["error"]({
              message: "Error al iniciar servicio de Firma Electronica",
              description: signParams.message
            })
          }
        }
      }else {
        sendDocument();
      }
      setOpenSigner(false)
    }
  };

  const renderFormItem = (formItem) => {
    return (
      <Col
        className={formItem.colClassName}
        span={formItem.wrapperCols}
        offset={formItem.offset}
      >
        <Form.Item
          className={formItem.itemClassName}
          label={formItem.label}
          labelCol={formItem.labelCol > 0 ? { span: formItem.labelCol } : {}}
          wrapperCol={
            formItem.labelCol > 0 ? { span: formItem.wrapperCol } : {}
          }
        >
          {formItem.tooltip ? (
            <Tooltip
              className="tooltip-form-field"
              title="El sistema requiere que se ingrese un Rut, en caso que el sujeto identificado no tenga Rut por favor ingresar tu mismo Rut "
            >
              {getFieldDecorator(formItem.name, {
                rules: formItem.rules,
                validateTrigger: "onChange",
              })(formItem.item)}
            </Tooltip>
          ) : (
            getFieldDecorator(formItem.name, {
              rules: formItem.rules,
              initialValue: formItem.initialValue,
              validateTrigger: "onChange",
            })(formItem.item)
          )}
        </Form.Item>
      </Col>
    );
  };

  const renderFormItemTable = ({ section, initialValue, cols=8, type='input', options=[], required=true, validator=null, customRule=null }) => {
    return renderFormItem({
      label: section.title,
      name: section.id,
      initialValue: initialValue,
      colClassName: "topLabel",
      labelCol: 0,
      wrapperCol: 0,
      rules:
      [
        { required: required, message: t( "messages.aml.requestedField")},
        ... validator ? [{ validator: validator }]:[],
        ...type==='input' ? [validateLengthFieldWithInnerLength(section)]:[],
        ...customRule ? [customRule]:[]
      ],
      wrapperCols: cols,
      item: (
        type === 'input' ?
          <Input
            autoComplete="off"
            onFocus= {(e)=>handleReadOnly(e.target.id,false,section.id)}
            onBlur= {(e)=>handleReadOnly(e.target.id,true,section.id)}
            readOnly = {section.readOnly}
            onChange={(e) => handleOnChangeField( section.id, e.target.value ) }
          />
          : format === 'html' ?
            type === 'select' ?
              <Select
                onChange={(value) => handleOnChangeField(section.id, value) }
              >
                { options.map((option) => (<Select.Option value={option.val}>{ option.text }</Select.Option>)) }
              </Select>
              :
              <DatePicker
                onFocus= {(e)=>handleReadOnly(e.currentTarget.id,false,section.id)}
                onBlur= {(e)=>handleReadOnly(e.currentTarget.id,true,section.id)}
                readOnly = {section.readOnly}
                format="DD/MM/YYYY"
                placeholder="Ingrese la fecha"
                onChange={(momentObj) => handleOnChangeField( section.id, momentObj ? moment(momentObj).format( "DD/MM/YYYY" ) : null ) }
              />
            :
            <Input/>
      ),
    })
}

  const renderFormItemBase = ({ section, title, cols=12, type='input', disabled=false, required = true, options=[], initialValue, validator=null }) => {
    return renderFormItem({
        label: title ? title : section.title,
        name: section.id,
        initialValue: initialValue,
        colClassName: "topLabel",
        labelCol: 0,
        wrapperCol: 0,
        rules: [
          { required: required, message: t("messages.aml.requestedField"), },
          { validator: validator },
          ...type==='input' ? [validateLengthBaseParam(section)]:[],
        ],
        wrapperCols: cols,
        item: (
          type === 'input' ?
            <Input
              disabled = {disabled}
              autoComplete="off"
              onFocus= {(e)=>handleReadOnly(e.target.id,false)}
              onBlur= {(e)=>handleReadOnly(e.target.id,true)}
              readOnly = {section.readOnly}
              onChange={(e) => handleOnChangeIntro(section, e.target.value) }
            />
          : format === 'html' ?
              type === 'select' ? <Select
                disabled = {disabled}
                onChange={(value) => handleOnChangeIntro(section, value) }
              >
              { options.map((option) => (<Select.Option value={option.val}>{ option.text }</Select.Option>)) }
              </Select>
              :
              <DatePicker
                onFocus= {(e)=>handleReadOnly(e.currentTarget.id,false)}
                onBlur= {(e)=>handleReadOnly(e.currentTarget.id,true)}
                readOnly = {section.readOnly}
                format="DD/MM/YYYY"
                placeholder="Ingrese la fecha"
                onChange={(momentObj) => handleOnChangeIntro( section, momentObj !== null ? moment(momentObj).format( "DD/MM/YYYY" ) : null ) }
              />
            :
            <Input/>
        )
      })
  }

  const renderFormItemIntro = ({section, initialValue, required = true, type = 'input'})=>{
    return (
      format === "pdf" ?
        <span className="input-value-field">{initialValue}</span>
      :
        <Form.Item className="introduction-item">
        {getFieldDecorator(section.id, {
          initialValue: initialValue,
          rules: [
            { required: required, message: t( "messages.aml.requestedField" ), },
          ],
        })(
          format === "html" ? (
            type === 'date' ?
              <DatePicker
                onFocus= {(e)=>handleReadOnly(e.currentTarget.id,false)}
                onBlur= {(e)=>handleReadOnly(e.currentTarget.id,true)}
                readOnly = {section.readOnly}
                format="DD/MM/YYYY"
                placeholder="Ingrese la fecha"
                onChange={(momentObj) => handleOnChangeIntro( section, momentObj ? moment(momentObj).format( "YYYY/MM/DD" ) : null ) }
              />
              :
              <Input
                autoComplete="off"
                onFocus= {(e)=>handleReadOnly(e.target.id,false)}
                onBlur= {(e)=>handleReadOnly(e.target.id,true)}
                readOnly = {section.readOnly}
                placeholder = {section.placeholder}
                onChange={(e) => handleOnChangeIntro( section, e.target.value ) }
              />
          ) : (
            <Input />
          )
        )}
      </Form.Item>
    )
  }

  const getFilteredRegistersByType = (type) => apiForm.attributes.filter(obj => obj.type===type)

  const handleOnChangeRadioButton2 = async (type,field, value) => {
    let hasCollections = apiForm.hasCollections
    if (hasCollections===null){
      hasCollections = []
    }
    const arrIndex = hasCollections.findIndex(obj=>obj.type === type)
    arrIndex > -1 ? hasCollections[arrIndex].value=value : hasCollections.push({type:type, value:value})
    let formToUpdate = { ...apiForm, hasCollections: hasCollections };
    let ret = await saveFormCDItrabPromiseLocal(formToUpdate);
    // if(!ret.success) {
    //   setFieldsValue({[field]: ret.form[field]})
    // }
  };

  const getRadioButtonValue = (type) => {
    let hasCollections = apiForm.hasCollections
    if (hasCollections===null){
      return null
    }
    const arrIndex = hasCollections.findIndex(obj=>obj.type === type)
    if (arrIndex === -1)
      return null
    else
      return hasCollections[arrIndex].value
  }

  const handleDeletAttributes = (id) => {
    return () => {
      const index = apiForm.attributes.findIndex(obj => obj.id === id)
      let attributes = [...apiForm.attributes];
      attributes.splice(index, 1);
      let formToUpdate = { ...apiForm, attributes: attributes };
      saveFormCDItrabPromiseLocal(formToUpdate);
    };
  };

  const handleOnAddAttributes = (objState,type,sectionId) => {
  setIsValidate(true);
  validateFields(Object.keys(objState)).then((error, values) => {
    const objStateOk = Object.keys(objState).reduce(
      (acc, e) => {
        return { ...acc, [objState[e].key]: objState[e].val, type: type };
      },
      {}
    );

    apiForm.attributes.push({ ...objStateOk });
    saveFormCDItrabPromiseLocal(apiForm).then(ret => {
      if(ret.success) handleOnClear(sectionId)
    });
  });
  registersStop[sectionId] = false;
};

  const handleReadOnly = (field,readOnly,sectionId=null)=>{
    if (sectionId===null){
      const key = Object.entries(baseParams).filter(([key,value])=>{
        return value.id === field
      })[0][0]
      setBaseParams({...baseParams,[key]:{...baseParams[key],readOnly:readOnly}})
    }else{
      settingStateObj(sectionId,readOnly)
    }
  }

  const settingStateObj=(sectionId,readOnly)=>{
    const [stateObject,stateObjectSetter] = customState(sectionId)
    stateObjectSetter({...stateObject,[sectionId]:{...stateObject[sectionId],readOnly:readOnly}})
  }

  const customState=(sectionId)=>{
    if (sectionId.startsWith('malla')) {
      return([malla,setMalla])
    }
    if (sectionId.startsWith('relationParentesco')) {
      return([relationParentesco,setRelationParentesco])
    }
    if (sectionId.startsWith('prov')) {
      return([relationProveedor,setRelationProveedor])
    }
    if (sectionId.startsWith('laboral')) {
      return([laboral,setLaboral])
    }
    if (sectionId.startsWith('outdoor')) {
      return([outdoor,setOutdoor])
    }
    if (sectionId.startsWith('competencia')) {
      return([competencia,setCompetencia])
    }
    if (sectionId.startsWith('relationParentesco')) {
      return([relationParentesco,setRelationParentesco])
    }
    if (sectionId.startsWith('relationPep')) {
      return([relationPep,setRelationPep])
    }
    if (sectionId.startsWith('relationFundaciones')) {
      return([relationFundaciones,setRelationFundaciones])
    }
  }

  const handleOnChangeField = (sectionId, value) => {
    const [stateObject,stateObjectSetter] = customState(sectionId)
    stateObjectSetter({ ...stateObject, [sectionId]: { ...stateObject[sectionId], val: value }});
    if (sectionId === "provRelationship" && value === "Mi persona") {
      setRelationProveedor({
        ...relationProveedor,
        [sectionId]: { ...relationProveedor[sectionId], val: value },
        provName: {
          ...relationProveedor["provName"],
          val: apiForm.recipient.record.nombre,
        },
        provRut: {
          ...relationProveedor["provRut"],
          val: apiForm.recipient.record.rut,
        },
      });
      setFields({
        provName: { value: apiForm.recipient.record.nombre },
        provRut: { value: apiForm.recipient.record.rut },
      });
    } else if (sectionId === "provRelationship" && value !== "Mi persona") {
      setRelationProveedor({
        ...relationProveedor,
        [sectionId]: { ...relationProveedor[sectionId], val: value },
        provName: { ...relationProveedor["provName"], val: "" },
        provRut: { ...relationProveedor["provRut"], val: "" },
      });
      setFields({
        provName: { value: "" },
        provRut: { value: "" },
      });
    } else
    if (sectionId === "relationPepRelationship" && value === "Mi persona") {
      setRelationPep({
        ...relationPep,
        [sectionId]: { ...relationPep[sectionId], val: value },
        relationPepName: {
          ...relationPep["relationPepName"],
          val: apiForm.recipient.record.nombre,
        },
        relationPepRut: {
          ...relationPep["relationPepRut"],
          val: apiForm.recipient.record.rut,
        },
      });
      setFields({
        relationPepName: { value: apiForm.recipient.record.nombre },
        relationPepRut: { value: apiForm.recipient.record.rut },
      });
    } else if (sectionId === "relationPepRelationship" && value !== "Mi persona") {
      setRelationPep({
        ...relationPep,
        [sectionId]: { ...relationPep[sectionId], val: value },
        relationPepName: { ...relationPep["relationPepName"], val: "" },
        relationPepRut: { ...relationPep["relationPepRut"], val: "" },
      });
      setFields({
        relationPepName: { value: "" },
        relationPepRut: { value: "" },
      });
    }else
    if (sectionId === "relationFundacionesRelationship" && value === "Mi persona") {
      setRelationFundaciones({
        ...relationFundaciones,
        [sectionId]: { ...relationFundaciones[sectionId], val: value },
        relationFundacionesName: {
          ...relationFundaciones["relationFundacionesName"],
          val: apiForm.recipient.record.nombre,
        },
        relationFundacionesRut: {
          ...relationFundaciones["relationFundacionesRut"],
          val: apiForm.recipient.record.rut,
        },
      });
      setFields({
        relationFundacionesName: { value: apiForm.recipient.record.nombre },
        relationFundacionesRut: { value: apiForm.recipient.record.rut },
      });
    } else if (
      sectionId === "relationFundacionesRelationship" && value !== "Mi persona"
    ) {
      setRelationFundaciones({
        ...relationFundaciones,
        [sectionId]: { ...relationFundaciones[sectionId], val: value },
        relationFundacionesName: {
          ...relationFundaciones["relationFundacionesName"],
          val: "",
        },
        relationFundacionesRut: {
          ...relationFundaciones["relationFundacionesRut"],
          val: "",
        },
      });
      setFields({
        relationFundacionesName: { value: "" },
        relationFundacionesRut: { value: "" },
      });
    }
  };

  const handleDeleteMalla = (index) => {
    return () => {
      //let temp = mallaCollection.filter(e => e.key !== record.key);    fake collection works ok
      //setMallaCollection(temp);
      //let temp = apiForm.family.filter(e => e.id !== record.id);

      let xx = [...apiForm.family];
      xx.splice(index, 1);
      let formToUpdate = { ...apiForm, family: xx };
      saveFormCDItrabPromiseLocal(formToUpdate);
    };
  };

  const handleOnAddRelationProveedor = () => {
    setIsValidate(true);
    validateFields(Object.keys(relationProveedor)).then((error, values) => {
      const relationProveedorOk = Object.keys(relationProveedor).reduce(
        (acc, e) => {
          return {
            ...acc,
            [relationProveedor[e].key]: relationProveedor[e].val,
          };
        },
        {}
      );
      apiForm.relProvCont.push({ ...relationProveedorOk });
      //formToUpdate =   {...apiForm,family: apiForm.relProvCont }

      saveFormCDItrabPromiseLocal(apiForm).then(ret => {
        if(ret.success) handleOnClear('relationProveedor')
      });
    });
    registersStop.relProvCont = false;
  };

  const handleDeleteRelationProveedor = (index) => {
    return () => {
      // let temp = relationProveedorCollection.filter(e => e.key !== record.key);
      // setRelationProveedorCollection(temp);

      let xx = [...apiForm.relProvCont];
      xx.splice(index, 1);
      let formToUpdate = { ...apiForm, relProvCont: xx };

      saveFormCDItrabPromiseLocal(formToUpdate);
    };
  };

  const handleOnChangeFieldLaboral = (field, value) => {
    setLaboral({ ...laboral, [field]: { ...laboral[field], val: value } });
  };

  const handleOnAddLaboralRelation = () => {
    setIsValidate(true);
    validateFields(Object.keys(laboral)).then((error, values) => {
      const laboralOk = Object.keys(laboral).reduce((acc, e) => {
        return { ...acc, [laboral[e].key]: laboral[e].val };
      }, {});
      apiForm.relLabAnt.push({ ...laboralOk });
      //formToUpdate =   {...apiForm,family: apiForm.family }

      saveFormCDItrabPromiseLocal(apiForm).then(ret => {
        if(ret.success) handleOnClear('laboral')
      });
    });
    registersStop.relLabAnt = false;
  };
  const handleDeleteLaboral = (index) => {
    return () => {
      let xx = [...apiForm.relLabAnt];
      xx.splice(index, 1);
      let formToUpdate = { ...apiForm, relLabAnt: xx };

      saveFormCDItrabPromiseLocal(formToUpdate);
    };
  };

  const handleOnAddOutdoor = () => {
    setIsValidate(true);
    validateFields(Object.keys(outdoor)).then((error, values) => {
      const outdoorOk = Object.keys(outdoor).reduce((acc, e) => {
        return { ...acc, [outdoor[e].key]: outdoor[e].val };
      }, {});

      apiForm.outsideActivities.push({ ...outdoorOk });

      saveFormCDItrabPromiseLocal(apiForm).then(ret => {
        if(ret.success) handleOnClear('outdoor')
      });
    });
    registersStop.outsideActivities = false;
  };

  const handleDeleteOutdoor = (index) => {
    return () => {
      let xx = [...apiForm.outsideActivities];
      xx.splice(index, 1);
      let formToUpdate = { ...apiForm, outsideActivities: xx };

      saveFormCDItrabPromiseLocal(formToUpdate);
    };
  };

  const handleOnAddCompetencia = () => {
    setIsValidate(true);
    console.log(competencia);

    validateFields(Object.keys(competencia)).then((error, values) => {
      const competenciaOk = Object.keys(competencia).reduce((acc, e) => {
        return { ...acc, [competencia[e].key]: competencia[e].val };
      }, {});

      apiForm.relCompetitor.push({ ...competenciaOk });

      saveFormCDItrabPromiseLocal(apiForm).then(ret => {
        if(ret.success) handleOnClear('competencia')
      });
    });
    registersStop.relCompetitor = false;
  };

  const handleDeleteCompetencia = (index) => {
    return () => {
      let xx = [...apiForm.relCompetitor];
      xx.splice(index, 1);
      let formToUpdate = { ...apiForm, relCompetitor: xx };

      saveFormCDItrabPromiseLocal(formToUpdate);
    };
  };

  const handleOnAddRelationParentesco = () => {
    setIsValidate(true);
    validateFields(Object.keys(relationParentesco)).then((error, values) => {
      const relationParentescoOk = Object.keys(relationParentesco).reduce(
        (acc, e) => {
          return {
            ...acc,
            [relationParentesco[e].key]: relationParentesco[e].val,
          };
        },
        {}
      );
      apiForm.relRelationship.push({ ...relationParentescoOk });

      saveFormCDItrabPromiseLocal(apiForm).then(ret => {
        if(ret.success) handleOnClear('relationParentesco')
      });
    });
    registersStop.relRelationship = false;
  };

  const handleDeleteRelationParentesco = (index) => {
    return () => {
      let xx = [...apiForm.relRelationship];
      xx.splice(index, 1);
      let formToUpdate = { ...apiForm, relRelationship: xx };

      saveFormCDItrabPromiseLocal(formToUpdate);
    };
  };

  const handleOnChangeFieldRelationPep = (field, value) => {
    setRelationPep({
      ...relationPep,
      [field]: { ...relationPep[field], val: value },
    });
    if (field === "relationPepRelationship" && value === "Mi persona") {
      setRelationPep({
        ...relationPep,
        [field]: { ...relationPep[field], val: value },
        relationPepName: {
          ...relationPep["relationPepName"],
          val: apiForm.recipient.record.nombre,
        },
        relationPepRut: {
          ...relationPep["relationPepRut"],
          val: apiForm.recipient.record.rut,
        },
      });
      setFields({
        relationPepName: { value: apiForm.recipient.record.nombre },
        relationPepRut: { value: apiForm.recipient.record.rut },
      });
    } else if (field === "relationPepRelationship" && value !== "Mi persona") {
      setRelationPep({
        ...relationPep,
        [field]: { ...relationPep[field], val: value },
        relationPepName: { ...relationPep["relationPepName"], val: "" },
        relationPepRut: { ...relationPep["relationPepRut"], val: "" },
      });
      setFields({
        relationPepName: { value: "" },
        relationPepRut: { value: "" },
      });
    }
  };

  const handleOnAddRelationPep = () => {
    setIsValidate(true);
    validateFields(Object.keys(relationPep)).then((error, values) => {
      const relationPepOk = Object.keys(relationPep).reduce((acc, e) => {
        return { ...acc, [relationPep[e].key]: relationPep[e].val };
      }, {});

      apiForm.relPepFp.push({ ...relationPepOk });
      saveFormCDItrabPromiseLocal(apiForm).then(ret => {
        if(ret.success) handleOnClear('relationPep')
      });
    });
    registersStop.relPepFp = false;
  };

  const handleDeleteRelationPep = (index) => {
    return () => {
      let xx = [...apiForm.relPepFp];
      xx.splice(index, 1);
      let formToUpdate = { ...apiForm, relPepFp: xx };

      saveFormCDItrabPromiseLocal(formToUpdate);
    };
  };

  const handleOnChangeFieldRelationFundaciones = (field, value) => {
    if (field === "relationFundacionesRelationship" && value === "Mi persona") {
      setRelationFundaciones({
        ...relationFundaciones,
        [field]: { ...relationFundaciones[field], val: value },
        relationFundacionesName: {
          ...relationFundaciones["relationFundacionesName"],
          val: apiForm.recipient.record.nombre,
        },
        relationFundacionesRut: {
          ...relationFundaciones["relationFundacionesRut"],
          val: apiForm.recipient.record.rut,
        },
      });
      setFields({
        relationFundacionesName: { value: apiForm.recipient.record.nombre },
        relationFundacionesRut: { value: apiForm.recipient.record.rut },
      });
    } else if (
      field === "relationFundacionesRelationship" && value !== "Mi persona"
    ) {
      setRelationFundaciones({
        ...relationFundaciones,
        [field]: { ...relationFundaciones[field], val: value },
        relationFundacionesName: {
          ...relationFundaciones["relationFundacionesName"],
          val: "",
        },
        relationFundacionesRut: {
          ...relationFundaciones["relationFundacionesRut"],
          val: "",
        },
      });
      setFields({
        relationFundacionesName: { value: "" },
        relationFundacionesRut: { value: "" },
      });
    } else {
      setRelationFundaciones({
        ...relationFundaciones,
        [field]: { ...relationFundaciones[field], val: value },
      });
    }
  };

  const handleOnAddRelationFundaciones = () => {
    setIsValidate(true);
    validateFields(Object.keys(relationFundaciones)).then((error, values) => {
      console.log(relationFundaciones);

      const relationFundacionesOk = Object.keys(relationFundaciones).reduce(
        (acc, e) => {
          return {
            ...acc,
            [relationFundaciones[e].key]: relationFundaciones[e].val,
          };
        },
        {}
      );

      apiForm.relFundPjsfl.push({ ...relationFundacionesOk });
      saveFormCDItrabPromiseLocal(apiForm).then(ret => {
        if(ret.success) handleOnClear('relationFundaciones')
      });
    });
    registersStop.relFundPjsfl = false;
  };

  const handleDeleteRelationFundaciones = (index) => {
    return () => {
      let xx = [...apiForm.relFundPjsfl];
      xx.splice(index, 1);
      let formToUpdate = { ...apiForm, relFundPjsfl: xx };

      saveFormCDItrabPromiseLocal(formToUpdate);
    };
  };

  const handleOnChangeFieldRelationOtros = (field, value) => {
    setRelationOtros({ ...relationOtros, [field]: value });
  };

  const handleOnAddRelationOtros = () => {
    setIsValidate(true);

    validateFields(Object.keys(relationOtros)).then((error, values) => {
      apiForm.othersCDI.push(relationOtros.relationOtrosDescripcion);
      saveFormCDItrabPromiseLocal(apiForm).then(ret => {
        if(ret.success) handleOnClear('relationOtros')
      });
    });
    registersStop.othersCDI = false;
  };

  const handleDeleteRelationOtros = (index) => {
    return () => {
      let xx = [...apiForm.othersCDI];
      xx.splice(index, 1);
      let formToUpdate = { ...apiForm, othersCDI: xx };
      saveFormCDItrabPromiseLocal(formToUpdate);
    };
  };

  //const relation = [{nombreEmpresa:'gesintel',tipoEmpresa:'limitada'}]

  const mallaColumns = [
    {
      title: "Parentesco",
      dataIndex: "type",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Tipo de documento de identidad",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "rut",
    },
    format === "html"
      ? {
          title: "Acción",
          dataIndex: "",
          key: "x",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteMalla(index)}>
              <Icon type="delete" />
            </a>
          ),
        }
      : {},
  ];

  const relationProveedorColumns = [
    {
      title: "Tipo de Empresa",
      dataIndex: "companyType",
    },
    {
      title: "Nombre de Empresa",
      dataIndex: "companyName",
    },
    {
      title: "Documento de Empresa",
      dataIndex: "companyRut",
    },
    {
      title: "Parentesco",
      dataIndex: "relationship",
    },
    {
      title: "Nombre de Persona",
      dataIndex: "name",
    },
    {
      title: "Documento de Persona",
      dataIndex: "rut",
    },
    {
      title: "Cargo en la Empresa",
      dataIndex: "position",
    },
    format === "html"
      ? {
          title: "Acción",
          dataIndex: "",
          key: "x",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteRelationProveedor(index)}>
              <Icon type="delete" />
            </a>
          ),
        }
      : {},
  ];

  // render: (text,record,index) => {
  //   if (record.pdfFile !== null) {
  //     return (<a onClick={() => handleDownloadReport(record)} ><Icon type="file-pdf" /></a>)
  //   } else {
  //     return (<Checkbox checked={record.checked} onChange ={handleOnChangeCheckPep(record)}/>)
  //   }
  // }

  const laboralColumns = [
    {
      title: "Nombre de Empresa",
      dataIndex: "name",
    },
    {
      title: "Documento de Empresa",
      dataIndex: "rut",
    },
    {
      title: "Cargo Ejercido",
      dataIndex: "position",
    },
    {
      title: "Fecha de Ingreso",
      dataIndex: "startDate",
      render: (text, record, index) =>
        text ? text : "",
    },
    {
      title: "Fecha de Salida ",
      dataIndex: "endDate",
      render: (text, record, index) =>
        text ? text : "",
    },
    format === "html"
      ? {
          title: "Acción",
          dataIndex: "",
          key: "x",

          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteLaboral(index)}>
              <Icon type="delete" />
            </a>
          ),
        }
      : {},
  ];

  const outdoorColumns = [
    {
      title: "Nombre Sociedad o Institución ",
      dataIndex: "companyName",
    },
    {
      title: "Tipo de documento de la Empresa",
      dataIndex: "companyTipoDoc",
    },
    {
      title: "Documento de Empresa",
      dataIndex: "companyRut",
    },
    {
      title: "Trabajo o Actividad laboral realizada ",
      dataIndex: "activity",
    },
    format === "html"
      ? {
          title: "Acción",
          dataIndex: "",
          key: "x",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteOutdoor(index)}>
              <Icon type="delete" />
            </a>
          ),
        }
      : {},
  ];

  const competenciaColumns = [
    {
      title: "Nombre Empresa de la Competencia ",
      dataIndex: "companyName",
    },
    {
      title: "Documento de Empresa de la Competencia ",
      dataIndex: "companyRut",
    },
    {
      title: "Parentesco",
      dataIndex: "relationship",
    },
    {
      title: "Nombre de la persona",
      dataIndex: "name",
    },
    {
      title: "Documento de la persona",
      dataIndex: "rut",
    },
    {
      title: "Cargo de la persona",
      dataIndex: "position",
    },
    format === "html"
      ? {
          title: "Acción",
          dataIndex: "",
          key: "x",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteCompetencia(index)}>
              <Icon type="delete" />
            </a>
          ),
        }
      : {},
  ];

  const relationParentescoColumns = [
    {
      title: "Parentesco",
      dataIndex: "relationship",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Documento",
      dataIndex: "rut",
    },
    {
      title: "Cargo",
      dataIndex: "position",
    },
    format === "html" && !signed
      ? {
          title: "Acción",
          dataIndex: "",
          key: "x",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteRelationParentesco(index)}>
              <Icon type="delete" />
            </a>
          ),
        }
      : {},
  ];

  const relationPepColumns = [
    {
      title: relationPep.relationPepRelationship.title,
      dataIndex: relationPep.relationPepRelationship.key,
    },
    {
      title: relationPep.relationPepName.title,
      dataIndex: relationPep.relationPepName.key,
    },
    {
      title: relationPep.relationPepCategory.title,
      dataIndex: relationPep.relationPepCategory.key,
    },
    {
      title: relationPep.relationPepInstitution.title,
      dataIndex: relationPep.relationPepInstitution.key,
    },
    {
      title: relationPep.relationPepPosition.title,
      dataIndex: relationPep.relationPepPosition.key,
    },
    format === "html" && !signed
      ? {
          title: "Acción",
          dataIndex: "",
          key: "x",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeletAttributes(record.id)}> <Icon type="delete" /> </a>
          ),
        }
      : {},
  ];

  const relationFundacionesColumns = [
    {
      title: "Nombre de la Institución ",
      dataIndex: "companyName",
    },
    {
      title: "Documento de la Institución",
      dataIndex: "companyRut",
    },
    {
      title: "Tipo de Institución",
      dataIndex: "companyType",
    },
    {
      title: "Cargo en la Institución ",
      dataIndex: "position",
    },
    {
      title: "Parentesco",
      dataIndex: "relationship",
    },
    {
      title: "Nombre de la persona ",
      dataIndex: "name",
    },
    {
      title: "Documento de la persona ",
      dataIndex: "rut",
    },
    format === "html" && !signed
      ? {
          title: "Acción",
          dataIndex: "",
          key: "x",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteRelationFundaciones(index)}>
              <Icon type="delete" />
            </a>
          ),
        }
      : {},
  ];

  const relationOtrosColumns = () => {
    let columns = [
      {
        title: "Descripción de la situación",
        dataIndex: "",
        width: "90%",
        render: (text, record, index) => {
          return record;
        },
      },
    ];
    if (format === "html" && !signed) {
      columns.push({
        title: "Acción",
        dataIndex: "",
        render: (text, record, index) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a onClick={handleDeleteRelationOtros(index)}>
            <Icon type="delete" />
          </a>
        ),
      });
    }
    return columns;
  };

  const rutValidator = (rule, value, cb) => {
    if (value && !validateRutHelper(value)) {
      cb("Documento no válido");
    }
    cb();
  };

  const rutValidatorCompany = (rule, value, cb) => {
    if (value && !validateCompanyRutHelper(value)) {
      cb("Documento no válido");
    }
    cb();
  };

  const docValidator = (tipoDoc, company = false) => {
    if (tipoDoc === "Chile-Rut")
      if (company === false) return rutValidator;
      else return rutValidatorCompany;
    else return null;
  };

  return (
    <FormLayout
      currentUser={{ userId: user.id, subclienteId }}
      view={format}
    >
      <div style={{ position: "relative" }}>
        {isLoading ? (
          <div className="form-header">
            <Row>
              <Col xs={9}></Col>
              <Col xs={6}>
                <div className="form-wrapper" style={{ textAlign: "center" }}>
                  <Spin style={{ fontColor: "#fff" }} size={"large"} />
                </div>
              </Col>
              <Col xs={9}></Col>
            </Row>
          </div>
        ) : (
          <>
            {apiForm !== null ? (
                <div
                    className={
                        "form-content" +
                        (!isValidate ? " form-validate-messages" : "")
                    }
                >
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit} className="form-form">
                        <Row
                            className="title-logo"
                            gutter={[0, 6]}
                            style={{
                              backgroundColor: "rgba(255,255,255,0.9)",
                              marginTop: "0px",
                              marginBottom: "0px",
                              textAlign: "left",
                              paddingTop: "10px",
                              paddingLeft: "10px",
                            }}
                        >
                          <Col
                              xs={24 - colLogo}
                              sm={24 - colLogo}
                              md={24 - colLogo}
                              lg={24 - colLogo}
                              xl={24 - colLogo}
                          >
                            <h3>
                              DECLARACIÓN DE CONFLICTO DE INTERÉS TRABAJADORES Y TRABAJADORAS
                            </h3>
                          </Col>
                          <Col
                              className="logo-col"
                              xs={colLogo}
                              sm={colLogo}
                              md={colLogo}
                              lg={colLogo}
                              xl={colLogo}
                          >
                            <Logo currentUser={{userId: user.id, subclienteId}} isForm={true}/>
                          </Col>
                        </Row>

                        <Row
                            className="date"
                            gutter={[0, 6]}
                            style={{
                              backgroundColor: "rgba(255,255,255,0.9)",
                              marginTop: "0px",
                              marginBottom: "0px",
                              textAlign: "left",
                              paddingBottom: "15px",
                              paddingLeft: "10px",
                            }}
                        >
                          <Col span={2} style={{textAlign: "left"}}>
                            Fecha:
                          </Col>

                          <Col span={4}>
                            <div
                                className="formDate"
                                style={{
                                  marginLeft: "5px",
                                  borderStyle: "solid",
                                  borderWidth: "1px",
                                  borderColor: "#e8e8e8",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                            >
                              {date}
                            </div>
                          </Col>
                          <Col span={13} style={{textAlign: "right"}}>
                            Folio:
                          </Col>
                          <Col span={5}>
                            <div
                                className="formDate"
                                style={{
                                  borderStyle: "solid",
                                  borderWidth: "1px",
                                  borderColor: "#e8e8e8",
                                  display: "flex",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                  marginLeft: "8px",
                                }}
                            >
                              {apiForm.folio !== null ? apiForm.folio : ""}
                            </div>
                          </Col>
                        </Row>

                        {apiForm.status === "SENT" && format === "html" ? (
                            <>
                              <br/>
                              <h3 style={{textAlign: "center"}}>
                                Estimado {apiForm.recipient.record.nombre}, le
                                informamos que su declaración fue correctamente
                                completada, agradecemos su tiempo y disposición.
                                <br/>
                                Hemos enviado una copia de la declaración realizada
                                al mail registrado: {apiForm.recipient.record.email}
                              </h3>
                            </>
                        ) : (
                            <>
                              <Row
                                  className="lineamiento subheader"
                                  style={{marginTop: "0px"}}
                              >
                                <Col xl={24}>INTRODUCCIÓN</Col>
                              </Row>
                              <Row className="summary">
                                <Col xl={24}>
                                  <p>
                                    Yo,{" "}
                                    <strong>{apiForm.recipient.record.nombre}</strong>
                                    ,{" "} cédula de indentidad número {" "}
                                    <strong>
                                      {apiForm.recipient.record.rut}
                                    </strong> {" "}
                                    persona trabajadora perteneciente a la Gerencia{" "}
                                    {renderFormItemIntro({
                                      section: baseParams.gerencia,
                                      initialValue: apiForm.gerencia,
                                    })}

                                    , en mi calidad de{" "}

                                    {renderFormItemIntro({
                                      section: baseParams.cargo,
                                      initialValue: apiForm.cargo,
                                    })}
                                    {" "}
                                    declaro bajo juramento
                                    que toda la información proporcionada en la presente Declaración Jurada, es del todo
                                    exacta,
                                    fehaciente y verdadera hasta donde tengo conocimiento y que no he omitido ningún
                                    tipo de
                                    información que pudiese ser relevante o pudiese producir un Conflicto de Interés
                                    para con La Compañía
                                    o sus empresas relacionadas, asumiendo desde ya, las
                                    responsabilidades tanto civiles como penales que me corresponderían en caso de
                                    falsedad o
                                    inexactitud de esta Declaración Jurada.
                                  </p>
                                  <p>
                                    Asimismo, declaro conocer, aceptar y haber leído, el Código de Ética, el Reglamento
                                    Interno
                                    de Orden, Higiene y Seguridad, y el Manual de Prevención del Delito de La Compañía,
                                    en adelante "La Empresa".
                                  </p>
                                  <p>
                                    También reconozco saber que tengo el deber de informar sobre cualquier situación que
                                    pueda
                                    dar origen a un potencial conflicto de interés en el desempeño de mis funciones
                                    encomendadas.
                                  </p>
                                  <p>
                                    Por todo lo anterior informo y declaro (marque el casillero correspondiente):
                                  </p>
                                </Col>
                              </Row>
                              <Row className="subheader">
                                <Col xl={24}>
                                  I. RELACIÓN CON PROVEEDORES, CONTRATISTAS, Y/O CLIENTES DE LA EMPRESA{" "}
                                </Col>
                              </Row>
                              <Row className="summary">
                                <Col span={21}>
                                  Declaro que tengo participación, directa o indirectamente, o lo hace mi cónyuge,
                                  conviviente civil, pareja o también lo hace algún
                                  pariente hasta el segundo grado de {" "}
                                  <Tooltip
                                      className="tooltip-form"
                                      title="La Empresa establece como parientes hasta el segundo grado de consanguinidad (padres, hijos, abuelos,
                                  hermanos, nietos) y hasta el segundo grado de afinidad (padres, hijos, abuelos, y hermanos del cónyuge);
                                  padres, hijos, abuelos y hermanos de la pareja de hecho; cónyuge o pareja de hecho de los hermanos del
                                  declarante (cuñados)."
                                  >
                                    consanguinidad o afinidad inclusive
                                  </Tooltip>{" "}
                                  en empresas que sean proveedoras, contratistas o
                                  clientes de La Compañía, ya sea como propietario, socio, accionista principal,
                                  director, o como
                                  ejecutivo principal, gerente, administrador, o con cualquier cargo que suponga
                                  capacidad para
                                  tomar decisiones y obligar al proveedor, contratista o cliente.
                                </Col>
                                {renderFormItem({
                                  label: "",
                                  name: "hasRelProvCont",
                                  initialValue:
                                      apiForm.hasRelProvCont !== null
                                          ? apiForm.hasRelProvCont
                                          : null,
                                  colClassName: "switch-col",
                                  itemClassName: "radio-item",
                                  labelCol: 0,
                                  wrapperCol: 0,
                                  offset: 0,
                                  rules: [
                                    {
                                      required: true,
                                      message: t("messages.aml.dontForgetSelect"),
                                    },
                                  ],
                                  wrapperCols: 3,
                                  item: (
                                      <Radio.Group
                                          onChange={({target}) =>
                                              hancleOnChangeRadioButton(
                                                  "hasRelProvCont",
                                                  target.value
                                              )
                                          }
                                      >
                                        <Radio className="radio-switch" value={true}>
                                          Sí
                                        </Radio>
                                        <Radio className="radio-switch" value={false}>
                                          No
                                        </Radio>
                                      </Radio.Group>
                                  ),
                                })}
                              </Row>
                              {/* {
                              format === "html"
                              ?
                              <>
                              </>
                              :
                              <>
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              </>
                          } */}
                              {((apiForm.hasRelProvCont !== null && apiForm.hasRelProvCont) || openContent) && (
                                  <Row className="content">
                                    {format === "html" && !signed && (
                                        <>
                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: relationProveedor.provCompanyType,
                                              type: 'select',
                                              options:
                                                  [
                                                    {val: "Proveedor", text: "Proveedor"},
                                                    {val: "Cliente Institucional", text: "Cliente Institucional"},
                                                    {val: "Contratista", text: "Contratista"},
                                                  ],
                                            })}
                                            {renderFormItemTable({
                                              section: relationProveedor.provCompanyName,
                                            })}
                                            {renderFormItemTable({
                                              section: relationProveedor.provCompanyTipoDoc,
                                              type: 'select',
                                              options: [{val: "Chile-Rut", text: "Chile-Rut"}, {
                                                val: "Otros",
                                                text: "Otros"
                                              }],
                                            })}
                                          </Row>
                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: relationProveedor.provCompanyRut,
                                              validator: docValidator(relationProveedor.provCompanyTipoDoc.val, true)
                                            })}
                                            {renderFormItemTable({
                                              section: relationProveedor.provRelationship,
                                              type: 'select',
                                              options:
                                                  [
                                                    {val: "Mi persona", text: "Mi persona"},
                                                    {val: "Conyuge", text: "Conyuge"},
                                                    {val: "Pareja", text: "Pareja"},
                                                    {val: "Conviviente civil", text: "Conviviente civil"},
                                                    {val: "Padre", text: "Padre"},
                                                    {val: "Madre", text: "Madre"},
                                                    {val: "Hijo(a)", text: "Hijo(a)"},
                                                    {val: "Hermano(a)", text: "Hermano(a)"},
                                                    {val: "Abuelo(a)", text: "Abuelo(a)"},
                                                    {val: "Nieto(a)", text: "Nieto(a)"},
                                                    {val: "Suegro(a)", text: "Suegro(a)"},
                                                    {
                                                      val: "Hijo(a) del conyuge que no sean suyos",
                                                      text: "Hijo(a) del conyuge que no sean suyos"
                                                    },
                                                    {val: "Abuelo(a) del cónyuge", text: "Abuelo(a) del cónyuge"},
                                                    {val: "Nieto(a) del cónyuge", text: "Nieto(a) del cónyuge"},
                                                    {val: "Hermano(a) del cónyuge", text: "Hermano(a) del cónyuge"},
                                                    {val: "Yerno", text: "Yerno"},
                                                    {val: "Nuera", text: "Nuera"},
                                                    {val: "Tío(a)", text: "Tío(a)"},
                                                    {val: "Sobrino(a)", text: "Sobrino(a)"},
                                                    {val: "Bisabuelo(a)", text: "Bisabuelo(a)"},
                                                    {val: "Biznieto(a)", text: "Biznieto(a)"},
                                                    {val: "Bisabuelo(a) del cónyuge", text: "Bisabuelo(a) del cónyuge"},
                                                    {val: "Cuñado(a)", text: "Cuñado(a)"},
                                                    {val: "Sobrino(a) del conyuge", text: "Sobrino(a) del conyuge"},
                                                  ],
                                            })}
                                            {renderFormItemTable({
                                              section: relationProveedor.provName,
                                            })}
                                          </Row>
                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: relationProveedor.provTipoDoc,
                                              type: 'select',
                                              options: [{val: "Chile-Rut", text: "Chile-Rut"}, {
                                                val: "Otros",
                                                text: "Otros"
                                              }],
                                            })}
                                            {renderFormItemTable({
                                              section: relationProveedor.provRut,
                                              validator: docValidator(relationProveedor.provTipoDoc.val)
                                            })}
                                            {renderFormItemTable({
                                              section: relationProveedor.provPosition,
                                              type: 'select',
                                              options:
                                                  [
                                                    {val: "Director", text: "Director"},
                                                    {val: "Socio", text: "Socio"},
                                                    {val: "Accionista", text: "Accionista"},
                                                    {val: "Propietario", text: "Propietario"},
                                                    {val: "Ejecutivo Principal", text: "Ejecutivo Principal"},
                                                    {val: "Administrador", text: "Administrador"},
                                                    {val: "Asesor", text: "Asesor"},
                                                  ],
                                            })}
                                          </Row>
                                          <Row className="button-row">
                                            {
                                                apiForm.relProvCont.length < 1 && validarRegistros && apiForm.hasRelProvCont && (
                                                    <Col
                                                        span={24}
                                                        className="missing-registers ant-form-explain"
                                                    >
                                                      {t("messages.aml.registersRequired")}
                                                    </Col>
                                                )
                                            }
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button"
                                                      onClick={handleOnAddRelationProveedor}
                                                      icon="plus"> Añadir </Button>
                                            </Col>
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button" icon="delete"
                                                      onClick={(e) => handleOnClear('relationProveedor')}> Limpiar </Button>
                                            </Col>
                                          </Row>
                                        </>
                                    )}

                                    {apiForm.relProvCont.length > 0 &&
                                    format === "html" ? (
                                        <Table columns={relationProveedorColumns} dataSource={apiForm.relProvCont}
                                               size="middle" pagination={false}></Table>
                                    ) : (
                                        toDescriptionsPdf(apiForm.relProvCont, relationProveedor)
                                    )}
                                  </Row>
                              )}
                              <Row className="subheader">
                                <Col xl={24}>
                                  II. RELACIONES LABORALES ANTERIORES (ÚLTIMOS 18 MESES)
                                </Col>
                              </Row>
                              <Row className="summary">
                                <Col span={21}>
                                  {/* {format !== "html" && ( <> <br /> <br /> </> )} */}
                                  Declaro que en los últimos 18 meses he tenido relaciones laborales anteriores, con un
                                  empleador distinto a La Empresa
                                  <br/>
                                </Col>
                                {renderFormItem({
                                  label: "",
                                  name: "hasRelLabAnt",
                                  initialValue:
                                      apiForm.hasRelLabAnt !== null
                                          ? apiForm.hasRelLabAnt
                                          : null,
                                  colClassName: "switch-col",
                                  itemClassName: "radio-item",
                                  labelCol: 0,
                                  wrapperCol: 0,
                                  offset: 0,
                                  rules: [
                                    {
                                      required: true,
                                      message: t("messages.aml.dontForgetSelect"),
                                    },
                                  ],
                                  wrapperCols: 3,
                                  item: (
                                      <Radio.Group
                                          onChange={({target}) =>
                                              hancleOnChangeRadioButton(
                                                  "hasRelLabAnt",
                                                  target.value
                                              )
                                          }
                                      >
                                        <Radio className="radio-switch" value={true}>
                                          Sí
                                        </Radio>
                                        <Radio className="radio-switch" value={false}>
                                          No
                                        </Radio>
                                      </Radio.Group>
                                  ),
                                })}
                              </Row>
                              {(apiForm.hasRelLabAnt || openContent) && (
                                  <Row className="content">
                                    {format === "html" && !signed && (
                                        <>
                                          <Row className="fields-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: laboral.laboralCompanyName,
                                            })}
                                            {renderFormItemTable({
                                              section: laboral.laboralCompanyTipoDoc,
                                              type: 'select',
                                              options: [{val: "Chile-Rut", text: "Chile-Rut"}, {
                                                val: "Otros",
                                                text: "Otros"
                                              }],
                                            })}
                                            {renderFormItemTable({
                                              section: laboral.laboralCompanyRut,
                                              validator: docValidator(laboral.laboralCompanyTipoDoc.val, true)
                                            })}
                                          </Row>

                                          <Row className="fields-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: laboral.laboralPosition,
                                            })}

                                            {renderFormItemTable({
                                              section: laboral.laboralStartDate,
                                              type: 'date',
                                              initialValue: laboral.laboralStartDate.val ? moment(laboral.laboralStartDate.val) : null,
                                            })}

                                            {renderFormItemTable({
                                              section: laboral.laboralEndDate,
                                              type: 'date',
                                              initialValue: laboral.laboralEndDate.val ? moment(laboral.laboralEndDate.val) : null,
                                            })}


                                          </Row>
                                          <Row className="button-row">
                                            {apiForm.relLabAnt.length < 1 && validarRegistros && apiForm.hasRelLabAnt && (
                                                <Col span={24}
                                                     className="missing-registers ant-form-explain"> {t("messages.aml.registersRequired")} </Col>
                                            )}
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button"
                                                      onClick={handleOnAddLaboralRelation} icon="plus"> Añadir </Button>
                                            </Col>
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button" icon="delete"
                                                      onClick={(e) => handleOnClear('laboral')}> Limpiar </Button>
                                            </Col>
                                          </Row>
                                        </>
                                    )}

                                    {apiForm.relLabAnt.length > 0 &&
                                    format === "html" ? (
                                        <Table
                                            columns={laboralColumns}
                                            dataSource={apiForm.relLabAnt}
                                            size="middle"
                                            pagination={false}
                                        ></Table>
                                    ) : (
                                        toDescriptionsPdf(apiForm.relLabAnt, laboral)
                                    )}
                                  </Row>
                              )}
                              <Row className="subheader">
                                <Col xl={24}>
                                  III. ACTIVIDADES FUERA DE LA EMPRESA Y DEL HORARIO LABORAL
                                </Col>
                              </Row>
                              <Row className="summary">
                                <Col span={21}>
                                  Declaro que:
                                  <ol type="a">
                                    <li>
                                      Realizo otros trabajos o actividades laborales que no entran en competencia con el
                                      giro de
                                      La Empresa ni en conflicto con sus intereses; o
                                    </li>
                                    <li>
                                      Participo o contribuyo en conferencias, seminarios, artículos, revistas e
                                      instituciones
                                      privadas o públicas; o
                                    </li>
                                    <li>
                                      Realizo actividades docentes de cualquier tipo, fuera de mi horario laboral.
                                    </li>
                                  </ol>
                                </Col>

                                {renderFormItem({
                                  label: "",
                                  name: "hasOutsideActivities",
                                  initialValue: apiForm.hasOutsideActivities !== null ? apiForm.hasOutsideActivities : null,
                                  colClassName: "switch-col",
                                  itemClassName: "radio-item",
                                  labelCol: 0,
                                  wrapperCol: 0,
                                  offset: 0,
                                  rules: [{required: true, message: t("messages.aml.dontForgetSelect"),},],
                                  wrapperCols: 3,
                                  item: (
                                      <Radio.Group
                                          onChange={({target}) => hancleOnChangeRadioButton("hasOutsideActivities", target.value)}
                                      >
                                        <Radio className="radio-switch" value={true}>
                                          Sí
                                        </Radio>
                                        <Radio className="radio-switch" value={false}>
                                          No
                                        </Radio>
                                      </Radio.Group>
                                  ),
                                })}
                              </Row>
                              {(apiForm.hasOutsideActivities || openContent) && (
                                  <Row className="content">
                                    {format === "html" && !signed && (
                                        <>
                                          <Row className="fields-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: outdoor.outdoorCompanyName,
                                            })}
                                            {renderFormItemTable({
                                              section: outdoor.outdoorCompanyTipoDoc,
                                              type: 'select',
                                              options: [{val: "Chile-Rut", text: "Chile-Rut"}, {
                                                val: "Otros",
                                                text: "Otros"
                                              }],
                                            })}
                                            {renderFormItemTable({
                                              section: outdoor.outdoorCompanyRut,
                                              validator: docValidator(outdoor.outdoorCompanyTipoDoc.val, true)
                                            })}
                                          </Row>
                                          <Row className="fields-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: outdoor.outdoorActivity,
                                            })}
                                          </Row>

                                          <Row className="button-row">
                                            {apiForm.outsideActivities.length < 1 && validarRegistros && apiForm.hasOutsideActivities && (
                                                <Col
                                                    span={24}
                                                    className="missing-registers ant-form-explain"
                                                >
                                                  {t("messages.aml.registersRequired")}
                                                </Col>
                                            )}

                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button" onClick={handleOnAddOutdoor}
                                                      icon="plus"> Añadir </Button>
                                            </Col>
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button" icon="delete"
                                                      onClick={(e) => handleOnClear('outdoor')}> Limpiar </Button>
                                            </Col>
                                          </Row>
                                        </>
                                    )}

                                    {apiForm.outsideActivities.length > 0 && (
                                        <Table
                                            columns={outdoorColumns}
                                            dataSource={apiForm.outsideActivities}
                                            size="middle"
                                            pagination={false}
                                        ></Table>
                                    )}
                                  </Row>
                              )}
                              <Row className="subheader">
                                <Col xl={24}>
                                  IV. RELACIÓN CON LA <Tooltip
                                    className="tooltip-form"
                                    title="La Empresa entiende como competencia, cualquier compañía que se dedique a la comercialización, producción
                                  y/o elaboración de los mismos productos trabajados por La Empresa"
                                >
                                  COMPETENCIA
                                </Tooltip>
                                </Col>
                              </Row>
                              <Row className="summary">
                                <Col span={21}>
                                  Declaro que mi cónyuge, conviviente civil o pareja es <strong> persona trabajadora de
                                  la
                                  competencia </strong> o que tengo relaciones de parentesco hasta el segundo grado
                                  de <Tooltip
                                    className="tooltip-form"
                                    title="La Empresa establece como parientes hasta el segundo grado de consanguinidad (padres, hijos, abuelos,
                                  hermanos, nietos) y hasta el segundo grado de afinidad (padres, hijos, abuelos, y hermanos del cónyuge);
                                  padres, hijos, abuelos y hermanos de la pareja de hecho; cónyuge o pareja de hecho de los hermanos del
                                  declarante (cuñados)."
                                >
                                  consanguinidad o afinidad inclusive
                                </Tooltip>{" "} con <strong>personas trabajadoras de la competencia.</strong>
                                </Col>

                                {renderFormItem({
                                  label: "",
                                  name: "hasRelCompetitor",
                                  initialValue: apiForm.hasRelCompetitor !== null ? apiForm.hasRelCompetitor : null,
                                  colClassName: "switch-col",
                                  itemClassName: "radio-item",
                                  labelCol: 0,
                                  wrapperCol: 0,
                                  offset: 0,
                                  rules: [{required: true, message: t("messages.aml.dontForgetSelect"),},],
                                  wrapperCols: 3,
                                  item: (
                                      <Radio.Group
                                          onChange={({target}) => hancleOnChangeRadioButton("hasRelCompetitor", target.value)}
                                      >
                                        <Radio className="radio-switch" value={true}>
                                          Sí
                                        </Radio>
                                        <Radio className="radio-switch" value={false}>
                                          No
                                        </Radio>
                                      </Radio.Group>
                                  ),
                                })}
                              </Row>
                              {(apiForm.hasRelCompetitor || openContent) && (
                                  <Row className="content">
                                    {format === "html" && !signed && (
                                        <>
                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: competencia.competenciaCompanyName,
                                            })}
                                            {renderFormItemTable({
                                              section: competencia.competenciaCompanyTipoDoc,
                                              type: 'select',
                                              options: [{val: "Chile-Rut", text: "Chile-Rut"}, {
                                                val: "Otros",
                                                text: "Otros"
                                              }],
                                            })}
                                            {renderFormItemTable({
                                              section: competencia.competenciaCompanyRut,
                                              validator: docValidator(competencia.competenciaCompanyTipoDoc.val, true)
                                            })}
                                          </Row>
                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: competencia.competenciaRelationship,
                                              type: 'select',
                                              options:
                                                  [
                                                    {val: "Conyuge", text: "Conyuge"},
                                                    {val: "Pareja", text: "Pareja"},
                                                    {val: "Conviviente civil", text: "Conviviente civil"},
                                                    {val: "Padre", text: "Padre"},
                                                    {val: "Madre", text: "Madre"},
                                                    {val: "Hijo(a)", text: "Hijo(a)"},
                                                    {val: "Hermano(a)", text: "Hermano(a)"},
                                                    {val: "Abuelo(a)", text: "Abuelo(a)"},
                                                    {val: "Nieto(a)", text: "Nieto(a)"},
                                                    {val: "Suegro(a)", text: "Suegro(a)"},
                                                    {
                                                      val: "Hijo(a) del conyuge que no sean suyos",
                                                      text: "Hijo(a) del conyuge que no sean suyos"
                                                    },
                                                    {val: "Abuelo(a) del cónyuge", text: "Abuelo(a) del cónyuge"},
                                                    {val: "Nieto(a) del cónyuge", text: "Nieto(a) del cónyuge"},
                                                    {val: "Hermano(a) del cónyuge", text: "Hermano(a) del cónyuge"},
                                                    {val: "Yerno", text: "Yerno"},
                                                    {val: "Nuera", text: "Nuera"},
                                                    {val: "Tío(a)", text: "Tío(a)"},
                                                    {val: "Sobrino(a)", text: "Sobrino(a)"},
                                                    {val: "Bisabuelo(a)", text: "Bisabuelo(a)"},
                                                    {val: "Biznieto(a)", text: "Biznieto(a)"},
                                                    {val: "Bisabuelo(a) del cónyuge", text: "Bisabuelo(a) del cónyuge"},
                                                    {val: "Cuñado(a)", text: "Cuñado(a)"},
                                                    {val: "Sobrino(a) del conyuge", text: "Sobrino(a) del conyuge"},
                                                  ],
                                            })}

                                            {renderFormItemTable({
                                              section: competencia.competenciaName,
                                            })}
                                            {renderFormItemTable({
                                              section: competencia.competenciaTipoDoc,
                                              type: 'select',
                                              options: [{val: "Chile-Rut", text: "Chile-Rut"}, {
                                                val: "Otros",
                                                text: "Otros"
                                              }],
                                            })}
                                          </Row>

                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: competencia.competenciaRut,
                                              validator: docValidator(competencia.competenciaTipoDoc.val)
                                            })}
                                            {renderFormItemTable({
                                              section: competencia.competenciaPosition,
                                            })}
                                          </Row>
                                          <Row className="button-row">
                                            {apiForm.relCompetitor.length < 1 &&
                                                validarRegistros &&
                                                apiForm.hasRelCompetitor && (
                                                    <Col
                                                        span={24}
                                                        className="missing-registers ant-form-explain"
                                                    >
                                                      {t("messages.aml.registersRequired")}
                                                    </Col>
                                                )}

                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button" onClick={handleOnAddCompetencia}
                                                      icon="plus"> Añadir </Button>
                                            </Col>
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button" icon="delete"
                                                      onClick={(e) => handleOnClear('competencia')}> Limpiar </Button>
                                            </Col>
                                          </Row>
                                        </>
                                    )}

                                    {apiForm.relCompetitor.length > 0 && format === "html" ? (
                                        <Table columns={competenciaColumns} dataSource={apiForm.relCompetitor}
                                               size="middle" pagination={false}></Table>
                                    ) : (
                                        toDescriptionsPdf(apiForm.relCompetitor, competencia)
                                    )}
                                  </Row>
                              )}
                              <Row className="subheader">
                                <Col xl={24}>
                                  V. RELACIÓN DE PARENTESCO O DE PAREJA CON TRABAJADORES (AS) DE LA EMPRESA{" "}
                                </Col>
                              </Row>
                              <Row className="summary">
                                <Col span={21}>
                                  Declaro que mi cónyuge, conviviente civil o pareja es persona trabajadora de La
                                  Empresa o sus empresas relacionadas; o que tengo relaciones de parentesco hasta el
                                  segundo grado de
                                  {" "}
                                  <Tooltip
                                      className="tooltip-form"
                                      title="La Empresa establece como parientes hasta el segundo grado de consanguinidad (padres, hijos, abuelos,
                                  hermanos, nietos) y hasta el segundo grado de afinidad (padres, hijos, abuelos, y hermanos del cónyuge);
                                  padres, hijos, abuelos y hermanos de la pareja de hecho; cónyuge o pareja de hecho de los hermanos del
                                  declarante (cuñados)."
                                  >
                                    consanguinidad o afinidad inclusive
                                  </Tooltip>{" "}
                                  con <strong>personas trabajadoras de La Empresa</strong>
                                </Col>
                                {renderFormItem({
                                  label: "",
                                  name: "hasRelRelationship",
                                  initialValue: apiForm.hasRelRelationship !== null ? apiForm.hasRelRelationship : null,
                                  colClassName: "switch-col",
                                  itemClassName: "radio-item",
                                  labelCol: 0,
                                  wrapperCol: 0,
                                  offset: 0,
                                  rules: [{required: true, message: t("messages.aml.dontForgetSelect"),},],
                                  wrapperCols: 3,
                                  item: (
                                      <Radio.Group
                                          onChange={({target}) => hancleOnChangeRadioButton("hasRelRelationship", target.value)}
                                      >
                                        <Radio className="radio-switch" value={true}>
                                          Sí
                                        </Radio>
                                        <Radio className="radio-switch" value={false}>
                                          No
                                        </Radio>
                                      </Radio.Group>
                                  ),
                                })}
                              </Row>
                              {(apiForm.hasRelRelationship || openContent) && (
                                  <Row className="content">
                                    {format === "html" && !signed && (
                                        <>
                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: relationParentesco.relationParentescoRelationship,
                                              type: 'select',
                                              options:
                                                  [
                                                    {val: "Conyuge", text: "Conyuge"},
                                                    {val: "Pareja", text: "Pareja"},
                                                    {val: "Conviviente civil", text: "Conviviente civil"},
                                                    {val: "Padre", text: "Padre"},
                                                    {val: "Madre", text: "Madre"},
                                                    {val: "Hijo(a)", text: "Hijo(a)"},
                                                    {val: "Hermano(a)", text: "Hermano(a)"},
                                                    {val: "Abuelo(a)", text: "Abuelo(a)"},
                                                    {val: "Nieto(a)", text: "Nieto(a)"},
                                                    {val: "Suegro(a)", text: "Suegro(a)"},
                                                    {
                                                      val: "Hijo(a) del conyuge que no sean suyos",
                                                      text: "Hijo(a) del conyuge que no sean suyos"
                                                    },
                                                    {val: "Abuelo(a) del cónyuge", text: "Abuelo(a) del cónyuge"},
                                                    {val: "Nieto(a) del cónyuge", text: "Nieto(a) del cónyuge"},
                                                    {val: "Hermano(a) del cónyuge", text: "Hermano(a) del cónyuge"},
                                                    {val: "Yerno", text: "Yerno"},
                                                    {val: "Nuera", text: "Nuera"},
                                                    {val: "Tío(a)", text: "Tío(a)"},
                                                    {val: "Sobrino(a)", text: "Sobrino(a)"},
                                                    {val: "Bisabuelo(a)", text: "Bisabuelo(a)"},
                                                    {val: "Biznieto(a)", text: "Biznieto(a)"},
                                                    {val: "Bisabuelo(a) del cónyuge", text: "Bisabuelo(a) del cónyuge"},
                                                    {val: "Cuñado(a)", text: "Cuñado(a)"},
                                                    {val: "Sobrino(a) del conyuge", text: "Sobrino(a) del conyuge"},
                                                  ],
                                            })}
                                            {renderFormItemTable({
                                              section: relationParentesco.relationParentescoName,
                                            })}
                                            {renderFormItemTable({
                                              section: relationParentesco.relationParentescoTipoDoc,
                                              type: 'select',
                                              options: [{val: "Chile-Rut", text: "Chile-Rut"}, {
                                                val: "Otros",
                                                text: "Otros"
                                              }],
                                            })}
                                          </Row>

                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: relationParentesco.relationParentescoRut,
                                              validator: docValidator(relationParentesco.relationParentescoTipoDoc.val)
                                            })}
                                            {renderFormItemTable({
                                              section: relationParentesco.relationParentescoPosition,
                                            })}
                                            {/* {renderFormItemTable({
                                      section: relationParentesco.relationParentescoCompanyName,
                                      type:'select',
                                      options: (params.empresas !== undefined && params.empresas != null && params.empresas.length > 0) ? params.empresas.map(empresa => ({ val: empresa, text: empresa })) : []
                                    })} */}
                                          </Row>

                                          <Row className="button-row">
                                            {apiForm.relRelationship.length < 1 &&
                                                validarRegistros &&
                                                apiForm.hasRelRelationship && (
                                                    <Col
                                                        span={24}
                                                        className="missing-registers ant-form-explain"
                                                    >
                                                      {t("messages.aml.registersRequired")}
                                                    </Col>
                                                )}
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button"
                                                      onClick={handleOnAddRelationParentesco}
                                                      icon="plus"> Añadir </Button>
                                            </Col>
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button" icon="delete"
                                                      onClick={(e) => handleOnClear('relationParentesco')}> Limpiar </Button>
                                            </Col>
                                          </Row>
                                        </>
                                    )}

                                    {apiForm.relRelationship.length > 0 &&
                                    format === "html" ? (
                                        <Table
                                            columns={relationParentescoColumns}
                                            dataSource={apiForm.relRelationship}
                                            size="middle"
                                            pagination={false}
                                        ></Table>
                                    ) : (
                                        toDescriptionsPdf(
                                            apiForm.relRelationship,
                                            relationParentesco
                                        )
                                    )}
                                  </Row>
                              )}
                              <Row className="subheader">
                                <Col xl={24}>
                                  VI. RELACIÓN CON FUNCIONARIOS
                                </Col>
                              </Row>
                              <Row className="summary">
                                <Col span={21}>
                                  <p>
                                    Declaro que mi cónyuge, conviviente civil o pareja es <strong>funcionario (a)
                                    público (a)</strong>, o que tengo
                                    parentesco hasta el segundo grado de {" "}
                                    <Tooltip
                                        className="tooltip-form"
                                        title="La Empresa establece como parientes hasta el segundo grado de consanguinidad (padres, hijos, abuelos,
                                    hermanos, nietos) y hasta el segundo grado de afinidad (padres, hijos, abuelos, y hermanos del cónyuge);
                                    padres, hijos, abuelos y hermanos de la pareja de hecho; cónyuge o pareja de hecho de los hermanos del
                                    declarante (cuñados)."
                                    >
                                      consanguinidad o
                                    </Tooltip>{" "}
                                    <Tooltip
                                        className="tooltip-form"
                                        title="La Empresa establece como parientes hasta el segundo grado de consanguinidad (padres, hijos, abuelos,
                                    hermanos, nietos) y hasta el segundo grado de afinidad (padres, hijos, abuelos, y hermanos del cónyuge);
                                    padres, hijos, abuelos y hermanos de la pareja de hecho; cónyuge o pareja de hecho de los hermanos del
                                    declarante (cuñados)."
                                    >
                                      afinidad
                                    </Tooltip>{" "} con <strong>funcionarios (as) públicos (as)</strong>,
                                    tanto en la administración central del Estado como en instituciones o empresas
                                    fiscales o semifiscales, municipales, autónomas u organismos creados
                                    por el Estado o bajo su dependencia.
                                  </p>
                                  <p>
                                    Asimismo, declaro que mi cónyuge, conviviente civil o pareja es Persona Expuesta
                                    Políticamente {" "}
                                    <Tooltip
                                        className="tooltip-form"
                                        title="La Circular N° 49 de la Unidad de Análisis Financiero, del 3 de diciembre de 2012, define como Personas
                                  Expuestas Políticamente (PEP) a “los chilenos o extranjeros que desempeñan o hayan desempeñado
                                  funciones públicas destacadas en un país, hasta a lo menos un año de finalizado el ejercicio de las mismas."
                                    >
                                      (PEP)
                                    </Tooltip>, o que tengo parentesco hasta el segundo grado
                                    de consanguinidad o afinidad con personas que detenten tal calidad.
                                  </p>
                                </Col>
                                {renderFormItem({
                                  label: "",
                                  name: "hasRelPepFp",
                                  initialValue: getRadioButtonValue("PEP"),
                                  colClassName: "switch-col",
                                  itemClassName: "radio-item",
                                  labelCol: 0,
                                  wrapperCol: 0,
                                  offset: 0,
                                  rules: [{required: true, message: t("messages.aml.dontForgetSelect"),},],
                                  wrapperCols: 3,
                                  item: (
                                      <Radio.Group
                                          onChange={({target}) => handleOnChangeRadioButton2("PEP", "hasRelPepFp", target.value)}
                                      >
                                        <Radio className="radio-switch" value={true}>
                                          Sí
                                        </Radio>
                                        <Radio className="radio-switch" value={false}>
                                          No
                                        </Radio>
                                      </Radio.Group>
                                  ),
                                })}
                              </Row>
                              {(getRadioButtonValue('PEP') || openContent) && (
                                  <Row className="content">
                                    {format === "html" && !signed && (
                                        <>
                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: relationPep.relationPepRelationship,
                                              type: 'select',
                                              options:
                                                  [
                                                    {val: "Mi persona", text: "Mi persona"},
                                                    {val: "Conyuge", text: "Conyuge"},
                                                    {val: "Pareja", text: "Pareja"},
                                                    {val: "Conviviente civil", text: "Conviviente civil"},
                                                    {val: "Padre", text: "Padre"},
                                                    {val: "Madre", text: "Madre"},
                                                    {val: "Hijo(a)", text: "Hijo(a)"},
                                                    {val: "Hermano(a)", text: "Hermano(a)"},
                                                    {val: "Abuelo(a)", text: "Abuelo(a)"},
                                                    {val: "Nieto(a)", text: "Nieto(a)"},
                                                    {val: "Suegro(a)", text: "Suegro(a)"},
                                                    {
                                                      val: "Hijo(a) del conyuge que no sean suyos",
                                                      text: "Hijo(a) del conyuge que no sean suyos"
                                                    },
                                                    {val: "Abuelo(a) del cónyuge", text: "Abuelo(a) del cónyuge"},
                                                    {val: "Nieto(a) del cónyuge", text: "Nieto(a) del cónyuge"},
                                                    {val: "Hermano(a) del cónyuge", text: "Hermano(a) del cónyuge"},
                                                    {val: "Yerno", text: "Yerno"},
                                                    {val: "Nuera", text: "Nuera"},
                                                    {val: "Tío(a)", text: "Tío(a)"},
                                                    {val: "Sobrino(a)", text: "Sobrino(a)"},
                                                    {val: "Bisabuelo(a)", text: "Bisabuelo(a)"},
                                                    {val: "Biznieto(a)", text: "Biznieto(a)"},
                                                    {val: "Bisabuelo(a) del cónyuge", text: "Bisabuelo(a) del cónyuge"},
                                                    {val: "Cuñado(a)", text: "Cuñado(a)"},
                                                    {val: "Sobrino(a) del conyuge", text: "Sobrino(a) del conyuge"},
                                                  ],
                                            })}
                                            {renderFormItemTable({
                                              section: relationPep.relationPepName,
                                            })}
                                            {renderFormItemTable({
                                              section: relationPep.relationPepTipoDoc,
                                              type: 'select',
                                              options: [{val: "Chile-Rut", text: "Chile-Rut"}, {
                                                val: "Otros",
                                                text: "Otros"
                                              }],
                                            })}
                                          </Row>
                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: relationPep.relationPepRut,
                                              validator: docValidator(relationPep.relationPepTipoDoc.val)
                                            })}
                                            {renderFormItemTable({
                                              section: relationPep.relationPepCategory,
                                              type: 'select',
                                              options: [{val: "PEP", text: "PEP"}, {
                                                val: "Funcionario Público",
                                                text: "Funcionario Público"
                                              }],
                                            })}
                                            {renderFormItemTable({
                                              section: relationPep.relationPepInstitution,
                                            })}
                                          </Row>

                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: relationPep.relationPepPosition,
                                            })}

                                            {renderFormItemTable({
                                              section: relationPep.relationPepEndDate,
                                              required: false,
                                              type: 'date',
                                              initialValue: relationPep.relationPepEndDate.val !== null && relationPep.relationPepEndDate.val !== undefined ? moment(relationPep.relationPepEndDate.val) : null,
                                            })}

                                          </Row>

                                          <Row className="button-row">
                                            {getFilteredRegistersByType('PEP').length < 1 && validarRegistros && getRadioButtonValue('PEP') && (
                                                <Col span={24}
                                                     className="missing-registers ant-form-explain"> {t("messages.aml.registersRequired")} </Col>
                                            )}
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button"
                                                      onClick={(e) => handleOnAddAttributes(relationPep, 'PEP', 'relationPep')}
                                                      icon="plus"> Añadir </Button>
                                            </Col>
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button" icon="delete"
                                                      onClick={(e) => handleOnClear('relationPep')}> Limpiar </Button>
                                            </Col>
                                          </Row>
                                        </>
                                    )}

                                    {getFilteredRegistersByType('PEP').length > 0 &&
                                    format === "html" ? (
                                        <Table columns={relationPepColumns}
                                               dataSource={getFilteredRegistersByType('PEP')} size="middle"
                                               pagination={false}></Table>
                                    ) : (
                                        toDescriptionsPdf(getFilteredRegistersByType('PEP'), relationPep)
                                    )}
                                  </Row>
                              )}
                              <Row className="subheader">
                                <Col xl={24}>
                                  VII. RELACIÓN CON FUNDACIONES, CORPORACIONES, INSTITUCIONES DE BENEFICENCIA CON O SIN
                                  FINES DE LUCRO
                                </Col>
                              </Row>
                              <Row className="summary">
                                <Col span={21}>
                                  Declaro que tengo participación, directa o indirecta, o lo hace mi cónyuge,
                                  conviviente civil,
                                  pareja o también algún pariente hasta el segundo grado
                                  de {" "}
                                  <Tooltip
                                      className="tooltip-form"
                                      title="La Empresa establece como parientes hasta el segundo grado de consanguinidad (padres, hijos, abuelos,
                                    hermanos, nietos) y hasta el segundo grado de afinidad (padres, hijos, abuelos, y hermanos del cónyuge);
                                    padres, hijos, abuelos y hermanos de la pareja de hecho; cónyuge o pareja de hecho de los hermanos del
                                    declarante (cuñados)."
                                  >
                                    consanguinidad o afinidad inclusive
                                  </Tooltip>{" "}, como fundador, director, consejero, gerente,
                                  administrador, u otro similar, <strong>en fundaciones, corporaciones, instituciones de
                                  beneficencia con o sin fines de lucro.</strong>
                                </Col>
                                {renderFormItem({
                                  label: "",
                                  name: "hasRelFundPjsfl",
                                  initialValue: apiForm.hasRelFundPjsfl !== null ? apiForm.hasRelFundPjsfl : null,
                                  colClassName: "switch-col",
                                  itemClassName: "radio-item",
                                  labelCol: 0,
                                  wrapperCol: 0,
                                  offset: 0,
                                  rules: [{required: true, message: t("messages.aml.dontForgetSelect"),},],
                                  wrapperCols: 3,
                                  item: (
                                      <Radio.Group
                                          onChange={({target}) => hancleOnChangeRadioButton("hasRelFundPjsfl", target.value)}
                                      >
                                        <Radio className="radio-switch" value={true}>
                                          Sí
                                        </Radio>
                                        <Radio className="radio-switch" value={false}>
                                          No
                                        </Radio>
                                      </Radio.Group>
                                  ),
                                })}
                              </Row>
                              {(apiForm.hasRelFundPjsfl || openContent) && (
                                  <Row className="content">
                                    {format === "html" && !signed && (
                                        <>
                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: relationFundaciones.relationFundacionesCompanyName,
                                            })}
                                            {renderFormItemTable({
                                              section: relationFundaciones.relationFundacionesCompanyTipoDoc,
                                              type: 'select',
                                              options: [{val: "Chile-Rut", text: "Chile-Rut"}, {
                                                val: "Otros",
                                                text: "Otros"
                                              }],
                                            })}
                                            {renderFormItemTable({
                                              section: relationFundaciones.relationFundacionesCompanyRut,
                                              validator: docValidator(relationFundaciones.relationFundacionesCompanyTipoDoc.val, true)
                                            })}
                                          </Row>
                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: relationFundaciones.relationFundacionesCompanyType,
                                              type: 'select',
                                              options: [
                                                {val: "Fundaciones", text: "Fundaciones"},
                                                {val: "Corporaciones", text: "Corporaciones"},
                                                {
                                                  val: "Instituciones de beneficencia y/o sin fines de lucro",
                                                  text: "Instituciones de beneficencia y/o sin fines de lucro"
                                                },
                                              ],
                                            })}
                                            {renderFormItemTable({
                                              section: relationFundaciones.relationFundacionesPosition,
                                              type: 'select',
                                              options: [
                                                {val: "Fundador", text: "Fundador"},
                                                {val: "Director", text: "Director"},
                                                {val: "Consejero", text: "Consejero"},
                                                {val: "Gerente", text: "Gerente"},
                                                {val: "Administrador", text: "Administrador"},
                                                {val: "Representante", text: "Representante"},
                                                {val: "Ejecutivo Principal", text: "Ejecutivo Principal"},
                                              ],
                                            })}
                                            {renderFormItemTable({
                                              section: relationFundaciones.relationFundacionesRelationship,
                                              type: 'select',
                                              options:
                                                  [
                                                    {val: "Mi persona", text: "Mi persona"},
                                                    {val: "Conyuge", text: "Conyuge"},
                                                    {val: "Pareja", text: "Pareja"},
                                                    {val: "Conviviente civil", text: "Conviviente civil"},
                                                    {val: "Padre", text: "Padre"},
                                                    {val: "Madre", text: "Madre"},
                                                    {val: "Hijo(a)", text: "Hijo(a)"},
                                                    {val: "Hermano(a)", text: "Hermano(a)"},
                                                    {val: "Abuelo(a)", text: "Abuelo(a)"},
                                                    {val: "Nieto(a)", text: "Nieto(a)"},
                                                    {val: "Suegro(a)", text: "Suegro(a)"},
                                                    {
                                                      val: "Hijo(a) del conyuge que no sean suyos",
                                                      text: "Hijo(a) del conyuge que no sean suyos"
                                                    },
                                                    {val: "Abuelo(a) del cónyuge", text: "Abuelo(a) del cónyuge"},
                                                    {val: "Nieto(a) del cónyuge", text: "Nieto(a) del cónyuge"},
                                                    {val: "Hermano(a) del cónyuge", text: "Hermano(a) del cónyuge"},
                                                    {val: "Yerno", text: "Yerno"},
                                                    {val: "Nuera", text: "Nuera"},
                                                    {val: "Tío(a)", text: "Tío(a)"},
                                                    {val: "Sobrino(a)", text: "Sobrino(a)"},
                                                    {val: "Bisabuelo(a)", text: "Bisabuelo(a)"},
                                                    {val: "Biznieto(a)", text: "Biznieto(a)"},
                                                    {val: "Bisabuelo(a) del cónyuge", text: "Bisabuelo(a) del cónyuge"},
                                                    {val: "Cuñado(a)", text: "Cuñado(a)"},
                                                    {val: "Sobrino(a) del conyuge", text: "Sobrino(a) del conyuge"},
                                                  ],
                                            })}
                                          </Row>
                                          <Row className="inner-row" gutter={[16, 8]}>
                                            {renderFormItemTable({
                                              section: relationFundaciones.relationFundacionesName,
                                            })}
                                            {renderFormItemTable({
                                              section: relationFundaciones.relationFundacionesTipoDoc,
                                              type: 'select',
                                              options: [{val: "Chile-Rut", text: "Chile-Rut"}, {
                                                val: "Otros",
                                                text: "Otros"
                                              }],
                                            })}
                                            {renderFormItemTable({
                                              section: relationFundaciones.relationFundacionesRut,
                                              validator: docValidator(relationFundaciones.relationFundacionesTipoDoc.val)
                                            })}
                                          </Row>

                                          {/* <Row className="inner-row" gutter={[16, 8]}>
                                    {renderFormItemTable({
                                      section: relationFundaciones.relationFundacionesArea,
                                    })}
                                  </Row> */}

                                          <Row className="button-row">
                                            {apiForm.relFundPjsfl.length < 1 &&
                                                validarRegistros &&
                                                apiForm.hasRelFundPjsfl && (
                                                    <Col
                                                        span={24}
                                                        className="missing-registers ant-form-explain"
                                                    >
                                                      {t("messages.aml.registersRequired")}
                                                    </Col>
                                                )}
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button"
                                                      onClick={handleOnAddRelationFundaciones}
                                                      icon="plus"> Añadir </Button>
                                            </Col>
                                            <Col className="addRelation" xl={3}>
                                              <Button type="primary" htmlType="button" icon="delete"
                                                      onClick={(e) => handleOnClear('relationFundaciones')}> Limpiar </Button>
                                            </Col>
                                          </Row>
                                        </>
                                    )}

                                    {apiForm.relFundPjsfl.length > 0 &&
                                    format === "html" ? (
                                        <Table
                                            columns={relationFundacionesColumns}
                                            dataSource={apiForm.relFundPjsfl}
                                            size="middle"
                                            pagination={false}
                                        ></Table>
                                    ) : (
                                        toDescriptionsPdf(
                                            apiForm.relFundPjsfl,
                                            relationFundaciones
                                        )
                                    )}
                                  </Row>
                              )}
                              <Row className="subheader">
                                <Col xl={24}>VIII. OTROS CONFLICTOS DE INTERÉS</Col>
                              </Row>
                              <Row className="summary">
                                <Col span={21}>
                                  Declaro que tengo conocimiento de algún otro conflicto de interés no abordado en las
                                  preguntas anteriores y <strong>que pudiera afectar o influir de cualquier forma en los
                                  intereses de
                                  La Empresa</strong>, tales como la existencia de relación laboral, comercial, o de
                                  negocios
                                  con <Tooltip
                                    className="tooltip-form"
                                    title="La Empresa establece como amistad íntima a la relación interpersonal frecuente, permanente y perdurable en
                                el tiempo con una persona sin existir relación de parentesco."
                                >amigos íntimos</Tooltip>, parientes por consanguinidad o afinidad hasta <Tooltip
                                    className="tooltip-form"
                                    title="La Empresa entiende como parientes por consanguinidad o afinidad hasta el tercer y cuarto grado, además de
                                los mencionados más arriba a los tíos, sobrinos, primos, tíos abuelos, bisabuelos, sobrinos nietos, bisnietos y
                                concuñados."
                                >
                                  tercer y cuarto grado.
                                </Tooltip>
                                </Col>

                                {renderFormItem({
                                  label: "",
                                  name: "hasOthersCDI",
                                  initialValue:
                                      apiForm.hasOthersCDI !== null
                                          ? apiForm.hasOthersCDI
                                          : null,
                                  colClassName: "switch-col",
                                  itemClassName: "radio-item",
                                  labelCol: 0,
                                  wrapperCol: 0,
                                  offset: 0,
                                  rules: [
                                    {
                                      required: true,
                                      message: t("messages.aml.dontForgetSelect"),
                                    },
                                  ],
                                  wrapperCols: 3,
                                  item: (
                                      <Radio.Group
                                          onChange={({target}) =>
                                              hancleOnChangeRadioButton(
                                                  "hasOthersCDI",
                                                  target.value
                                              )
                                          }
                                      >
                                        <Radio className="radio-switch" value={true}>
                                          Sí
                                        </Radio>
                                        <Radio className="radio-switch" value={false}>
                                          No
                                        </Radio>
                                      </Radio.Group>
                                  ),
                                })}
                              </Row>
                              {(apiForm.hasOthersCDI || openContent) && (
                                  <Row className="content">
                                    {format === "html" && !signed && (
                                        <>
                                          <Row className="fields-row" gutter={[16, 8]}>
                                            {renderFormItem({
                                              label: "Descripción de la situación",
                                              name: baseParamsIS.relationOtrosDescripcion.id,
                                              initialValue:
                                              relationOtros.relationOtrosDescripcion,
                                              colClassName: "topLabel",
                                              labelCol: 0,
                                              wrapperCol: 0,
                                              rules: [
                                                {
                                                  required: true,
                                                  message: t("messages.aml.requestedField"),
                                                },
                                                validateLengthBaseParam(baseParamsIS.relationOtrosDescripcion)
                                              ],
                                              wrapperCols: 24,
                                              item: (
                                                  <Input
                                                      autoComplete="off"
                                                      onFocus={(e) => handleReadOnly(e.target.id, false)}
                                                      onBlur={(e) => handleReadOnly(e.target.id, true)}
                                                      readOnly={baseParams.relationOtrosDescripcion.readOnly}
                                                      disabled={false}
                                                      onChange={(e) => handleOnChangeFieldRelationOtros("relationOtrosDescripcion", e.target.value)}
                                                      maxLength={180}
                                                      placeholder="Máximo 180 caracteres."
                                                  />
                                              ),
                                            })}
                                          </Row>

                                          <Row className="button-row">
                                            {apiForm.othersCDI.length < 1 &&
                                                validarRegistros &&
                                                apiForm.hasOthersCDI && (
                                                    <Col
                                                        span={24}
                                                        className="missing-registers ant-form-explain"
                                                    >
                                                      {t("messages.aml.registersRequired")}
                                                    </Col>
                                                )}
                                            <Col className="addRelation" xl={3}>
                                              <Button
                                                  type="primary"
                                                  htmlType="button"
                                                  onClick={handleOnAddRelationOtros}
                                                  icon="plus"
                                              >
                                                Añadir
                                              </Button>
                                            </Col>
                                            <Col className="addRelation" xl={3}>
                                              <Button
                                                  type="primary"
                                                  htmlType="button"
                                                  icon="delete"
                                                  onClick={(e) => handleOnClear('relationOtros')}
                                              >
                                                Limpiar
                                              </Button>
                                            </Col>
                                          </Row>
                                        </>
                                    )}

                                    {apiForm.othersCDI.length > 0 && (
                                        <Table
                                            columns={relationOtrosColumns()}
                                            dataSource={apiForm.othersCDI}
                                            size="middle"
                                            pagination={false}
                                        ></Table>
                                    )}
                                  </Row>
                              )}
                              <Row className="subheader">
                                <Col xl={24}>
                                  IX. CONOCIMIENTO DEL USO DE INFORMACIÓN CONFIDENCIAL, RESERVADA Y/O PRIVILEGIADA
                                </Col>
                              </Row>
                              <Row className="summary">
                                <Col xl={24}>
                                  <p>
                                    Declaro tener conocimiento de la Ley N° 18.045 en lo relativo a la prohibición de
                                    utilizar o
                                    revelar en beneficio propio o de terceros, información confidencial, reservada y/o
                                    privilegiada.
                                  </p>
                                  <p>
                                    Asimismo, declaro tener conocimiento de que se encuentra prohibido, divulgar,
                                    revelar o
                                    consentir que otra persona acceda a un secreto comercial de la empresa y que esto
                                    constituye
                                    un delito contemplado en el artículo 284 bis del Código Penal.
                                  </p>
                                </Col>
                              </Row>
                              <Row className="subheader">
                                <Col xl={24}>
                                  X. CONOCIMIENTO DE LA LEY N°20.393 SOBRE
                                  “RESPONSABILIDAD PENAL DE LAS PERSONAS JURÍDICAS"
                                </Col>
                              </Row>
                              <Row className="summary">
                                <Col xl={24}>
                                  <p>
                                    Declaro estar en conocimiento de que La Empresa ha adoptado un modelo de prevención
                                    de
                                    delitos de acuerdo a la Ley N°20.393 de “Responsabilidad Penal de las Personas
                                    Jurídicas”, y
                                    me comprometo a no cometer o participar en la comisión de ninguno de los delitos
                                    señalados
                                    en la citada Ley u otros que pudiesen incorporarse en el futuro, así como cumplir
                                    procesos y
                                    controles establecidos para dicho fin.
                                  </p>
                                  <p>
                                    Declaro tener la obligación de comunicar al Encargado de Prevención de Delitos, todo
                                    acto o
                                    conducta que revista carácter de delito del cual tome conocimiento, mediante el
                                    Canal de
                                    Denuncias dispuesto por La Empresa
                                  </p>
                                  <p>
                                    Certifico que mis respuestas están completas y son correctas a mi mejor saber y
                                    entender.
                                    Además, acepto que tengo la obligación permanente de comunicar a la brevedad y por
                                    escrito
                                    a La Empresa, en el evento de que se originen cambios a la presente declaración
                                    antes de la
                                    siguiente presentación, con el objeto de que se proceda a actualizar los
                                    antecedentes
                                    entregados, y si fuera el caso, también tengo la obligación de inhibirme de tomar
                                    decisiones
                                    que pudieran verse afectadas por un posible conflicto de interés declarado mientras
                                    éste no se
                                    resuelva.
                                  </p>
                                  <p>
                                    Asimismo, faculto y declaro estar en conocimiento de que La Empresa, por si o a
                                    través de
                                    terceros, podría eventualmente, y de manera aleatoria o basado en patrones de
                                    riesgo,
                                    verificar la información proporcionada.
                                  </p>
                                </Col>
                              </Row>
                              {hasSign && format === "pdf" && !signed && apiForm?.status !== 'SENT' &&
                                  <Row className="button-row">
                                    <Col className="submitTrabajador" offset={18} style={{
                                      fontSize: 4,
                                      paddingTop: 60,
                                      paddingRight: 30,
                                      color: 'rgba(255,255,255,0)'
                                    }}>
                                      Firma_aqui
                                    </Col>
                                  </Row>
                              }
                              {format === "html" && !signed && apiForm?.status !== 'SENT' &&
                                  <>
                                    <Row className="button-row">
                                      <Col className="submitTrabajador" xl={24}>
                                        <Button type="primary" htmlType="submit" disabled={openSigner}
                                                icon={openSigner ? 'loading' : 'file-protect'}>
                                          {hasSign ? 'Firmar' : 'Enviar'}
                                        </Button>
                                      </Col>
                                    </Row>

                                    {hasSign && signData?.embedUrl && signData?.provider === "DOCUSIGN" &&
                                        <Modal visible={true} title="Firma" width={1200}
                                               onCancel={closeHandlerSigner}
                                               footer={[<Button
                                                   onClick={closeHandlerSigner}>{t('messages.aml.btnClose')}</Button>]}
                                        >
                                          <iframe id="iframeDocument" src={signData.embedUrl} width="100%"
                                                  height="480"></iframe>
                                        </Modal>
                                    }
                                  </>
                              }
                            </>
                        )}
                      </Form>
                    </Col>
                  </Row>
                </div>
            ) : (
                <h2 style={{textAlign: "center"}}>Formulario no encontrado</h2>
            )}
          </>
        )}
      </div>
    </FormLayout>
  );
};

export default withRouter(Form.create()(FormTrab));
