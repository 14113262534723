import { AuthProvider } from 'react-oidc-context';
import { LoginOauthPage } from '..';
import Logo from '../../layouts/commonComponent/components/Logo/Logo';
import { Row, Col } from 'antd'

export default function AuthLogin({client, initAuth, successHandler, config, authResponse, children}) {

    const oidcConfig = {
        authority: config.authority,
        client_id: config.clientId,
        redirect_uri: document.location.protocol + "//" + document.location.host + "/callback-oauth2",
        response_type: 'code',
        scope: 'openid profile email'
    };

    return (
        <AuthProvider {...oidcConfig}>
            { !children &&
                <div className="login-header">
                    <Row>
                        <Col xs={ 8 } offset={8} style={{textAlign:'center', padding:20, alignContent:'center'}}>
                        { client?.id &&
                            <div style={{maxWidth:'fit-content', margin: 'auto'}}>
                                <Logo currentUser={{clientId: client?.id}} isForm={false}/>
                            </div>
                        }
                        </Col>
                    </Row>
                </div>
            }
            <LoginOauthPage client={client} initAuth={initAuth} successHandler={successHandler} authResponse={authResponse}>
                {children}
            </LoginOauthPage>
        </AuthProvider>
    )
}