import getCategories from './getCategories'
import getForm from './getForm'
import saveForm from './saveForm'
import updateFormBasic from './updateFormBasic'
import sendForm from './sendForm'
import getParams from './getParams'
import getParamsUboFinder from './getParamsUboFinder'
import saveFormCDItrab from './saveFormCDItrab'
import saveFormCDIprov from './saveFormCDIprov'
import signCDIForm from './signCDIForm'
import sendCdiRequest from './sendCdiRequest'
import getCountriesCode from './getCountriesCode'
import updateForm from './updateForm'
export const updateFormPromise = updateForm;
export const getCategoriesPromise = getCategories
export const getFormPromise = getForm
export const saveFormPromise = saveForm
export const updateFormBasicPromise = updateFormBasic
export const sendFormPromise = sendForm
export const getParamsPromise = getParams
export const saveFormCDItrabPromise = saveFormCDItrab
export const saveFormCDIprovPromise = saveFormCDIprov
export const signCDIFormPromise = signCDIForm
export const sendCdiRequestPromise = sendCdiRequest
export const getCountriesCodePromise = getCountriesCode;
export const getParamsUboFinderPromise = getParamsUboFinder;
