import React from 'react'
import axios from 'axios'
import { base64DecoderHelper } from '../../../../helpers'
import {useEffect, useState} from "react";
import apiConfig from "../../../../config/api";
import { Spin } from 'antd';


const ImageResources =({url, styleResource}) => {

    let dataType= 'data:image/png;base64,';

    const [imageDecoded, setImageDecoded] = useState([])
    const [isImgLoading, setIsImgLoading] = useState(false);

    useEffect(() => {
        getImageResource();
    }, []);


    const getImageResource = () => {
        const finalUrl = apiConfig.url + url;
        setIsImgLoading(true)
        axios.get(finalUrl, {
            responseType: 'arraybuffer'
        })
            .then(response => {
                if(response.data !== null && response.data !== '') {
                    let base64 = base64DecoderHelper(response.data)
                    setImageDecoded(base64)
                }
            })
            .catch(error => {
                console.log('Recurso no encontrado', error)
            })
            .finally(() => {
                setIsImgLoading(false)
            })
    }

    return (
        <>
            {isImgLoading ?
                <Spin/>
            :
                <img src={dataType + imageDecoded} alt="" style={styleResource}/>
            }
        </>
    )
}

export default ImageResources