import "./FormTrab.scss";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row, Spin, notification, Table, Icon, Radio, Divider } from "antd";
import { FormLayout } from "../../../../../layouts";
import { withRouter } from "react-router-dom";
import { getFormPromise, getParamsPromise } from "../../../promises";
import moment from "moment";
import Logo from '../../../../../layouts/commonComponent/components/Logo/Logo'
import { getCountriesCodePromise } from "../../../../OnBoarding/promises";
import apiConfig from '../../../../../config/api'
import { cargosOptions, relationshipOptions, tipoDocOptions, tipoEmpresaOptions, tipoFundacionOptions } from "../../assets/optList";
import useFormsHooks from "../../hooks/useFormsHooks";

const FormTrab = ({ form, match }) => {
  const { t } = useTranslation();
  const { getFieldsError, validateFieldsAndScroll, setFields } = form;
  const [isValidate, setIsValidate] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  const [paises, setPaises] = useState(null);
  const [date, setDate] = useState(0);
  const [signed, setSigned] = useState(false);
  const [user, setUser] = useState({});
  const [apiForm, _setApiForm] = useState(null);
  const [colLogo, setColLogo] = useState(4);
  const [format, setFormat] = useState("html");
  const [openSigner, setOpenSigner] = useState(false);
  const hasSign = false
  const [clientName, setClientName] = useState("");
  const [signData, setSignData] = useState(null);
  const apiFormRef = useRef(apiForm);
  const [validarRegistros, setValidarRegistros] = useState(false);
  const [empresasGrupo, setEmpresasGrupo] = useState([]);
  const [registerData, setRegisterData] = useState();

  const setApiForm = data => {
    apiFormRef.current = data;
    _setApiForm(data);
  };

  const handlerOnChangeObjAttr = async (sectionId, value, formObj) => {
    handleOnChangeField(sectionId, value)
    const binfo = entriesToObj(basicInformation)
    const iMall = entriesToObj(mallaParental)
    const iPsoc = entriesToObj(participacionSociedades)
    const iPemp = entriesToObj(participacionEmpresa);
    const inter = entriesToObj(interlocking);
    const iRelProv = entriesToObj(relacionProveedores);
    const iFuerHor = entriesToObj(fueraDeHorario);    
    const iEmpleosAnt = entriesToObj(empleosAnteriores);
    const iRelComp = entriesToObj(relacionCompetencia);
    const iRelPar = entriesToObj(relacionParentesco);
    const iFuncPub = entriesToObj(funcionariosPublicos);
    const iRelFund = entriesToObj(relacionFundaciones);
    const iOtrosCon = entriesToObj(otrosConflictos);

    if(!formObj) formObj = apiForm
    const json = { 
      basicInformation: binfo,
      mallaParental: iMall, 
      participacionSociedades: iPsoc, 
      participacionEmpresa: iPemp,
      interlocking: inter,
      relacionProveedores: iRelProv,
      fueraDeHorario: iFuerHor,
      empleosAnteriores: iEmpleosAnt,
      relacionCompetencia: iRelComp,
      relacionParentesco: iRelPar,
      funcionariosPublicos: iFuncPub,
      relacionFundaciones: iRelFund,
      otrosConflictos: iOtrosCon
    }
    let formToUpdate = { ...formObj, json };
    let ret = await saveFormPromiseLocal(formToUpdate);
    if(!ret.success) {
    //  setFieldsValue({[field]: ret.form[field]})
    }
  }

  const initVariablesFromObject = (obj, objs={}) => {
    let objs1 = {...objs}
    if (obj.json) {
      if (obj.json.basicInformation) {
        let binfo = objToVariable(basicInformationIS, obj.json.basicInformation);
        objs1['basicInformation'] = binfo
      }
      if (obj.json.mallaParental) {
        let iMall = objToVariable(mallaParentalIS, obj.json.mallaParental);
        objs1['mallaParental'] = iMall
      }
      if (obj.json.participacionSociedades) {
        let partSoc = objToVariable(participacionSociedadesIS, obj.json.participacionSociedades);
        objs1['participacionSociedades'] = partSoc
      }
      if (obj.json.participacionEmpresa) {
        let partEmp = objToVariable(participacionEmpresaIS, obj.json.participacionEmpresa);
        objs1['participacionEmpresa']= partEmp
      }
      if (obj.json.interlocking) {
        let interlock = objToVariable(interlockingIS, obj.json.interlocking);
        objs1['interlocking'] = interlock
      }
      if (obj.json.relacionProveedores) {
        let relProv = objToVariable(relacionProveedoresIS, obj.json.relacionProveedores);
        objs1['relacionProveedores'] = relProv
      }
      if (obj.json.fueraDeHorario) {
        let fuerHor = objToVariable(fueraDeHorarioIS, obj.json.fueraDeHorario);
        objs1['fueraDeHorario'] = fuerHor
      }
      if (obj.json.empleosAnteriores) {
        let empleosAnt = objToVariable(empleosAnterioresIS, obj.json.empleosAnteriores);
        objs1['empleosAnteriores'] = empleosAnt
      }
      if (obj.json.relacionCompetencia) {
        let relComp = objToVariable(relacionCompetenciaIS, obj.json.relacionCompetencia);
        objs1['relacionCompetencia'] = relComp
      }
      if (obj.json.relacionParentesco) {
        let relPar = objToVariable(relacionParentescoIS, obj.json.relacionParentesco);
        objs1['relacionParentesco'] = relPar
      }
      if (obj.json.funcionariosPublicos) {
        let funcPub = objToVariable(funcionariosPublicosIS, obj.json.funcionariosPublicos);
        objs1['funcionariosPublicos'] = funcPub
      }
      if (obj.json.relacionFundaciones) {
        let relFund = objToVariable(relacionFundacionesIS, obj.json.relacionFundaciones);
        objs1['relacionFundaciones'] = relFund
      }
      if (obj.json.otrosConflictos) {
        let otrosCon = objToVariable(otrosConflictosIS, obj.json.otrosConflictos);
        objs1['otrosConflictos'] = otrosCon
      }
    }
    registerObjSections(objs1)
  };

  const { getObjSection, registerObjSections, handleOnChangeFields, handleOnChangeField, renderFormItem, renderFormItemTable, renderFormItemObj, objToVariable, handleOnClear, handleDeleteAttrTable, handleOnAddAttrTable, entriesToObj, saveFormPromiseLocal, sendDocument, docValidator, toDescriptionsPdf } = useFormsHooks({format, form, match, apiForm, setApiForm, handlerOnChangeObjAttr, initVariablesFromObject});

  let registersStop = {};

  const radioStyle = {
    display: 'block',
    height: '22px',
    lineHeight: '22px',
    pointerEvents: format === 'pdf' ? 'none' : 'auto'
  };

  // INTRODUCCION
  const basicInformationIS= {
    gerencia: { key: "gerencia", size: "small", placeholder:"Ingrese Gerencia", maxLength:100, id: "basicInformationGerencia", required: true, type: 'input', itemClassName: "introduccion-item"},
    cargo: { key: "cargo", size: "small", placeholder:"Ingrese Cargo", maxLength:100, id: "basicInformationCargo", required: true, type: 'input'},
    fechaContratacion: { key: "fechaContratacion", size: "small", placeholder: "", id: "basicInformationFechaContratacion", required: true, type: 'date'},
  };
  const [keyBasicInformation, basicInformation] = getObjSection('basicInformation')

  // MALLA PARENTAL
  const mallaParentalIS= {
    records: { key: "records", val: [], id: "mallaParentalRecords"},
  };
  const [keyMallaParental, mallaParental] = getObjSection('mallaParental')


  const mallaParObjIS = {
    parentesco: { key: "parentesco", title: "Parentesco", id: "mallaParObjParentesco", required: true, type: 'select'},
    nombre: { key: "nombre", title: "Nombre", maxLength:100, id: "mallaParObjNombre", required: true, type: 'input'},
    tipoDoc: { key: "tipoDoc", title: "Tipo de documento de identidad", id: "mallaParObjTipoDoc", required: true, type: 'select'},
    doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "mallaParObjDoc", required: true, type: 'input'},
  };
  const [keyMallaParObj, mallaParObj] = getObjSection('mallaParObj')

  // PARTICIPACION EN SOCIEDADES
  const participacionSociedadesIS= {
    hasRecords: { key: "hasRecords", val: undefined, title: "", id: "participacionSociedadesHasPsoc", required: true},
    records: { key: "records", val: [], id: "participacionSociedadesRecords"},
  };
  const [keyParticipacionSociedades, participacionSociedades] = getObjSection('participacionSociedades')

  const dataPsocObjIS = {
    razonSocial: { key: "razonSocial",  title: "Razón Social", maxLength:100, id: "dataPsocObjRazonSocial", required: true, type: 'input'},
    tipoDocEmp: { key: "tipoDocEmp", title: "Tipo de documento de identidad", id: "dataPsocObjTipoDocEmp", required: true, type: 'select'},
    docEmp: { key: "docEmp",  title: "Documento de identidad", maxLength:50, id: "dataPsocObjDocEmp", required: true, type: 'input'},
    paisEmp: { key: "paisEmp", title: "País de Constitución", id: "dataPsocObjPais", required: true, type: 'select'},
    parentesco: { key: "parentesco", title: "Parentesco", id: "dataPsocObjParentesco", required: true, type: 'select'},
    nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataPsocObjNombre", required: true, type: 'input'},
    tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "dataPsocObjTipoDoc", required: true, type: 'select'},
    doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "dataPsocObjDoc", required: true, type: 'input'},
    cargo: { key: "cargo",  title: "Cargo", id: "dataPsocObjCargo", required: true, type: 'select'},
    tipoPropiedad: { key: "tipoPropiedad",  title: "Tipo de Propiedad", id: "dataPsocObjTipoPropiedad", required: true, type: 'select' },
    porcentajeParticipacion: { key: "porcentajeParticipacion",  title: "Porcentaje de Participación", id: "dataPsocObjPorcentajeParticipacion", required: true, type: 'number', isPercentage: true, max: 100 },
    nombreEmpIndirecta: { key: "nombreEmpIndirecta",  title: "Nombre de Empresa Propiedad Indirecta", maxLength: 100, id: "dataPsocObjNombreEmpIndirecta", required: true, type: 'input' },
    tipoDocEmpIndirecta: { key: "tipoDocEmpIndirecta",  title: "Tipo documento Empresa Propiedad Indirecta", maxLength: 200, id: "dataPsocObjTipoDocEmpIndirecta", required: true, type: 'select' },
    docEmpIndirecta: { key: "docEmpIndirecta",  title: "Documento Empresa propiedad indirecta", maxLength: 50, id: "dataPsocObjDocEmpIndirecta", required: true, type: 'input' },
  };
  const [keyDataPsocObj, dataPsocObj] = getObjSection('dataPsocObj')

  // PARTICIPACION EN PROPIEDAD DE LA EMPRESA
  const participacionEmpresaIS= {
    hasRecords: { key: "hasRecords", val: undefined, title: "", id: "participacionEmpresaHasPemp", required: true},
    records: { key: "records", val: [], id: "participacionEmpresaRecords"},
  };
  const [keyParticipacionEmpresa, participacionEmpresa] = getObjSection('participacionEmpresa')

  const dataPempObjIS = {
    parentesco: { key: "parentesco", title: "Parentesco", id: "dataPempObjParentesco", required: true, type: 'select'},
    nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataPempObjNombre", required: true, type: 'input'},
    tipoDoc: { key: "tipoDoc", title: "Tipo de documento de identidad", id: "dataPempObjTipoDoc", required: true, type: 'select'},
    doc: { key: "doc", title: "Documento de identidad", maxLength:50, id: "dataPempObjDoc", required: true, type: 'input'},
    grupo: { key: "grupo", title: "Empresa del Grupo", id: "dataPempObjGrupo", required: true, type: 'select'},
    cargo: { key: "cargo", title: "Cargo", id: "dataPempObjCargo", required: true, type: 'select'},
    tipoPropiedad: { key: "tipoPropiedad", title: "Tipo de Propiedad", id: "dataPempObjTipoPropiedad", required: true, type: 'select'},
    porcentajeParticipacion: { key: "porcentajeParticipacion",  title: "Porcentaje de Participación", id: "dataPempObjPorcentajeParticipacion", required: true, type: 'number', isPercentage: true, max: 100},
    nombreEmpIndirecta: { key: "nombreEmpIndirecta", title: "Nombre de Empresa Propiedad Indirecta",maxLength:100 ,id: "dataPempObjNombreEmpIndirecta", required: true, type: 'input'},
    tipoDocEmpIndirecta: { key: "tipoDocEmpIndirecta", title: "Tipo de documento Empresa propiedad indirecta", id: "dataPempObjTipoDocEmpIndirecta", required: true, type: 'select'},
    docEmpIndirecta: { key: "docEmpIndirecta", title: "Documento Empresa propiedad indirecta", maxLength:50, id: "dataPempObjDocEmpIndirecta", required: true, type: 'input'},
  };
  const [keyDataPempObj, dataPempObj] = getObjSection('dataPempObj')

  // INTERLOCKING
const interlockingIS = {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "interlockingHasInterlocking", required: true },
  records: { key: "records", val: [], id: "interlockingRecords" },
};
const [keyInterlocking, interlocking] = getObjSection('interlocking')

const interObjIS = {
  razonSocial: { key: "razonSocial",  title: "Razón social", maxLength: 100, id: "interObjRazonSocial", required: true, type: 'input' },
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "interObjTipoDoc", required: true, type: 'select' },
  doc: { key: "doc",  title: "Documento de identidad", maxLength: 50, id: "interObjDoc", required: true, type: 'input' },
  fechaIngreso: { key: "fechaIngreso",  title: "Fecha de ingreso", id: "interObjFechaIngreso", required: true, type: 'date' },
  fechaTermino: { key: "fechaTermino",  title: "Fecha de término", id: "interObjFechaTermino", required: false, type: 'date' },
  cargo: { key: "cargo",  title: "Cargo", maxLength: 200, id: "interObjCargo", required: true, type: 'select' },
};
const [keyInterObj, interObj] = getObjSection('interObj')

  // RELACIÓN CON PROVEEDORES, CONTRATISTAS, Y/O CLIENTES
const relacionProveedoresIS = {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "relacionProveedoresHasRelProveedores", required: true },
  records: { key: "records", val: [], id: "relacionProveedoresRecords" },
};
const [keyRelacionProveedores, relacionProveedores] = getObjSection('relacionProveedores')

const relProvObjIS = {
  razonSocial: { key: "razonSocial",  title: "Razón Social", maxLength:100, id: "relProvObjRazonSocial", required: true, type: 'input'},
  tipoDocEmp: { key: "tipoDocEmp",  title: "Tipo de documento de identidad", id: "relProvObjTipoDocEmp", required: true, type: 'select'},
  docEmp: { key: "docEmp",  title: "Documento de identidad", maxLength:50, id: "relProvObjDocEmp", required: true, type: 'input'},
  tipoEmpresa: { key: "tipoEmpresa",  title: "Tipo de Empresa", maxLength:100, id: "relProvObjTipoEmpresa", required: true, type: 'select'},
  parentesco: { key: "parentesco",  title: "Parentesco", id: "relProvObjParentesco", required: true, type: 'select'},
  grupo: { key: "grupo",  title: "Empresa del Grupo", id: "relProvObjGrupo", required: true, type: 'select'},
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "relProvObjNombre", required: true, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "relProvObjTipoDoc", required: true, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "relProvObjDoc", required: true, type: 'input'},
  cargo: { key: "cargo",  title: "Cargo", id: "relProvObjCargo", required: true, type: 'select'},
  tipoPropiedad: { key: "tipoPropiedad",  title: "Tipo de Propiedad", id: "relProvObjTipoPropiedad", required: true, type: 'select' },
  porcentajeParticipacion: { key: "porcentajeParticipacion",  title: "Porcentaje de Participación", id: "relProvObjPorcentajeParticipacion", required: true, type: 'number', isPercentage: true, max: 100 },
  nombreEmpIndirecta: { key: "nombreEmpIndirecta",  title: "Nombre de Empresa Propiedad Indirecta", maxLength: 100, id: "relProvObjNombreEmpIndirecta", required: true, type: 'input' },
  tipoDocEmpIndirecta: { key: "tipoDocEmpIndirecta",  title: "Tipo documento Empresa Propiedad Indirecta", id: "relProvObjTipoDocEmpIndirecta", required: true, type: 'select' },
  docEmpIndirecta: { key: "docEmpIndirecta",  title: "Documento Empresa propiedad indirecta", maxLength: 50, id: "relProvObjDocEmpIndirecta", required: true, type: 'input' },
};
const [keyRelProvObj, relProvObj] = getObjSection('relProvObj')

// ACTIVIDADES FUERA DE LA EMPRESA
const fueraDeHorarioIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "fueraDeHorarioHasActFueraHorario", required: true},
  records: { key: "records", val: [], id: "fueraDeHorarioRecords"},
};
const [keyFueraDeHorario, fueraDeHorario] = getObjSection('fueraDeHorario')

const actFueraObjIS = {
  razonSocial: { key: "razonSocial",  title: "Razón Social", maxLength:100, id: "actFueraObjRazonSocial", required: true, type: 'input'},
  tipoDocEmp: { key: "tipoDocEmp",  title: "Tipo de documento de identidad", id: "actFueraObjTipoDocEmp", required: true, type: 'select'},
  docEmp: { key: "docEmp",  title: "Documento de identidad", maxLength:50, id: "actFueraObjDocEmp", required: true, type: 'input'},
  actividad: { key: "actividad",  title: "Trabajo o actividad laboral realizada", maxLength:100, id: "actFueraObjActividad", required: true, type: 'input'},
};
const [keyActFueraObj, actFueraObj] = getObjSection('actFueraObj')

// RELACIONES LABORALES ANTERIORES
const empleosAnterioresIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "empleosAnterioresHasEmpleosAnteriores", required: true},
  records: { key: "records", val: [], id: "empleosAnterioresRecords"},
};
const [keyEmpleosAnteriores, empleosAnteriores] = getObjSection('empleosAnteriores')

const empleosAntObjIS = {
  razonSocial: { key: "razonSocial",  title: "Razón Social", maxLength:100, id: "empleosAntObjRazonSocial", required: true, type: 'input'},
  tipoDocEmp: { key: "tipoDocEmp",  title: "Tipo de documento de identidad", id: "empleosAntObjTipoDocEmp", required: true, type: 'select'},
  docEmp: { key: "docEmp",  title: "Documento de identidad", maxLength:50, id: "empleosAntObjDocEmp", required: true, type: 'input'},
  cargo: { key: "cargo",  title: "Cargo ejercido", maxLength:100, id: "empleosAntObjCargo", required: true, type: 'input'},
  fechaIngreso: { key: "fechaIngreso",  title: "Fecha de ingreso", id: "empleosAntObjFechaIngreso", required: true, type: 'date'},
  fechaTermino: { key: "fechaTermino",  title: "Fecha de término", id: "empleosAntObjFechaTermino", required: true, type: 'date'},
};
const [keyEmpleosAntObj, empleosAntObj] = getObjSection('empleosAntObj')

// RELACION CON LA COMPETENCIA
const relacionCompetenciaIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "relacionCompetenciaHasCompetencia", required: true},
  records: { key: "records", val: [], id: "relacionCompetenciaRecords"},
};
const [keyRelacionCompetencia, relacionCompetencia] = getObjSection('relacionCompetencia')

const dataCompObjIS = {
  razonSocial: { key: "razonSocial",  title: "Razón Social", maxLength:100, id: "dataCompObjRazonSocial", required: true, type: 'input'},
  tipoDocEmp: { key: "tipoDocEmp",  title: "Tipo de documento de identidad", id: "dataCompObjTipoDocEmp", required: true, type: 'select'},
  docEmp: { key: "docEmp",  title: "Documento de identidad", maxLength:50, id: "dataCompObjDocEmp", required: true, type: 'input'},
  parentesco: { key: "parentesco",  title: "Parentesco", id: "dataCompObjParentesco", required: true, type: 'select'},
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataCompObjNombre", required: true, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "dataCompObjTipoDoc", required: true, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "dataCompObjDoc", required: true, type: 'input'},
  cargo: { key: "cargo",  title: "Cargo", maxLength:100, id: "dataCompObjCargo", required: true, type: 'input'},
};
const [keyDataCompObj, dataCompObj] = getObjSection('dataCompObj')

// RELACION DE PARENTESCO O PAREJA
const relacionParentescoIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "relacionParentescoHasParentesco", required: true},
  records: { key: "records", val: [], id: "relacionParentescoRecords"},
};
const [keyRelacionParentesco, relacionParentesco] = getObjSection('relacionParentesco')

const dataRelParObjIS = {
  parentesco: { key: "parentesco",  title: "Parentesco", id: "dataRelParObjParentesco", required: true, type: 'select'},
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataRelParObjNombre", required: true, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "dataRelParObjTipoDoc", required: true, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "dataRelParObjDoc", required: true, type: 'input'},
  cargo: { key: "cargo",  title: "Cargo", maxLength:100, id: "dataRelParObjCargo", required: true, type: 'input'},
  grupo: { key: "grupo",  title: "Empresas del Grupo", id: "dataRelParObjGrupo", required: true, type: 'select'},
};
const [keyDataRelParObj, dataRelParObj] = getObjSection('dataRelParObj')

// FUNCIONARIOS PUBLICOS O PERSONAS EXPUESTAS POLÍTICAMENTE
const funcionariosPublicosIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", maxLength:200, id: "funcionariosPublicosHasPep", required: true},
  records: { key: "records", val: [], id: "funcionariosPublicosRecords"},
};
const [keyFuncionariosPublicos, funcionariosPublicos] = getObjSection('funcionariosPublicos')

const dataPepObjIS = {
  categoria: { key: "categoria",  title: "Categoría", id: "dataPepObjCategoria", required: true, type: 'select'},
  razonSocial: { key: "razonSocial",  title: "Institución u Organismo Público", maxLength:100, id: "dataPepObjRazonSocial", required: true, type: 'input'},
  parentesco: { key: "parentesco",  title: "Parentesco", id: "dataPepObjParentesco", required: true, type: 'select'},
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataPepObjNombre", required: true, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "dataPepObjTipoDoc", required: true, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad",maxLength:50, id: "dataPepObjDoc", required: true, type: 'input'},
  cargo: { key: "cargo",  title: "Cargo", maxLength:100, id: "dataPepObjCargo", required: true, type: 'input'},
  fechaTermino: { key: "fechaTermino",  title: "Fecha de término", id: "dataPepObjFechaTermino", required: false, type: 'date'},
};
const [keyDataPepObj, dataPepObj] = getObjSection('dataPepObj')

// RELACIÓN CON FUNDACIONES, CORPORACIONES, INSTITUCIONES DE BENEFICIENCIA Y/O SIN FINES DE LUCRO
const relacionFundacionesIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", id: "relacionFundacionesHasFundaciones", required: true},
  records: { key: "records", val: [], id: "relacionFundacionesRecords"},
};
const [keyRelacionFundaciones, relacionFundaciones] = getObjSection('relacionFundaciones')

const dataRelFundObjIS = {
  tipoInstitucion: { key: "tipoInstitucion",  title: "Tipo de Institución", id: "dataRelFundObjTipoInstitucion", required: true, type: 'select'},
  nombreInstitucion: { key: "razonSocial",  title: "Nombre de la Institución", maxLength:100, id: "dataRelFundObjNombreInstitucion", required: true, type: 'input'},
  tipoDocEmp: { key: "tipoDocEmp",  title: "Tipo de documento de identidad", id: "dataRelFundObjTipoDocEmp", required: true, type: 'select'},
  docEmp: { key: "docEmp",  title: "Documento de identidad", maxLength:50, id: "dataRelFundObjDocEmp", required: true, type: 'input'},
  parentesco: { key: "parentesco",  title: "Parentesco", id: "dataRelFundObjParentesco", required: true, type: 'select'},
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataRelFundObjNombre", required: true, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "dataRelFundObjTipoDoc", required: true, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "dataRelFundObjDoc", required: true, type: 'input'},
  cargo: { key: "cargo",  title: "Cargo", id: "dataRelFundObjCargo", required: true, type: 'select'},
  area: { key: "area",  title: "Área en la que se desarrolla", maxLength:70, id: "dataRelFundObjArea", required: true, type: 'input'},
};
const [keyDataRelFundObj, dataRelFundObj] = getObjSection('dataRelFundObj')

// OTROS CONFLICTOS DE INTERÉS
const otrosConflictosIS= {
  hasRecords: { key: "hasRecords", val: undefined, title: "", maxLength:200, id: "otrosConflictosHasOtrosConflictos", required: true},
  records: { key: "records", val: [], id: "otrosConflictosRecords"},
};
const [keyOtrosConflictos, otrosConflictos] = getObjSection('otrosConflictos')

const dataOtrosConObjIS = {
  razonSocial: { key: "razonSocial",  title: "Razón Social", maxLength:100, id: "dataOtrosConObjRazonSocial", required: false, type: 'input'},
  tipoDocEmp: { key: "tipoDocEmp",  title: "Tipo de documento de identidad", id: "dataOtrosConObjTipoDocEmp", required: false, type: 'select'},
  docEmp: { key: "docEmp",  title: "Documento de identidad", maxLength:50, id: "dataOtrosConObjDocEmp", required: false, type: 'input'},
  nombre: { key: "nombre",  title: "Nombre", maxLength:100, id: "dataOtrosConObjNombre", required: false, type: 'input'},
  tipoDoc: { key: "tipoDoc",  title: "Tipo de documento de identidad", id: "dataOtrosConObjTipoDoc", required: false, type: 'select'},
  doc: { key: "doc",  title: "Documento de identidad", maxLength:50, id: "dataOtrosConObjDoc", required: false, type: 'input'},
  descripcion: { key: "descripcion",  title: "Ingrese una breve descripción de la situación", maxLength:500, id: "dataOtrosConObjDescripcion", required: true, type: 'textarea'},
};
const [keyDataOtrosConObj, dataOtrosConObj] = getObjSection('dataOtrosConObj')

//PARTICIPACION EN LA PROPIEDAD DE LA EMPRESA --> PARENTESCO === MI PERSONA
useEffect(() => {
  if(dataPempObj) {
    let nombre = undefined
    let rut = undefined
    let tipoDoc = undefined
    if (dataPempObj.parentesco.val === "Mi Persona") {
      nombre = registerData.nombre
      rut = registerData.rut
      tipoDoc = registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
    }
    setFields({ [dataPempObj.nombre.id]: { value: nombre }, 
                [dataPempObj.tipoDoc.id]: { value: tipoDoc },
                [dataPempObj.doc.id]: { value: rut }
              })
    if(handleOnChangeFields) {
      handleOnChangeFields({ 
        [dataPempObj.nombre.id]:  nombre, 
        [dataPempObj.tipoDoc.id]: tipoDoc, 
        [dataPempObj.doc.id]: rut, 
      })
    }
  }
}, [dataPempObj?.parentesco?.val]);

//PARTICIPACION EN OTRAS SOCIEDADES --> Cargo y Tipo de Propiedad
useEffect(() => {
  if(dataPsocObj) {
    if(dataPsocObj.tipoPropiedad.val !== "Indirecta" || (dataPsocObj?.cargo?.val !== "Accionista" && dataPsocObj?.cargo?.val !== "Propietario")) {
      setFields({ [dataPsocObj.nombreEmpIndirecta.id]: { value: undefined },
        [dataPsocObj.tipoDocEmpIndirecta.id]: { value: undefined },
        [dataPsocObj.docEmpIndirecta.id]: { value: undefined },
      })

      if(handleOnChangeFields) {
        handleOnChangeFields({ [dataPsocObj.nombreEmpIndirecta.id]:  undefined, 
          [dataPsocObj.tipoDocEmpIndirecta.id]: undefined, 
          [dataPsocObj.docEmpIndirecta.id]: undefined, 
        })
      }
      if(dataPsocObj?.cargo?.val !== "Accionista" && dataPsocObj?.cargo?.val !== "Propietario") {
        setFields({ [dataPsocObj.tipoPropiedad.id]: { value: undefined },
          [dataPsocObj.porcentajeParticipacion.id]: { value: undefined },
        })

        if(handleOnChangeFields) {
          handleOnChangeFields({ [dataPsocObj.tipoPropiedad.id]:  undefined, 
            [dataPsocObj.porcentajeParticipacion.id]: undefined, 
          })
        }
      }
    }
  }
}, [dataPsocObj?.cargo?.val, dataPsocObj?.tipoPropiedad?.val]);
//PARTICIPACION EN LA PROPIEDAD DE LA EMPRESA --> Cargo y Tipo de Propiedad
useEffect(() => {
  if(dataPempObj) {
    if(dataPempObj.tipoPropiedad.val !== "Indirecta" || (dataPempObj?.cargo?.val !== "Accionista" && dataPempObj?.cargo?.val !== "Propietario")) {
      setFields({ [dataPempObj.nombreEmpIndirecta.id]: { value: undefined },
        [dataPempObj.tipoDocEmpIndirecta.id]: { value: undefined },
        [dataPempObj.docEmpIndirecta.id]: { value: undefined },
      })

      if(handleOnChangeFields) {
        handleOnChangeFields({ [dataPempObj.nombreEmpIndirecta.id]:  undefined, 
          [dataPempObj.tipoDocEmpIndirecta.id]: undefined, 
          [dataPempObj.docEmpIndirecta.id]: undefined, 
        })
      }
      if(dataPempObj?.cargo?.val !== "Accionista" && dataPempObj?.cargo?.val !== "Propietario") {
        setFields({ [dataPempObj.tipoPropiedad.id]: { value: undefined },
          [dataPempObj.porcentajeParticipacion.id]: { value: undefined },
        })

        if(handleOnChangeFields) {
          handleOnChangeFields({ [dataPempObj.tipoPropiedad.id]:  undefined, 
            [dataPempObj.porcentajeParticipacion.id]: undefined, 
          })
        }
      }
    }
  }
}, [dataPempObj?.cargo?.val, dataPempObj?.tipoPropiedad?.val]);
//RELACION CON PROVEEDORES --> Cargo y Tipo de Propiedad
useEffect(() => {
  if(relProvObj) {
    if(relProvObj.tipoPropiedad.val !== "Indirecta" || (relProvObj?.cargo?.val !== "Accionista" && relProvObj?.cargo?.val !== "Propietario")) {
      setFields({ [relProvObj.nombreEmpIndirecta.id]: { value: undefined },
        [relProvObj.tipoDocEmpIndirecta.id]: { value: undefined },
        [relProvObj.docEmpIndirecta.id]: { value: undefined },
      })

      if(handleOnChangeFields) {
        handleOnChangeFields({ [relProvObj.nombreEmpIndirecta.id]:  undefined, 
          [relProvObj.tipoDocEmpIndirecta.id]: undefined, 
          [relProvObj.docEmpIndirecta.id]: undefined, 
        })
      }
      if(relProvObj?.cargo?.val !== "Accionista" && relProvObj?.cargo?.val !== "Propietario") {
        setFields({ [relProvObj.tipoPropiedad.id]: { value: undefined },
          [relProvObj.porcentajeParticipacion.id]: { value: undefined },
        })

        if(handleOnChangeFields) {
          handleOnChangeFields({ [relProvObj.tipoPropiedad.id]:  undefined, 
            [relProvObj.porcentajeParticipacion.id]: undefined, 
          })
        }
      }
    }
  }
}, [relProvObj?.cargo?.val, relProvObj?.tipoPropiedad?.val]);

//PARTICIPACION EN OTRAS SOCIEDADES --> PARENTESCO === MI PERSONA
useEffect(() => {
  if(dataPsocObj) {
    let nombre = undefined
    let rut = undefined
    let tipoDoc = undefined
    if (dataPsocObj.parentesco.val === "Mi Persona") {
      nombre = registerData.nombre
      rut = registerData.rut
      tipoDoc = registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
    }
    setFields({ [dataPsocObj.nombre.id]: { value: nombre },
                [dataPsocObj.tipoDoc.id]: { value: tipoDoc },
                [dataPsocObj.doc.id]: { value: rut }
              })

    if(handleOnChangeFields) {
      handleOnChangeFields({ [dataPsocObj.nombre.id]:  nombre, 
        [dataPsocObj.tipoDoc.id]: tipoDoc, 
        [dataPsocObj.doc.id]: rut
      })
    }
  }
}, [dataPsocObj?.parentesco?.val]);

//RELACION CON PROVEEDORES --> PARENTESCO === MI PERSONA
useEffect(() => {
  if(relProvObj) {
    let nombre = undefined
    let rut = undefined
    let tipoDoc = undefined
    if (relProvObj.parentesco.val === "Mi Persona") {
      nombre = registerData.nombre
      rut = registerData.rut
      tipoDoc = registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
    }
    setFields({ [relProvObj.nombre.id]: { value: nombre },
                [relProvObj.tipoDoc.id]: { value: tipoDoc },
                [relProvObj.doc.id]: { value: rut }
              })

    if(handleOnChangeFields) {
      handleOnChangeFields({ 
        [relProvObj.nombre.id]:  nombre, 
        [relProvObj.tipoDoc.id]: tipoDoc, 
        [relProvObj.doc.id]: rut,
      })
    }
  }
}, [relProvObj?.parentesco?.val]);

//FUNCIONARIOS PUBLICOS --> PARENTESCO === MI PERSONA
useEffect(() => {
  if(dataPepObj) {
    let nombre = undefined
    let rut = undefined
    let tipoDoc = undefined
    if (dataPepObj.parentesco.val === "Mi Persona") {
      nombre = registerData.nombre
      rut = registerData.rut
      tipoDoc = registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
    }
    setFields({ [dataPepObj.nombre.id]: { value: nombre },
                [dataPepObj.tipoDoc.id]: { value: tipoDoc },
                [dataPepObj.doc.id]: { value: rut }
              })

    if(handleOnChangeFields) {
      handleOnChangeFields({ 
        [dataPepObj.nombre.id]:  nombre, 
        [dataPepObj.tipoDoc.id]: tipoDoc, 
        [dataPepObj.doc.id]: rut,
      })
    }
  }
}, [dataPepObj?.parentesco?.val]);

//RELACIÓN CON FUNDACIONES, CORPORACIONES, INSTITUCIONES DE BENEFICIENCIA Y/O SIN FINES DE LUCRO --> PARENTESCO === MI PERSONA
useEffect(() => {
  if(dataRelFundObj) {
    let nombre = undefined
    let rut = undefined
    let tipoDoc = undefined
    if (dataRelFundObj.parentesco.val === "Mi Persona") {
      nombre = registerData.nombre
      rut = registerData.rut
      tipoDoc = registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
    }
    setFields({ [dataRelFundObj.nombre.id]: { value: nombre },
                [dataRelFundObj.tipoDoc.id]: { value: tipoDoc },
                [dataRelFundObj.doc.id]: { value: rut }
              })

    if(handleOnChangeFields) {
      handleOnChangeFields({ 
        [dataRelFundObj.nombre.id]:  nombre, 
        [dataRelFundObj.tipoDoc.id]: tipoDoc, 
        [dataRelFundObj.doc.id]: rut,
      })
    }
  }
}, [dataRelFundObj?.parentesco?.val]);

  useEffect(() => {
    const obj = { basicInformation: basicInformationIS,
          mallaParental: mallaParentalIS,
          mallaParObj: mallaParObjIS,
          participacionSociedades: participacionSociedadesIS,
          dataPsocObj: dataPsocObjIS,
          participacionEmpresa: participacionEmpresaIS,
          dataPempObj: dataPempObjIS,
          interlocking: interlockingIS,
          interObj: interObjIS,
          relacionProveedores: relacionProveedoresIS,
          relProvObj: relProvObjIS,
          fueraDeHorario: fueraDeHorarioIS,
          actFueraObj: actFueraObjIS,
          empleosAnteriores: empleosAnterioresIS,
          empleosAntObj: empleosAntObjIS,
          relacionCompetencia: relacionCompetenciaIS,
          dataCompObj: dataCompObjIS,
          relacionParentesco: relacionParentescoIS,
          dataRelParObj: dataRelParObjIS,
          funcionariosPublicos: funcionariosPublicosIS,
          dataPepObj: dataPepObjIS,
          relacionFundaciones: relacionFundacionesIS,
          dataRelFundObj: dataRelFundObjIS,
          otrosConflictos: otrosConflictosIS,
          dataOtrosConObj: dataOtrosConObjIS
    }

    if (match.params.view === "pdf") {
      setColLogo(5);
      setFormat("pdf");
    }

    getCountriesCodePromise().then((response) => {
      setPaises(response)
    })
    getFormPromise(match.params.id).then((response) => {
      if (response?.data?.status) {
        setApiForm(response.data);
        initVariablesFromObject(response.data, obj)
        setUser(response.data.recipient.request.createUser);
        setClientName(response.data.recipient.request.createUser.cliente.name);
        setRegisterData(response.data.recipient.record)
        if(response.data.recipient.request.createUser.cliente.outsourcer){
          setEmpresasGrupo(response.data.recipient.request.createUser.cliente.clientes.map(item => {
            return item.name
          }))
        }

        // getParamsPromise(
        //   response.data.recipient.request.createUser.cliente.id
        // ).then((response) => {
        //   setParams(response.data);
        //   setEmpresasGrupo(response.data.empresas)
        // });

        if(response.data.status === "SENT") {
          setDate(moment(response.data.receiveDate).format("DD-MM-YYYY"));
        }else {
          setDate(moment().format("DD-MM-YYYY"));
        }
      }
      setIsloading(false);
    });

    const handleMessage = (message) => {
      if(message.data && typeof message.data === 'string' && message.data.startsWith('DOCUSIGN') && apiConfig.url.startsWith(message.origin)) {
        const data = message.data.split('|')
        if(data.length === 4) {
          if(data[1] === "signing_complete") {
            const reqId = data[2] + "|" + data[3]
            sendDocument(reqId, apiFormRef.current)
          }else {
            closeHandlerSigner();
            notification["warning"]({
              message: "Firma cancelada",
            });
          }
        }
      }
    }

    window.addEventListener("message", handleMessage);
    return function () {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const closeHandlerSigner = () => {
    setSignData(null)
    setOpenSigner(false)
  }

  const mallaColumns = [
    {
      title: "Parentesco",
      dataIndex: "parentesco",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "Tipo de documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    format === "html" && !signed ? {
          title: "Acción",
          dataIndex: "",
          width: "8%",
          render: (text, record, index) => (
            <a onClick={handleDeleteAttrTable({attrTable: 'mallaParental', index})}>
              <Icon type="delete" />
            </a>
          ),
        }
      : {},
  ];

  const pempColumns = [
    {
      title: "Parentesco",
      dataIndex: "parentesco",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Empresa del Grupo",
      dataIndex: "grupo",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'participacionEmpresa', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const psocColumns = [
    {
      title: "Razón Social",
      dataIndex: "razonSocial",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de documento de identidad",
      dataIndex: "tipoDocEmp",
    },
    {
      title: "Documento Empresa",
      dataIndex: "docEmp",
    },
    {
      title: "País de Constitución",
      dataIndex: "paisEmp",
    },
    {
      title: "Parentesco",
      dataIndex: "parentesco",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "Tipo de documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Cargo",
      dataIndex: "cargo"
    },
    format === "html" && !signed ? {
          title: "Acción",
          dataIndex: "",
          width: "8%",
          render: (text, record, index) => (
            <a onClick={handleDeleteAttrTable({attrTable: 'participacionSociedades', index})}>
              <Icon type="delete" />
            </a>
          ),
        }
      : {},
  ];

  const interlockingColumns = [
    {
      title: "Razón social",
      dataIndex: "razonSocial",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Fecha de Ingreso",
      dataIndex: "fechaIngreso",
    },
    {
      title: "Fecha de Término",
      dataIndex: "fechaTermino",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'interlocking', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const relProvColumns = [
    {
      title: "Razón Social",
      dataIndex: "razonSocial",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento",
      dataIndex: "tipoDocEmp",
    },
    {
      title: "Documento",
      dataIndex: "docEmp",
    },
    {
      title: "Tipo de Empresa",
      dataIndex: "tipoEmpresa",
    },
    {
      title: "Parentesco",
      dataIndex: "parentesco",
    },
    {
      title: "Empresa del Grupo",
      dataIndex: "grupo",
    },
    {
      title: "Nombre persona",
      dataIndex: "nombre",
    },
    {
      title: "Tipo de documento de indentidad",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento de identidad",
      dataIndex: "doc",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'relacionProveedores', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const fueraDeHorarioColumns = [
    {
      title: "Razón Social",
      dataIndex: "razonSocial",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento Empresa",
      dataIndex: "tipoDocEmp",
    },
    {
      title: "Documento Empresa",
      dataIndex: "docEmp",
    },
    {
      title: "Actividad",
      dataIndex: "actividad",
      className: "overflow-wrap-text"
    },

    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'fueraDeHorario', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const empleosAnterioresColumns = [
    {
      title: "Razón Social",
      dataIndex: "razonSocial",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento Empresa",
      dataIndex: "tipoDocEmp",
    },
    {
      title: "Documento Empresa",
      dataIndex: "docEmp",
    },
    {
      title: "Cargo Ejercido",
      dataIndex: "cargo",
    },
    {
      title: "Fecha de Ingreso",
      dataIndex: "fechaIngreso",
    },
    {
      title: "Fecha de Término",
      dataIndex: "fechaTermino",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'empleosAnteriores', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const relacionCompetenciaColumns = [
    {
      title: "Razón Social",
      dataIndex: "razonSocial",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento Empresa",
      dataIndex: "tipoDocEmp",
    },
    {
      title: "Documento Empresa",
      dataIndex: "docEmp",
    },
    {
      title: "Parentesco",
      dataIndex: "parentesco",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "Tipo de Documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'relacionCompetencia', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const relacionParentescoColumns = [
    {
      title: "Parentesco",
      dataIndex: "parentesco",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
    },
    {
      title: "Empresas del Grupo",
      dataIndex: "grupo",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'relacionParentesco', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const funcionariosPublicosColumns = [
    {
      title: "Categoría",
      dataIndex: "categoria",
    },
    {
      title: "Institución u Organismo Público",
      dataIndex: "razonSocial",
    },
    {
      title: "Parentesco",
      dataIndex: "parentesco",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento",
      dataIndex: "doc",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
    },
    {
      title: "Fecha de término",
      dataIndex: "fechaTermino",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'funcionariosPublicos', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const relacionFundacionesColumns = [
    {
      title: "Tipo de Institución",
      dataIndex: "tipoInstitucion",
    },
    {
      title: "Nombre de la Institución",
      dataIndex: "razonSocial",
      className: "overflow-wrap-text"
    },
    {
      title: "Tipo de Documento Empresa",
      dataIndex: "tipoDocEmp",
    },
    {
      title: "Documento Empresa",
      dataIndex: "docEmp",
    },
    {
      title: "Parentesco",
      dataIndex: "parentesco",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "Tipo de Documento Personal",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento Personal",
      dataIndex: "doc",
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
    },
    {
      title: "Área",
      dataIndex: "area",
      className: "overflow-wrap-text"
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'relacionFundaciones', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  const otrosConflictosColumns = [
    {
      title: "Razón Social",
      dataIndex: "razonSocial",
    },
    {
      title: "Tipo de Documento Empresa",
      dataIndex: "tipoDocEmp",
    },
    {
      title: "Documento Empresa",
      dataIndex: "docEmp",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "Tipo de Documento Personal",
      dataIndex: "tipoDoc",
    },
    {
      title: "Documento Personal",
      dataIndex: "doc",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      className: "overflow-wrap-text"
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'otrosConflictos', index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ];

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }
  

const handleOnAddAttrTableLocal = (attrObj, attrTable, attrRecords='records') => {
  setIsValidate(true);
  if(handleOnAddAttrTable(attrObj, attrTable, attrRecords)) {
    registersStop[attrObj] = false;
  }
}

  const handleSubmit = async (e) => {
    console.log(basicInformation)
    //VALIDACIONES
    e.preventDefault();
    setIsValidate(true);
    setValidarRegistros(true);
    validateFieldsAndScroll([basicInformation.cargo.id, basicInformation.gerencia.id, basicInformation.fechaContratacion.id])
    validateFieldsAndScroll([
      participacionEmpresa.hasRecords.id,
      participacionSociedades.hasRecords.id,
      relacionFundaciones.hasRecords.id,
      otrosConflictos.hasRecords.id,
      funcionariosPublicos.hasRecords.id,
      relacionParentesco.hasRecords.id,
      relacionCompetencia.hasRecords.id,
      fueraDeHorario.hasRecords.id,
      empleosAnteriores.hasRecords.id,
      interlocking.hasRecords.id,
      relacionProveedores.hasRecords.id
    ]);
    if(mallaParental.records.val.length < 2){
      validateFieldsAndScroll(Object.values(mallaParObj).map(v => v.id))
      registersStop = { ...registersStop, [mallaParental.records.id]: true };
    }

    // Validación para Participación en Empresas
    if (participacionEmpresa.hasRecords.val && participacionEmpresa.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataPempObj).map(v => v.id));
      registersStop = { ...registersStop, [participacionEmpresa.records.id]: true };
    }

    // Validación para Participación en Sociedades
    if (participacionSociedades.hasRecords.val && participacionSociedades.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataPsocObj).map(v => v.id));
      registersStop = { ...registersStop, [participacionSociedades.records.id]: true };
    }

    // Validación para la nueva sección Relación con Fundaciones
    if (relacionFundaciones.hasRecords.val && relacionFundaciones.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataRelFundObj).map(v => v.id));
      registersStop = { ...registersStop, [relacionFundaciones.records.id]: true };
    }

    // Validación para la nueva sección Otros Conflictos de Interés
    if (otrosConflictos.hasRecords.val && otrosConflictos.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataOtrosConObj).map(v => v.id));
      registersStop = { ...registersStop, [otrosConflictos.records.id]: true };
    }

    // Validación para la nueva sección Funcionarios Públicos
    if (funcionariosPublicos.hasRecords.val && funcionariosPublicos.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataPepObj).map(v => v.id));
      registersStop = { ...registersStop, [funcionariosPublicos.records.id]: true };
    }

    // Validación para la nueva sección Relación de Parentesco
    if (relacionParentesco.hasRecords.val && relacionParentesco.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataRelParObj).map(v => v.id));
      registersStop = { ...registersStop, [relacionParentesco.records.id]: true };
    }

    // Validación para la nueva sección Relación con la Competencia
    if (relacionCompetencia.hasRecords.val && relacionCompetencia.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(dataCompObj).map(v => v.id));
      registersStop = { ...registersStop, [relacionCompetencia.records.id]: true };
    }

    // Validación para la nueva sección Actividades Fuera de Horario
    if (fueraDeHorario.hasRecords.val && fueraDeHorario.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(actFueraObj).map(v => v.id));
      registersStop = { ...registersStop, [fueraDeHorario.records.id]: true };
    }

    // Validación para la nueva sección Empleos Anteriores
    if (empleosAnteriores.hasRecords.val && empleosAnteriores.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(empleosAntObj).map(v => v.id));
      registersStop = { ...registersStop, [empleosAnteriores.records.id]: true };
    }

    //Validación para la sección Interlocking
    if (interlocking.hasRecords.val && interlocking.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(interObj).map(v => v.id));
      registersStop = { ...registersStop, [interlocking.records.id]: true };
    }

    //Validación para la sección Relación Proveedores
    if (relacionProveedores.hasRecords.val && relacionProveedores.records.val.length === 0) {
      validateFieldsAndScroll(Object.values(relProvObj).map(v => v.id));
      registersStop = { ...registersStop, [relacionProveedores.records.id]: true };
    }

    if (
      hasErrors(getFieldsError()) ||
      Object.values(registersStop).find((value) => value === true) !== undefined
    ) {
      notification["warning"]({
        message: t("messages.aml.missingRequiredField"),
      });
    } else {
      //FUNCION DE PROMISE Y GESTION DE RESPONSE
      sendDocument()
    }
  };

  return (
    <FormLayout view={match.params.view} >
      <div style={{ position: "relative" }}>
        {isLoading ? (
          <div className="form-header">
            <Row>
              <Col xs={9}></Col>
              <Col xs={6}>
                <div className="form-wrapper" style={{ textAlign: "center" }}>
                  <Spin style={{ fontColor: "#fff" }} size={"large"} />
                </div>
              </Col>
              <Col xs={9}></Col>
            </Row>
          </div>
        ) : (
          <div className="form-trab-aguasandinas">
            {apiForm !== null ? (
              <div
                className={
                  "form-content " + format + " " +(!isValidate ? " form-validate-messages" : "")
                }
              >
                <Form onSubmit={handleSubmit} className="form-form">
                  <Row
                    className="title-logo"
                    gutter={[0, 6]}
                    style={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      marginTop: "0px",
                      marginBottom: "0px",
                      textAlign: "left",
                      paddingTop: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Col
                      xs={24 - colLogo}
                      sm={24 - colLogo}
                      md={24 - colLogo}
                      lg={24 - colLogo}
                      xl={24 - colLogo}
                    >
                      <h3>FORMULARIO DE CONFLICTO DE INTERÉS - COLABORADORES</h3>
                    </Col>
                    <Col
                      className="logo-col"
                      xs={colLogo}
                      sm={colLogo}
                      md={colLogo}
                      lg={colLogo}
                      xl={colLogo}
                    >
                      <Logo currentUser={{ userId: user.id, subclienteId: '0' }} isForm={true} />
                    </Col>
                  </Row>
                  <Row
                    className="date"
                    gutter={[0, 6]}
                    style={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      marginTop: "0px",
                      marginBottom: "0px",
                      textAlign: "left",
                      paddingBottom: "15px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Col span={2} style={{ textAlign: "left" }}>
                      Fecha:
                    </Col>
                    <Col span={4}>
                      <div
                        className="formDate"
                        style={{
                          marginLeft: "5px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "#e8e8e8",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {date}
                      </div>
                    </Col>
                    <Col span={13} style={{ textAlign: "right" }}>
                      Folio:
                    </Col>
                    <Col span={5}>
                      <div
                        className="formDate"
                        style={{
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "#e8e8e8",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "10px",
                          marginLeft: "8px",
                        }}
                      >
                        {apiForm.folio !== null ? apiForm.folio : ""}
                      </div>
                    </Col>
                  </Row>
                  {apiForm.status === "SENT" &&
                  (format === "html") ? (
                    <>
                      <br />
                      <h3 style={{ textAlign: "center" }}>
                        Estimado(a){/* {apiForm.recipient.record.nombre} */}
                        {apiForm.recipient.record.name}, le informamos que su declaración fue correctamente
                        completada, agradecemos su tiempo y disposición.
                        <br />
                        Hemos enviado una copia de la declaración realizada al
                        correo electrónico registrado:<br/><br/>
                        {apiForm.recipient.record.email}
                      </h3>
                    </>
                  ) : (
                    <div style={{marginBottom: 20}}>
                      <Row
                        className="lineamiento subheader"
                        style={{ marginTop: "0px" }}
                      >
                        <Col xl={24}>INTRODUCCIÓN</Col>
                      </Row>
                      <Row className="summary">
                        <Col xl={24}>
                          <p>
                            Yo, <strong>{apiForm.recipient.record.nombre}</strong>, cédula de identidad nacional número <strong>{apiForm.recipient.record.rut}</strong>, 
                            persona trabajadora perteneciente a la Gerencia{" "}
                            {format === "pdf" ?
                              <span style={{overflowWrap:'anywhere'}} className="input-value-field">{basicInformation.gerencia.val}</span>
                              :
                              <span style={{ display: "inline-block", width: "200px" }}>
                                {renderFormItemObj({
                                  itemClassName: "introduction-item",
                                  section: basicInformation.gerencia,
                                  cols: null,
                                })}
                              </span>
                            }
                            , en mi calidad de{" "}
                            {format === "pdf" ?
                              <span style={{overflowWrap:'anywhere'}} className="input-value-field">{basicInformation.cargo.val}</span>
                              :
                              <span style={{ display: "inline-block", width: "200px" }}>
                                {renderFormItemObj({
                                  itemClassName: "introduction-item",
                                  section: basicInformation.cargo,
                                  cols: null,
                                })}
                              </span>
                            }
                            , función que realizó desde{" "}
                            {format === "pdf" ?
                              <span className="input-value-field">{basicInformation.fechaContratacion.val}</span>
                              :
                              <span style={{ display: "inline-block", width: "200px" }}>
                                {renderFormItemObj({
                                  itemClassName: "introduction-item",
                                  section: basicInformation.fechaContratacion,
                                  cols: null,
                                })}
                              </span>
                            }{" "}
                            declaro bajo juramento que toda la información proporcionada en la presente Declaración Jurada, es del todo exacta, 
                            fehaciente y verdadera hasta donde tengo conocimiento y que no he omitido ningún tipo de información que 
                            pudiese ser relevante o pudiese producir un Conflicto de Interés para con <strong>{clientName}</strong> o sus empresas relacionadas, 
                            asumiendo desde ya, las responsabilidades tanto civiles como penales que me corresponderían en caso de falsedad o 
                            inexactitud de esta Declaración Jurada.
                          </p>
                          <p>
                            Asimismo, declaro conocer, aceptar y haber leído, el Código de Ética, el Reglamento Interno de Orden, Higiene y Seguridad, 
                            y el Manual de Prevención del Delito de <strong>{clientName}</strong>, en adelante "La Empresa".
                          </p>
                          <p>
                            También reconozco saber que tengo el deber de informar sobre cualquier situación que pueda dar origen a un potencial conflicto de interés 
                            en el desempeño de mis funciones encomendadas.
                          </p>
                          <p>Por todo lo anterior informo y declaro (marque el casillero correspondiente):</p>
                        </Col>
                      </Row>
                      <Row
                        className="lineamiento subheader"
                        style={{ marginTop: "0px" }}
                      >
                        <Col xl={24}>MALLA PARENTAL</Col>
                      </Row>
                      <Row className="summary">
                        <p>
                          Indique a su cónyuge, conviviente civil y/o parientes hasta el segundo grado de consanguinidad o afinidad inclusive, mayores de 18 años.
                        </p>
                        <p>
                          Es obligatorio ingresar al menos 2 registros.
                        </p>
                      </Row>
                      {format === 'html' &&
                        <Row gutter={[20]}>
                          {renderFormItemTable({
                            section: mallaParObj.parentesco,
                            options: relationshipOptions.slice(1)
                          })}
                          {renderFormItemTable({
                            section: mallaParObj.nombre,
                          })}
                          {renderFormItemTable({
                            section: mallaParObj.tipoDoc,
                            options: tipoDocOptions
                          })}
                          {renderFormItemTable({
                            section: mallaParObj.doc,
                            validator: docValidator(mallaParObj.tipoDoc.val, "persona")
                          })}
                      </Row>
                      }
                      {mallaParental.records.val.length < 2 &&
                        validarRegistros && (
                          <Col
                          span={24}
                          style= {{color: 'red'}}
                          className="missing-registers ant-form-explain"
                          >
                            Se requieren al menos 2 registros. Luego de ingresar un registro, presiona "Añadir".
                          </Col>
                      )}
                      {format === 'html' &&
                        <Row>
                          <Col className={mallaParental.records.val.length < 1 && "addRelation"} xl={3}>
                            <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTableLocal('mallaParObj', 'mallaParental')} icon="plus" > Añadir </Button>
                          </Col>
                          <Col className={mallaParental.records.val.length < 1 && "addRelation"} xl={3}>
                            <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('mallaParObj')}> Limpiar </Button>
                          </Col>
                        </Row>
                      }
                      {mallaParental.records.val.length > 0 && format === "html" ?
                        <Table columns={mallaColumns} dataSource={mallaParental.records.val} size="middle" pagination={false} ></Table>
                        :
                        toDescriptionsPdf( mallaParental.records.val, mallaParObj, apiForm.typeSol )
                      }

                      <Row className="lineamiento subheader">
                        <Col xl={24}>PARTICIPACIÓN EN LA PROPIEDAD DE LA EMPRESA</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                            Declaro que, hasta donde tengo conocimiento, participo, o lo hace mi cónyuge y/o parientes 
                            hasta el tercer grado de consanguinidad o afinidad inclusive, en sociedades, en forma 
                            directa o a través de otras personas naturales o jurídicas, con un 10% o más de su capital, 
                            o bien mi cónyuge y/o parientes hasta el tercer grado de consanguinidad o afinidad inclusive 
                            ocupa el cargo de director, gerente, administrador, ejecutivo principal u otro equivalente 
                            en {clientName}, o alguna de sus empresas relacionadas.
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: participacionEmpresa.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: participacionEmpresa.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              readOnly={format==='pdf'}
                              onChange={({ target }) =>{
                                handlerOnChangeObjAttr(
                                  participacionEmpresa.hasRecords.id,
                                  target.value
                                )
                              }
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>
                      {participacionEmpresa.hasRecords.val && (
                        <>
                        {format === 'html' &&
                          <Row>
                            <Divider orientation='left'>
                              Datos de la persona que posee relación con {clientName}
                            </Divider>
                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: dataPempObj.parentesco,
                                options: relationshipOptions
                              })}
                              {renderFormItemTable({
                                section: dataPempObj.nombre,
                              })}
                              {renderFormItemTable({
                                section: dataPempObj.tipoDoc,
                                options: tipoDocOptions,
                              })}
                              {renderFormItemTable({
                                section: dataPempObj.doc,
                                validator: docValidator(dataPempObj.tipoDoc.val, "persona")
                              })}
                            </Row>
                            <Row gutter={[20]}>
                              {empresasGrupo?.length > 0 && renderFormItemTable({
                                section: dataPempObj.grupo,
                                options: empresasGrupo?.map(empresa => ({ val: empresa, text: empresa }))
                              })}
                            </Row>

                            <Divider orientation='left'>
                              Cargo ocupado por: {dataPempObj.nombre.val}
                            </Divider>
                            <Row className="inner-row" gutter={[16, 8]}>
                              {renderFormItemTable({
                                section: dataPempObj.cargo,
                                options: cargosOptions
                              })}

                              {(dataPempObj.cargo.val === "Accionista" || dataPempObj.cargo.val === "Propietario") &&
                                renderFormItemTable({
                                  section: dataPempObj.tipoPropiedad,
                                  options: [
                                    { val: "Directa", text: "Directa" },
                                    { val: "Indirecta", text: "Indirecta" }
                                  ],
                                })
                              }
                              {(dataPempObj.cargo.val === "Accionista" || dataPempObj.cargo.val === "Propietario") &&
                                renderFormItemTable({
                                  section: dataPempObj.porcentajeParticipacion, // Campo para Porcentaje de Propiedad
                                })
                              }
                              {(dataPempObj.cargo.val === "Accionista" || dataPempObj.cargo.val === "Propietario") && dataPempObj.tipoPropiedad.val === "Indirecta" &&
                                renderFormItemTable({
                                  section: dataPempObj.nombreEmpIndirecta, // Campo para Nombre de Empresa Indirecta
                                })
                              }
                            </Row>

                            <Row className="inner-row" gutter={[16, 8]}>
                              {(dataPempObj.cargo.val === "Accionista" || dataPempObj.cargo.val === "Propietario") && dataPempObj.tipoPropiedad.val === "Indirecta" &&
                                renderFormItemTable({
                                  section: dataPempObj.tipoDocEmpIndirecta, // Campo para Tipo de Documento de Empresa Indirecta
                                  cols: 8,
                                  options: tipoDocOptions
                                })
                              }
                              {(dataPempObj.cargo.val === "Accionista" || dataPempObj.cargo.val === "Propietario") && dataPempObj.tipoPropiedad.val === "Indirecta" &&
                                renderFormItemTable({
                                  section: dataPempObj.docEmpIndirecta, // Campo para Rut de Empresa Indirecta
                                  validator: docValidator(dataPempObj.tipoDocEmpIndirecta.val, "empresa") // Validador para Rut
                                })
                              }
                            </Row>

                          </Row>
                        }
                        {participacionEmpresa.records.val.length < 1 &&
                          validarRegistros && (
                            <Col
                            span={24}
                            style= {{color: 'red'}}
                            className="missing-registers ant-form-explain"
                            >
                            {t("messages.aml.registersRequired")}
                            </Col>
                        )}
                        {format === 'html' &&
                          <Row>
                            <Col className={participacionEmpresa.records.val.length < 1 && "addRelation"} xl={3}>
                              <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTableLocal('dataPempObj', 'participacionEmpresa')} icon="plus" > Añadir </Button>
                            </Col>
                            <Col className={participacionEmpresa.records.val.length < 1 && "addRelation"} xl={3}>
                              <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('dataPempObj')}> Limpiar </Button>
                            </Col>
                          </Row>
                        }
                        
                          {participacionEmpresa.records.val.length > 0 && format === "html" ? (
                            <Table columns={pempColumns} dataSource={participacionEmpresa.records.val} size="middle" pagination={false} ></Table>
                          ) : (
                            toDescriptionsPdf(participacionEmpresa.records.val, dataPempObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      <Row
                        className="lineamiento subheader"
                      >
                        <Col xl={24}>PARTICIPACIÓN EN OTRAS SOCIEDADES</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <div>
                            Declaro que, hasta donde tengo conocimiento, participo, o lo hace mi cónyuge, y/o parientes hasta el tercer grado de consanguinidad o afinidad inclusive, en sociedades, en forma directa o a través de otras personas naturales o jurídicas, con un 10% o más de su capital, o bien mi cónyuge y/o parientes hasta el tercer grado de consanguinidad o afinidad inclusive ocupa el cargo de director, gerente, administrador, ejecutivo principal u otro equivalente en otras Sociedades, tanto en Chile como en el extranjero.
                          </div>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: participacionSociedades.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: participacionSociedades.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(
                                  participacionSociedades.hasRecords.id,
                                  target.value
                                )
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })
                        }
                      </Row>
                      {participacionSociedades.hasRecords.val && (
                      <>
                        {format === 'html' &&
                        <>
                          <Row gutter={[20]}>
                            <Divider orientation='left'>
                              Datos de la empresa en la que posee participación
                            </Divider>
                            {renderFormItemTable({
                              section: dataPsocObj.razonSocial,
                            })}
                            {renderFormItemTable({
                              section: dataPsocObj.tipoDocEmp,
                              options: tipoDocOptions
                            })}
                            {renderFormItemTable({
                              section: dataPsocObj.docEmp,
                              validator: docValidator(dataPsocObj.tipoDocEmp.val, "empresa")
                            })}
                            {renderFormItemTable({
                              section: dataPsocObj.paisEmp,
                              options: paises.map(item => {
                                return {val: item.country, text: item.country}
                              })
                            })}
                          </Row>
                        
                          <Row gutter={[20]}>
                            <Divider orientation='left'>
                              Datos de la persona relacionada a {dataPsocObj.razonSocial.val}
                            </Divider>
                            {renderFormItemTable({
                              section: dataPsocObj.parentesco,
                              options: relationshipOptions
                            })}
                            {renderFormItemTable({
                              section: dataPsocObj.nombre,
                            })}
                            {renderFormItemTable({
                              section: dataPsocObj.tipoDoc,
                              options: tipoDocOptions
                            })}
                            {renderFormItemTable({
                              section: dataPsocObj.doc,
                              validator: docValidator(dataPsocObj.tipoDoc.val, "persona")
                            })}
                          </Row>
                          <Row className="inner-row" gutter={[16, 8]}>
                            {renderFormItemTable({
                              section: dataPsocObj.cargo,
                              options: cargosOptions.map(option =>
                                option.val === "Gerente"
                                  ? { val: "Propietario", text: "Propietario" }
                                  : option
                              )
                            })}

                            {(dataPsocObj.cargo.val === "Accionista" || dataPsocObj.cargo.val === "Propietario") &&
                              renderFormItemTable({
                                section: dataPsocObj.tipoPropiedad, // Campo para Tipo de Propiedad
                                options: [
                                  { val: "Directa", text: "Directa" },
                                  { val: "Indirecta", text: "Indirecta" }
                                ],
                              })
                            }
                            {(dataPsocObj.cargo.val === "Accionista" || dataPsocObj.cargo.val === "Propietario") &&
                              renderFormItemTable({
                                section: dataPsocObj.porcentajeParticipacion, // Campo para Porcentaje de Propiedad
                              })
                            }
                            {(dataPsocObj.cargo.val === "Accionista" || dataPsocObj.cargo.val === "Propietario") && dataPsocObj.tipoPropiedad.val === "Indirecta" &&
                              renderFormItemTable({
                                section: dataPsocObj.nombreEmpIndirecta, // Campo para Nombre de Empresa Indirecta
                              })
                            }
                          </Row>

                          <Row className="inner-row" gutter={[16, 8]}>
                            {(dataPsocObj.cargo.val === "Accionista" || dataPsocObj.cargo.val === "Propietario") && dataPsocObj.tipoPropiedad.val === "Indirecta" &&
                              renderFormItemTable({
                                section: dataPsocObj.tipoDocEmpIndirecta, // Campo para Tipo de Documento de Empresa Indirecta
                                cols: 8,
                                options: tipoDocOptions
                              })
                            }
                            {(dataPsocObj.cargo.val === "Accionista" || dataPsocObj.cargo.val === "Propietario") && dataPsocObj.tipoPropiedad.val === "Indirecta" &&
                              renderFormItemTable({
                                section: dataPsocObj.docEmpIndirecta, // Campo para Rut de Empresa Indirecta
                                validator: docValidator(dataPsocObj.tipoDocEmpIndirecta.val, "empresa") // Validador para Rut
                              })
                            }
                          </Row>

                      
                          {participacionSociedades.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                              span={24}
                              style= {{color: 'red'}}
                              className="missing-registers ant-form-explain"
                              >
                              {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          <Row>
                            <Col className={participacionSociedades.records.val.length < 1 && "addRelation"} xl={3}>
                              <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTableLocal('dataPsocObj', 'participacionSociedades')} icon="plus" > Añadir </Button>
                            </Col>
                            <Col className={participacionSociedades.records.val.length < 1 && "addRelation"} xl={3}>
                              <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('dataPsocObj')}> Limpiar </Button>
                            </Col>
                          </Row>
                        </>
                        }
                        {participacionSociedades.records.val.length > 0 && format === "html" ?
                          <Table columns={psocColumns} dataSource={participacionSociedades.records.val} size="middle" pagination={false} ></Table>
                          :
                          toDescriptionsPdf( participacionSociedades.records.val, dataPsocObj, apiForm.typeSol )
                        }
                      </>
                      )}

                      <Row className="lineamiento subheader">
                        <Col xl={24}>INTERLOCKING</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <p>
                            Interlocking: De acuerdo a lo establecido en el artículo 3 inciso segundo letra d) del DL 211 es: (…) d) La participación simultánea de una persona en cargos ejecutivos relevantes o de director en dos o más empresas competidoras entre sí, siempre que el grupo empresarial al que pertenezca cada una de las referidas empresas tenga ingresos anuales por ventas, servicios y otras actividades del giro que excedan las cien mil unidades de fomento en el último año calendario. Con todo, sólo se materializará esta infracción si transcurridos noventa días corridos, contados desde el término del año calendario en que fue superado el referido umbral, se mantuviere la participación simultánea en tales cargos”.
                          </p>
                          <p>
                            Declaro ejercer o haber ejercido durante el año calendario anterior, cargos de ejecutivo relevante o de director en empresas competidoras de {clientName} y sus filiales. 
                          </p>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: interlocking.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: interlocking.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(interlocking.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {interlocking.hasRecords.val && (
                        <>
                        {format === 'html' &&
                          <Row>
                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: interObj.razonSocial,
                              })}
                              {renderFormItemTable({
                                section: interObj.tipoDoc,
                                options: tipoDocOptions,
                              })}
                              {renderFormItemTable({
                                section: interObj.doc,
                                validator: docValidator(interObj.tipoDoc.val, 'empresa')
                              })}
                              {renderFormItemTable({
                                section: interObj.fechaIngreso,
                              })}
                            </Row>
                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: interObj.fechaTermino,
                              })}
                              {renderFormItemTable({
                                section: interObj.cargo,
                                options: [
                                  { val: "Ejecutivo relevante", text: "Ejecutivo relevante" },
                                  { val: "Director", text: "Director" }
                                ]
                              })}

                            </Row>
                          </Row>
                        }
                        {interlocking.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                              span={24}
                              style= {{color: 'red'}}
                              className="missing-registers ant-form-explain"
                              >
                              {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          {format === 'html' &&
                            <Row>
                              <Col className="addRelation" xl={3}>
                                <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTableLocal('interObj', 'interlocking')} icon="plus" > Añadir </Button>
                              </Col>
                              <Col className="addRelation" xl={3}>
                                <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('interObj')}> Limpiar </Button>
                              </Col>
                            </Row>
                          }
                          {interlocking.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={interlockingColumns} // Define las columnas para la tabla de interlocking
                              dataSource={interlocking.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(interlocking.records.val, interObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      {/* RELACION CON PROVEEDORES */}
                      <Row className="lineamiento subheader">
                        <Col xl={24}>RELACIÓN CON PROVEEDORES, CONTRATISTAS, Y/O CLIENTES</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <div>
                            Declaro que tengo participación, directa o indirectamente, o lo hace mi cónyuge, conviviente civil y/o parientes hasta el segundo grado de consanguinidad o afinidad inclusive, hasta donde tengo conocimiento, en sociedades que sean proveedoras, contratistas o clientes de {clientName}, ya sea como propietario, socio, accionista principal, director, o como ejecutivo principal, gerente, administrador, o con cualquier cargo que suponga capacidad para tomar decisiones y obligar al proveedor, contratista y/o cliente.
                          </div>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: relacionProveedores.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: relacionProveedores.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(relacionProveedores.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {relacionProveedores.hasRecords.val && (
                        <>
                        {format === 'html' &&
                          <Row>
                            <Divider orientation='left'>
                              Datos de la empresa que presta servicio a {clientName}
                            </Divider>
                      
                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: relProvObj.razonSocial,
                              })}

                              {renderFormItemTable({
                                section: relProvObj.tipoDocEmp,
                                options: tipoDocOptions,
                              })}

                              {renderFormItemTable({
                                section: relProvObj.docEmp,
                                validator: docValidator(relProvObj.tipoDocEmp.val, 'empresa')
                              })}

                              {renderFormItemTable({
                                section: relProvObj.tipoEmpresa,
                                options: tipoEmpresaOptions,
                              })}
                            </Row>
                          
                            <Row gutter={[20]}>
                              {empresasGrupo?.length > 0 && renderFormItemTable({
                                section: relProvObj.grupo,
                                options: empresasGrupo?.map(empresa => ({ val: empresa, text: empresa }))
                              })}
                            </Row>
                            
                            <Divider orientation='left'>
                              Datos de la persona relacionada a {relProvObj.razonSocial.val}
                            </Divider>
                            
                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: relProvObj.parentesco,
                                options: relationshipOptions,
                              })}
                              {renderFormItemTable({
                                section: relProvObj.nombre,
                              })}

                              {renderFormItemTable({
                                section: relProvObj.tipoDoc,
                                options: tipoDocOptions,
                              })}

                              {renderFormItemTable({
                                section: relProvObj.doc,
                                validator: docValidator(relProvObj.tipoDoc.val, 'persona')
                                
                              })}
                            </Row>
                            <Row className="inner-row" gutter={[16, 8]}>
                              {renderFormItemTable({
                                section: relProvObj.cargo,
                                options: cargosOptions
                              })}
                              {(relProvObj.cargo.val === "Accionista" || relProvObj.cargo.val === "Propietario") &&
                                renderFormItemTable({
                                  section: relProvObj.tipoPropiedad, // Campo para Tipo de Propiedad
                                  options: [
                                    { val: "Directa", text: "Directa" },
                                    { val: "Indirecta", text: "Indirecta" }
                                  ],
                                })
                              }
                              {(relProvObj.cargo.val === "Accionista" || relProvObj.cargo.val === "Propietario") &&
                                renderFormItemTable({
                                  section: relProvObj.porcentajeParticipacion, // Campo para Porcentaje de Propiedad
                                })
                              }
                              {(relProvObj.cargo.val === "Accionista" || relProvObj.cargo.val === "Propietario") && relProvObj.tipoPropiedad.val === "Indirecta" &&
                                renderFormItemTable({
                                  section: relProvObj.nombreEmpIndirecta, // Campo para Nombre de Empresa Indirecta
                                })
                              }
                            </Row>

                            <Row className="inner-row" gutter={[16, 8]}>
                              {(relProvObj.cargo.val === "Accionista" || relProvObj.cargo.val === "Propietario") && relProvObj.tipoPropiedad.val === "Indirecta" &&
                                renderFormItemTable({
                                  section: relProvObj.tipoDocEmpIndirecta, // Campo para Tipo de Documento de Empresa Indirecta
                                  cols: 8,
                                  options: tipoDocOptions
                                })
                              }
                              {(relProvObj.cargo.val === "Accionista" || relProvObj.cargo.val === "Propietario") && relProvObj.tipoPropiedad.val === "Indirecta" &&
                                renderFormItemTable({
                                  section: relProvObj.docEmpIndirecta, // Campo para Rut de Empresa Indirecta
                                  validator: docValidator(relProvObj.tipoDocEmpIndirecta.val, "empresa") // Validador para Rut
                                })
                              }
                            </Row>

                          </Row>
                          }
                          {relacionProveedores.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                              span={24}
                              style= {{color: 'red'}}
                              className="missing-registers ant-form-explain"
                              >
                              {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          {format === 'html' &&
                            <Row>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('relProvObj', 'relacionProveedores')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('relProvObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          }

                          {relacionProveedores.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={relProvColumns}
                              dataSource={relacionProveedores.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(relacionProveedores.records.val, relProvObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      <Row className="lineamiento subheader">
                        <Col xl={24}>ACTIVIDADES FUERA DE LA EMPRESA Y DEL HORARIO LABORAL</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <div>
                            Declaro que participo o contribuyo en conferencias, seminarios, artículos a revistas e instituciones privadas o públicas, y/o realizo actividades docentes de cualquier tipo, fuera de mi horario laboral, en otros trabajos o actividades laborales que no entren en competencia con el giro de {clientName} o en conflicto con los intereses de ella.
                          </div>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: fueraDeHorario.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: fueraDeHorario.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(fueraDeHorario.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {fueraDeHorario.hasRecords.val && (
                        <>
                          {format === 'html' &&
                            <>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: actFueraObj.razonSocial,
                                })}

                                {renderFormItemTable({
                                  section: actFueraObj.tipoDocEmp,
                                  options: tipoDocOptions,
                                })}

                                {renderFormItemTable({
                                  section: actFueraObj.docEmp,
                                  validator: docValidator(actFueraObj.tipoDocEmp.val, 'empresa')
                                })}

                                {renderFormItemTable({
                                  section: actFueraObj.actividad,
                                })}
                              </Row>
                              {fueraDeHorario.records.val.length < 1 &&
                                validarRegistros && (
                                  <Col
                                  span={24}
                                  style= {{color: 'red'}}
                                  className="missing-registers ant-form-explain"
                                  >
                                  {t("messages.aml.registersRequired")}
                                  </Col>
                              )}
                              <Row>
                                <Col className="addRelation" xl={3}>
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    onClick={() => handleOnAddAttrTableLocal('actFueraObj', 'fueraDeHorario')}
                                    icon="plus"
                                  >
                                    Añadir
                                  </Button>
                                </Col>
                                <Col className="addRelation" xl={3}>
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    icon="delete"
                                    onClick={() => handleOnClear('actFueraObj')}
                                  >
                                    Limpiar
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          }

                          {fueraDeHorario.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={fueraDeHorarioColumns}
                              dataSource={fueraDeHorario.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(fueraDeHorario.records.val, actFueraObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      <Row className="lineamiento subheader">
                        <Col xl={24}>RELACIONES LABORALES ANTERIORES</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <div>
                            Declaro que en los últimos 18 meses he tenido relaciones laborales anteriores, con un empleador distinto a {clientName}
                          </div>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: empleosAnteriores.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: empleosAnteriores.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(empleosAnteriores.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {empleosAnteriores.hasRecords.val && (
                        <>
                        {format === 'html' &&
                          <>
                          <Row>
                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: empleosAntObj.razonSocial,
                              })}

                              {renderFormItemTable({
                                section: empleosAntObj.tipoDocEmp,
                                options: tipoDocOptions,
                              })}

                              {renderFormItemTable({
                                section: empleosAntObj.docEmp,
                                validator: docValidator(empleosAntObj.tipoDocEmp.val,"empresa")
                              })}

                              {renderFormItemTable({
                                section: empleosAntObj.cargo,
                              })}
                            </Row>
                            <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: empleosAntObj.fechaIngreso,
                              })}

                              {renderFormItemTable({
                                section: empleosAntObj.fechaTermino,
                              })}
                            </Row>
                          </Row>
                            {empleosAnteriores.records.val.length < 1 &&
                              validarRegistros && (
                                <Col
                                  span={24}
                                  style= {{color: 'red'}}
                                  className="missing-registers ant-form-explain"
                                >
                                  {t("messages.aml.registersRequired")}
                                </Col>
                            )}
                            <Row>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('empleosAntObj', 'empleosAnteriores')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('empleosAntObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          </>
                        }

                          {empleosAnteriores.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={empleosAnterioresColumns}
                              dataSource={empleosAnteriores.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(empleosAnteriores.records.val, empleosAntObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      {/* RELACION CON LA COMPETENCIA */}
                      <Row className="lineamiento subheader">
                        <Col xl={24}>RELACIÓN CON LA COMPETENCIA</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <div>
                            Declaro que mi cónyuge, conviviente civil o pareja es persona trabajadora de la competencia o que tengo relaciones de parentesco hasta el segundo grado de consanguinidad o afinidad inclusive con personas trabajadoras de la competencia.
                          </div>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: relacionCompetencia.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: relacionCompetencia.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(relacionCompetencia.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {relacionCompetencia.hasRecords.val && (
                        <>
                        {format === 'html' &&
                          <>
                            <Row>
                              <Divider orientation="left">
                                Datos de la empresa competidora de {clientName}
                              </Divider>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataCompObj.razonSocial,
                                })}

                                {renderFormItemTable({
                                  section: dataCompObj.tipoDocEmp,
                                  options: tipoDocOptions,
                                })}

                                {renderFormItemTable({
                                  section: dataCompObj.docEmp,
                                  validator: docValidator(dataCompObj.tipoDocEmp.val, "empresa"),
                                })}
                              </Row>
                              <Divider orientation="left">
                                Datos de la persona relacionada a {dataCompObj.razonSocial.val}
                              </Divider>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataCompObj.parentesco,
                                  options: relationshipOptions.slice(1),
                                })}

                                {renderFormItemTable({
                                  section: dataCompObj.nombre,
                                })}

                                {renderFormItemTable({
                                  section: dataCompObj.tipoDoc,
                                  options: tipoDocOptions,
                                })}

                                {renderFormItemTable({
                                  section: dataCompObj.doc,
                                  validator: docValidator(dataCompObj.tipoDoc.val, "persona")
                                })}
                              </Row>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataCompObj.cargo,
                                })}
                              </Row>
                            </Row>
                            {relacionCompetencia.records.val.length < 1 &&
                              validarRegistros && (
                                <Col
                                  span={24}
                                  style= {{color: 'red'}}
                                  className="missing-registers ant-form-explain"
                                >
                                  {t("messages.aml.registersRequired")}
                                </Col>
                            )}
                            <Row>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('dataCompObj', 'relacionCompetencia')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('dataCompObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          </>
                        }

                          {relacionCompetencia.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={relacionCompetenciaColumns}
                              dataSource={relacionCompetencia.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(relacionCompetencia.records.val, dataCompObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      <Row className="lineamiento subheader">
                        <Col xl={24}>RELACIÓN DE PARENTESCO O PAREJA</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <div>
                            Declaro que mi cónyuge, conviviente civil o pareja es persona trabajadora de {clientName} o sus empresas relacionadas; o que tengo relaciones de parentesco hasta el segundo grado de consanguinidad o afinidad inclusive
                          </div>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: relacionParentesco.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: relacionParentesco.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(relacionParentesco.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {relacionParentesco.hasRecords.val && (
                        <>
                          {format ==='html' && 
                            <Row>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataRelParObj.parentesco,
                                  options: relationshipOptions.slice(1)
                                })}

                                {renderFormItemTable({
                                  section: dataRelParObj.nombre,
                                })}

                                {renderFormItemTable({
                                  section: dataRelParObj.tipoDoc,
                                  options: tipoDocOptions
                                })}

                                {renderFormItemTable({
                                  section: dataRelParObj.doc,
                                  validator: docValidator(dataRelParObj.tipoDoc.val, "persona")
                                })}
                              </Row>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataRelParObj.cargo,
                                })}

                                {empresasGrupo?.length > 0 && renderFormItemTable({
                                  section: dataRelParObj.grupo,
                                  options: empresasGrupo?.map(empresa => ({ val: empresa, text: empresa }))
                                })}
                              </Row>
                            </Row>
                          }
                          {relacionParentesco.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                                span={24}
                                style= {{color: 'red'}}
                                className="missing-registers ant-form-explain"
                              >
                                {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          {format ==='html' && 
                            <Row>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('dataRelParObj', 'relacionParentesco')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('dataRelParObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          }
                        
                          {relacionParentesco.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={relacionParentescoColumns}
                              dataSource={relacionParentesco.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(relacionParentesco.records.val, dataRelParObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      <Row className="lineamiento subheader">
                        <Col xl={24}>FUNCIONARIOS PÚBLICOS O PERSONAS EXPUESTAS POLÍTICAMENTE</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                        <p>
                          Declaro que mi cónyuge, conviviente civil o pareja es funcionario (a) público (a), o que tengo parentesco hasta el segundo grado de consanguinidad o afinidad con funcionarios (as) públicos (as), tanto en la administración central del Estado como en instituciones o empresas fiscales o semifiscales, municipales, autónomas u organismos creados por el Estado o bajo su dependencia.
                        </p>
                        <p>
                          Asimismo, declaro que mi cónyuge, conviviente civil o pareja es Persona Expuesta Políticamente (PEP), o que tengo parentesco hasta el segundo grado de consanguinidad o afinidad con personas que detenten tal calidad.
                        </p>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: funcionariosPublicos.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: funcionariosPublicos.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(funcionariosPublicos.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {funcionariosPublicos.hasRecords.val && (
                        <>
                          {format === 'html' &&
                            <Row>
                              <Divider orientation='left'>
                                Datos del Organismo Estatal
                              </Divider>
                              <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: dataPepObj.categoria,
                                options: [{text: 'Funcionario Público', val: 'Funcionario Público'}, {text: 'PEP', val: 'PEP'}],  // Opciones para categoría de funcionario público
                              })}

                              {renderFormItemTable({
                                section: dataPepObj.razonSocial,
                              })}
                              </Row>
                              <Divider orientation='left'>
                                Datos de la persona relacionada a {dataPepObj.razonSocial.val}
                              </Divider>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataPepObj.parentesco,
                                  options: relationshipOptions.slice(1),  // Opciones para parentesco
                                })}

                                {renderFormItemTable({
                                  section: dataPepObj.nombre,
                                })}

                                {renderFormItemTable({
                                  section: dataPepObj.tipoDoc,
                                  options: tipoDocOptions,  // Opciones para el select de tipo de documento
                                })}

                                {renderFormItemTable({
                                  section: dataPepObj.doc,
                                  validator: docValidator(dataPepObj.tipoDoc.val, "persona")
                                })}
                              </Row>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataPepObj.cargo,
                                })}
                                {renderFormItemTable({
                                  section: dataPepObj.fechaTermino,
                                })}
                              </Row>
                            </Row>
                          }
                          {funcionariosPublicos.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                                span={24}
                                style= {{color: 'red'}}
                                className="missing-registers ant-form-explain"
                              >
                                {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          {format ==='html' && 
                            <Row>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('dataPepObj', 'funcionariosPublicos')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('dataPepObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          }
                        
                          {funcionariosPublicos.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={funcionariosPublicosColumns}
                              dataSource={funcionariosPublicos.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(funcionariosPublicos.records.val, dataPepObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      <Row className="lineamiento subheader">
                        <Col xl={24}>RELACIÓN CON FUNDACIONES, CORPORACIONES, INSTITUCIONES DE BENEFICENCIA Y/O SIN FINES DE LUCRO</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <p>
                            Declaro que tengo participación, directa o indirectamente, o lo hace mi cónyuge, conviviente civil y/o parientes hasta el segundo grado de consanguinidad o afinidad, inclusive, como fundador, director, consejero, gerente, administrador, u otro similar, hasta donde tengo conocimiento, en fundaciones, corporaciones, instituciones de beneficencia y/o sin fines de lucro.
                          </p>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: relacionFundaciones.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: relacionFundaciones.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(relacionFundaciones.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {relacionFundaciones.hasRecords.val && (
                        <>
                          {format === 'html' &&
                            <Row gutter={[20]}>
                              <Divider orientation='left'>
                                Datos de la Institución
                              </Divider>
                              {renderFormItemTable({
                                section: dataRelFundObj.tipoInstitucion,
                                options: tipoFundacionOptions,  // Opciones para el tipo de institución
                              })}

                              {renderFormItemTable({
                                section: dataRelFundObj.nombreInstitucion,
                              })}

                              {renderFormItemTable({
                                section: dataRelFundObj.tipoDocEmp,
                                options: tipoDocOptions,  // Opciones para tipo de documento de la empresa
                              })}

                              {renderFormItemTable({
                                section: dataRelFundObj.docEmp,
                                validator: docValidator(dataRelFundObj.tipoDocEmp.val, "empresa")
                              })}

                              <Divider orientation='left'>
                                Datos de la persona relacionada a {dataRelFundObj.nombreInstitucion.val}
                              </Divider>
                              {renderFormItemTable({
                                section: dataRelFundObj.parentesco,
                                options: relationshipOptions,  // Opciones para parentesco
                              })}

                              {renderFormItemTable({
                                section: dataRelFundObj.nombre,
                              })}

                              {renderFormItemTable({
                                section: dataRelFundObj.tipoDoc,
                                options: tipoDocOptions,  // Opciones para tipo de documento
                              })}

                              {renderFormItemTable({
                                section: dataRelFundObj.doc,
                                validator: docValidator(dataRelFundObj.tipoDoc.val, "persona")
                              })}

                              {renderFormItemTable({
                                section: dataRelFundObj.cargo,
                                options: cargosOptions,  // Opciones para el cargo
                              })}

                              {renderFormItemTable({
                                section: dataRelFundObj.area,
                              })}
                            </Row>
                          }
                          {relacionFundaciones.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                                span={24}
                                style= {{color: 'red'}}
                                className="missing-registers ant-form-explain"
                              >
                                {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          {format ==='html' && 
                            <Row>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('dataRelFundObj', 'relacionFundaciones')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('dataRelFundObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          }
                        
                          {relacionFundaciones.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={relacionFundacionesColumns}
                              dataSource={relacionFundaciones.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(relacionFundaciones.records.val, dataRelFundObj, apiForm.typeSol)
                          )}
                        </>
                      )}

                      <Row className="lineamiento subheader">
                        <Col xl={24}>OTROS CONFLICTOS DE INTERÉS</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={21}>
                          <div>
                            Declaro que tengo conocimiento de algún otro conflicto de interés no abordado en las preguntas anteriores y que pudiera afectar o influir de cualquier forma en los intereses de {clientName}, tales como la existencia de relación laboral, comercial, o de negocios con amigos íntimos, parientes por consanguinidad o afinidad hasta tercer y cuarto grado.
                          </div>
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item",
                          name: otrosConflictos.hasRecords.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 0,
                          initialValue: otrosConflictos.hasRecords.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(otrosConflictos.hasRecords.id, target.value)
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>

                      {otrosConflictos.hasRecords.val && (
                        <>
                          {format === 'html' &&
                            <Row>
                              <Divider orientation='left'>
                                Datos de la empresa que origina el posible Conflicto de Interés
                              </Divider>
                              <Row gutter={[20]}>
                              {renderFormItemTable({
                                section: dataOtrosConObj.razonSocial,
                              })}

                              {renderFormItemTable({
                                section: dataOtrosConObj.tipoDocEmp,
                                options: tipoDocOptions, 
                              })}

                              {renderFormItemTable({
                                section: dataOtrosConObj.docEmp,
                                validator: docValidator(dataOtrosConObj.tipoDocEmp.val, "empresa")
                              })}
                              </Row>
                              <Divider orientation='left'>
                                Datos de la persona que origina el posible Conflicto de Interés
                              </Divider>
                              <Row gutter={[20]}>
                                {renderFormItemTable({
                                  section: dataOtrosConObj.nombre,
                                })}

                                {renderFormItemTable({
                                  section: dataOtrosConObj.tipoDoc,
                                  options: tipoDocOptions,
                                })}

                                {renderFormItemTable({
                                  section: dataOtrosConObj.doc,
                                  validator: docValidator(dataOtrosConObj.tipoDoc.val, "persona")
                                })}
                              </Row>
                              <Divider orientation='left'>
                                Comentarios
                              </Divider>
                              <Row>
                                {renderFormItemTable({
                                  section: dataOtrosConObj.descripcion,
                                  cols: 24
                                })}
                              </Row>
                            </Row>
                          }
                          {otrosConflictos.records.val.length < 1 &&
                            validarRegistros && (
                              <Col
                                span={24}
                                style= {{color: 'red'}}
                                className="missing-registers ant-form-explain"
                              >
                                {t("messages.aml.registersRequired")}
                              </Col>
                          )}
                          {format ==='html' && 
                            <Row>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => handleOnAddAttrTableLocal('dataOtrosConObj', 'otrosConflictos')}
                                  icon="plus"
                                >
                                  Añadir
                                </Button>
                              </Col>
                              <Col className="addRelation" xl={3}>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  icon="delete"
                                  onClick={() => handleOnClear('dataOtrosConObj')}
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            </Row>
                          }
                       
                          {otrosConflictos.records.val.length > 0 && format === "html" ? (
                            <Table
                              columns={otrosConflictosColumns}
                              dataSource={otrosConflictos.records.val}
                              size="middle"
                              pagination={false}
                            />
                          ) : (
                            toDescriptionsPdf(otrosConflictos.records.val, dataOtrosConObj, apiForm.typeSol)
                          )}
                        </>
                      )}
                      
                      <Row className="subheader">
                        USO DE INFORMACIÓN CONFIDENCIAL, RESERVADA Y/O PRIVILEGIADA
                      </Row>
                      <Row className="summary">
                        <Col span={24}>
                          <p>
                            Declaro tener conocimiento de la Ley N° 18.045 en lo relativo a la prohibición de utilizar o revelar en beneficio propio o de terceros, información confidencial, reservada y/o privilegiada.
                          </p>
                          <p>
                            Asimismo, declaro tener conocimiento de que se encuentra prohibido, divulgar, revelar o consentir que otra persona acceda a un secreto comercial de la empresa y que esto constituye un delito contemplado en el artículo 284 bis del Código Penal.
                          </p>
                        </Col>
                      </Row>
                      <Row gutter={[60, 30]} className="summary">
                        <Col span={8}>
                          <p>
                            <strong>Información Confidencial:</strong> es aquella que se refiere a hechos que no presentan el carácter de esenciales y respecto de los cuales no existe obligación de darla a conocer a la Superintendencia y al público en general, o que no reviste la calidad de información privilegiada o reservada, y que sin embargo los directores, gerentes generales y gerentes de la Administradora deciden mantener internamente, bajo sigilo.
                          </p>
                        </Col>
                        <Col span={8}>
                          <p>
                            <strong>Información reservada:</strong> se refiere a ciertos hechos o antecedentes relativos a negociaciones aún pendientes que al conocerse puedan perjudicar el interés social y respecto de las cuales con la aprobación de las tres cuartas partes de los directores en ejercicio, se les dio el carácter de reservados
                          </p>
                        </Col>
                        <Col span={8}>
                          <p>
                            <strong>Información Privilegiada:</strong> es toda información que se refiera a uno o a varios emisores de valores, a sus negocios o a uno o varios valores por ellos emitidos; no divulgada al mercado; y cuyo conocimiento, por su naturaleza, sea capaz de influir en la cotización de valores emitidos
                          </p>
                        </Col>
                      </Row>
                      <Row className="subheader">
                        CONOCIMIENTO DE LA LEY N°20.393 SOBRE “RESPONSABILIDAD PENAL DE LAS PERSONAS JURÍDICAS"
                      </Row>
                      <Row className="summary">
                        <Col span={24}>
                          <p>
                            Declaro estar en conocimiento de que {clientName} ha adoptado un modelo de prevención de delitos de acuerdo a la Ley N°20.393 de “Responsabilidad Penal de las Personas Jurídicas”, y me comprometo a no cometer o participar en la comisión de ninguno de los delitos señalados en la citada Ley u otros que pudiesen incorporarse en el futuro, así como cumplir procesos y controles establecidos para dicho fin.
                          </p>
                          <p>
                            Declaro tener la obligación de comunicar al Encargado de Prevención de Delitos, todo acto o conducta que revista carácter de delito del cual tome conocimiento, mediante el Canal de Denuncias dispuesto por {clientName}.
                          </p>
                        </Col>
                      </Row>
                      <Row className="subheader">
                        <Col xl={24}>
                          DECLARACIÓN DE RESPONSABILIDAD
                        </Col>
                      </Row>
                      <Row className="summary">
                        <p>
                          Certifico que mis respuestas están completas y son correctas a mi mejor saber y entender. Además, acepto que tengo la obligación permanente de comunicar a la brevedad y por escrito a {clientName}, en el evento que se originen cambios a la presente declaración antes de la siguiente presentación, con el objeto de que se proceda a actualizar los antecedentes entregados, y si fuera el caso, también tengo la obligación de inhibirme de tomar decisiones que pudieran verse afectadas por un posible conflicto de interés declarado mientras este no se resuelva.
                        </p>
                        <p>
                          Asimismo, faculto y declaro estar en conocimiento de que {clientName}, por si o a través de terceros, podría eventualmente, y de manera aleatoria o basado en patrones de riesgo, verificar la información proporcionada.
                        </p>
                      </Row>
                    </div>
                  )}
                  {apiForm.status !== "SENT" && format === 'html' &&
                    <Row className="button-row">
                      <Col className="submitTrabajador" xl={24}>
                        <Button type="primary" onClick={handleSubmit} icon='file-protect'>
                          { hasSign ? 'Firmar' : 'Enviar' }
                        </Button>
                      </Col>
                    </Row>
                  }
                </Form>
              </div>
            ) : (
              <h2 style={{ textAlign: "center" }}>Formulario no encontrado</h2>
            )}
          </div>
        )}
      </div>
    </FormLayout>
  );
};

export default withRouter(Form.create()(FormTrab));
