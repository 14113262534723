import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useState, useEffect } from "react";
import axios from 'axios';

export default function PrivateOauth({initAuth, successHandler, authResponse}) {
  const [ user, setUser ] = useState(null);
  const [ profile, setProfile ] = useState(null);

  
  const login = useGoogleLogin({
      //flow: 'auth-code',
      ux_mode: 'redirect',
      redirect_uri: document.location.protocol + "//" + document.location.host + '/google-callback',
      onSuccess: async codeResponse => {
        setUser(codeResponse);
      },
      onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(() => {
    initAuth({logout: googleLogout})
    if (user) {
        axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                setProfile(res.data);
                successHandler(user.access_token, res.data.email)
            })
            .catch((err) => console.log(err));
    }
  }, [ user ]);

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
      googleLogout();
      setProfile(null);
  };

  return (
    <div className="root">
      <div>
        <h1>Log in with Google</h1>
        {profile ? (
            <div>
                <h3>User Logged in</h3>
                <p>Name: {profile.name}</p>
                <p>Email Address: {profile.email}</p>
                <br />
                <br />
                <button onClick={logOut}>Log out</button>
            </div>
        ) : (
            <button onClick={() => login()}>Sign in with Google 🚀 </button>
        )}
      </div>
    </div>
  );
}