import { useAuth } from 'react-oidc-context';
import { useEffect, useState } from "react";
import { Spin } from "antd";

export default function PrivateOauth({initAuth, successHandler, authResponse, children}) {
  const auth = useAuth();
  const isLoading = auth.isLoading
  const isAuthenticated = auth.isAuthenticated
  const user = auth.user?.profile
  const [ email, setEmail ] = useState(null);

  useEffect(() => {
    initAuth({logout: auth.removeUser})
    if(isAuthenticated && user) {
      let email = user.preferred_username
      if(!email) {
        email = user.email
      }
      setEmail(email)
      successHandler(auth.user.id_token, email)
    }
    
  }, [isAuthenticated]);

  return (
    !children ?
      <div style={{padding: 70, textAlign: 'center'}}>
        <div>
          { isLoading && <div><Spin/></div>}
          { !isAuthenticated ?
              <input type="button" value="Ingresar" onClick={auth.signinRedirect} />
            : authResponse?.error &&
            <>
              <h3>{email}</h3>
              <h3>{ authResponse.msg ? authResponse.msg : "Usuario no habilitado" }</h3>
              <input type="button" value="Salir" onClick={auth.removeUser} style={{margin:20}} />
            </>
          }
        </div>
      </div>
    : children
  );
}