import './AlertRules.scss'
import React, {useEffect, useState} from 'react';
import {Row, Col, Icon, Button, Card, Spin, Pagination, Table, Descriptions} from 'antd'
import { useTranslation } from 'react-i18next';
import { camelizerHelper } from '../../../../helpers';
import AnimateHeight from 'react-animate-height';
import moment from 'moment';
import { FalsesPositivesBlock } from '../../../AlertManager/components';
import apiConfig from '../../../../config/api'
import { sanitize } from 'dompurify';
import CurrentUser from '../../../../layouts/Private/components/CurrentUser/CurrentUser';

const AlertRules = ({alert, refreshHandler, origin, setFalsosPositivos, currentUser}) => {
  const { t } = useTranslation();
  const [seeMore, setSeeMore] = useState('auto');
  const [tabla1Data, setTabla1Data] = useState([]);
  const [tabla2Data, setTabla2Data] = useState([]);
  const [tabla2BData, setTabla2BData] = useState([]);
  const [tabla3Data, setTabla3Data] = useState([]);
  const [tabla4Data, setTabla4Data] = useState([]);

  const tabla1Rules = ["RE1101", "RE1102", "RE1103", "RE1104"];
  const tabla2Rules = ["RE1120", "RE1121", "RE1122", "RE1123", "RE1124", "RE1125", "RE1126", "RE1127", "RE1128", "RE1129"];
  const tabla3Rules = ["RE1105", "RE1106", "RE1107", "RE1108"];
  const tabla2BRules = ["RE1140", "RE1141", "RE1142", "RE1143"];
  const tabla4Rules = ["RE1150", "RE1151", "RE1152", "RE1153"];

  useEffect(() => {
      if (alert && alert.riskRecords) {
        const filteredTabla1 = alert.riskRecords.filter(item => tabla1Rules.includes(item.ruleId));
        setTabla1Data(filteredTabla1);

        const filteredTabla2 = alert.riskRecords.filter(item => tabla2Rules.includes(item.ruleId));
        setTabla2Data(filteredTabla2);

        const filteredTabla3 = alert.riskRecords.filter(item => tabla3Rules.includes(item.ruleId));
        setTabla3Data(filteredTabla3);

        const filteredTabla2B = alert.riskRecords.filter(item => tabla2BRules.includes(item.ruleId));
        setTabla2BData(filteredTabla2B);

        const filteredTabla4 = alert.riskRecords.filter(item => tabla4Rules.includes(item.ruleId));
        setTabla4Data(filteredTabla4);
      }
    }, [alert]);
  const updateFP = (fps) => {
    setFalsosPositivos(fps)
  }

  const downloadReportRisk = (pdfFile) => {
    window.open(apiConfig.url + '/ubo/getPDFUboFinder?path=' + pdfFile)
  }

  const quiebrasColumn = [
    {
      title: 'Rut',
      dataIndex: 'rut'
    },
    {
      title: 'Tribunal',
      dataIndex: 'tribunal'
    },
    {
      title: 'Martillero',
      dataIndex: 'martillero'
    },
    {
      title: 'Etapa',
      dataIndex: 'etapa'
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha'
    },
    {
      title: t('messages.aml.risk'),
      dataIndex: 'risk',
      render: (risk) => {
        return(
          <div className={ 'child-risk risk-' + risk }>
            { risk === null && 'N/A'}
            { risk === 'BLACK' && 'C' }
            { risk === 'RED' && 'A' }
            { risk === 'ORANGE' && 'M' }
            { risk === 'YELLOW' && 'B' }
            { risk === 'GREEN' && 'N' }
          </div>
        )
      }
    }
  ]

  const alertRiskDetailColumns = [
    {
      key: 'type',
      title: t('messages.aml.type'),
      dataIndex: "type",
      render: (type) => {
        return type === 'UBO' ? type : t('messages.aml.' + type.toLowerCase())
      }
    },
    {
      key: 'name',
      title: "Nombre",
      dataIndex: "name",
      render: (name) =>{
        return camelizerHelper(name)
      }
    },
    {
      key: 'rut',
      title: "Rut",
      dataIndex: 'rut',
      render: (rut) => {
        return rut !== null ? rut : t('messages.aml.notFound')
      }
    },
    {
      key: 'db',
      title: t('messages.aml.databases'),
      dataIndex: 'basesAML'
    },
    {
      key: 'rulesId',
      title:  t('messages.aml.rules'),
      dataIndex: 'rulesId',
      render: (rulesId) => {
        return rulesId !== null && rulesId.join(', ')
      }
    },
    {
      key: 'risk',
      title: t('messages.aml.risk'),
      dataIndex: 'risk',
      render: (risk) => {
        return(
          <div className={ 'child-risk risk-' + risk }>
            { risk === null && 'N/A'}
            { risk === 'BLACK' && 'C' }
            { risk === 'RED' && 'A' }
            { risk === 'ORANGE' && 'M' }
            { risk === 'YELLOW' && 'B' }
            { risk === 'GREEN' && 'N' }
          </div>
        )
      }
    },
    {
      key: 'downloadBtn',
      title: t('messages.aml.downloadReport'),
      dataIndex: 'path',
      render: (path) => {
        return (
          <div>
            <Button type="primary" icon="file-pdf" size="small" onClick={ (e) => downloadReportRisk(path) }>PDF</Button>
          </div>
        )
      }
    }
  ]

  const tabla1Columns = [
    {
      title: t('messages.aml.category'),
      dataIndex: "categoryRelated",
      render: (categoryRelated) => {
        return camelizerHelper(categoryRelated)
      }
    },
    {
      title: t('messages.aml.type'),
      dataIndex: "relRelated",
      render: (relRelated) => {
        return camelizerHelper(relRelated)
      }
    },
    {
      title: "Participación",
      dataIndex: 'partRelated',
      render: (partRelated) => {
        return partRelated ? (partRelated +"%") : null
      }
    },
    {
      title: t('messages.aml.databases'),
      dataIndex: 'basesRelated'
    },
    {
      title:  t('messages.aml.rules'),
      dataIndex: 'ruleId'
    },
    {
      title: t('messages.aml.risk'),
      dataIndex: 'risk',
      render: (risk) => {
        return(
          <div className={ 'child-risk risk-' + risk }>
            { risk === null && 'N/A'}
            { risk === 'BLACK' && 'C' }
            { risk === 'RED' && 'A' }
            { risk === 'ORANGE' && 'M' }
            { risk === 'YELLOW' && 'B' }
            { risk === 'GREEN' && 'N' }
          </div>
        )
      }
    },
    {
      title: t('messages.aml.downloadReport'),
      dataIndex: 'pdfRelated',
      render: (pdfRelated) => {
          if(pdfRelated){
          return (
            <div>
              <Button type="primary" icon="file-pdf" size="small" onClick={ (e) => downloadReportRisk(pdfRelated) }>PDF</Button>
            </div>
          )
        }
      }
    }
  ]

  const tabla3Columns = [
    {
      title: "Razón Social",
      dataIndex: "nameRelated",
      render: (name) =>{
        return camelizerHelper(name)
      }
    },
    {
      title: "Rut",
      dataIndex: "rutRelated"
    },
    {
      title: t('messages.aml.category'),
      dataIndex: "categoryRelated",
      render: (categoryRelated) => {
        return camelizerHelper(categoryRelated)
      }
    },
    {
      title: t('messages.aml.type'),
      dataIndex: "relRelated",
      render: (relRelated) => {
        return camelizerHelper(relRelated)
      }
    },
    {
      title: "Participación",
      dataIndex: 'partRelated',
      render: (partRelated) => {
        return partRelated ? (partRelated +"%") : null
      }
    },
    {
      title: t('messages.aml.databases'),
      dataIndex: 'basesRelated'
    },
    {
      title:  t('messages.aml.rules'),
      dataIndex: 'ruleId'
    },
    {
      title: t('messages.aml.risk'),
      dataIndex: 'risk',
      render: (risk) => {
        return(
          <div className={ 'child-risk risk-' + risk }>
            { risk === null && 'N/A'}
            { risk === 'BLACK' && 'C' }
            { risk === 'RED' && 'A' }
            { risk === 'ORANGE' && 'M' }
            { risk === 'YELLOW' && 'B' }
            { risk === 'GREEN' && 'N' }
          </div>
        )
      }
    },
    {
      title: t('messages.aml.downloadReport'),
      dataIndex: 'pdfRelated',
      render: (pdfRelated) => {
          if(pdfRelated){
          return (
            <div>
              <Button type="primary" icon="file-pdf" size="small" onClick={ (e) => downloadReportRisk(pdfRelated) }>PDF</Button>
            </div>
          )
        }
      }
    }
  ]

  const tabla2Columns = [
    {
      title: "Nombre",
      dataIndex: "nameRelated",
      render: (name) =>{
        return camelizerHelper(name)
      }
    },
    {
      title: "Rut",
      dataIndex: "rutRelated"
    },
    {
      title: t('messages.aml.category'),
      dataIndex: "categoryRelated",
      render: (categoryRelated) => {
        return camelizerHelper(categoryRelated)
      }
    },
    {
      title: t('messages.aml.relationship'),
      dataIndex: "relRelated",
      render: (relRelated) => {
        return camelizerHelper(relRelated)
      }
    },
    {
      title: t('messages.aml.databases'),
      dataIndex: 'basesRelated'
    },
    {
      title:  t('messages.aml.rules'),
      dataIndex: 'ruleId'
    },
    {
      title: t('messages.aml.risk'),
      dataIndex: 'risk',
      render: (risk) => {
        return(
          <div className={ 'child-risk risk-' + risk }>
            { risk === null && 'N/A'}
            { risk === 'BLACK' && 'C' }
            { risk === 'RED' && 'A' }
            { risk === 'ORANGE' && 'M' }
            { risk === 'YELLOW' && 'B' }
            { risk === 'GREEN' && 'N' }
          </div>
        )
      }
    },
    {
      title: t('messages.aml.downloadReport'),
      dataIndex: 'pdfRelated',
      render: (pdfRelated) => {
          if(pdfRelated){
          return (
            <div>
              <Button type="primary" icon="file-pdf" size="small" onClick={ (e) => downloadReportRisk(pdfRelated) }>PDF</Button>
            </div>
          )
        }
      }
    }
  ]

  const tabla2BColumns = [
    {
      title: "Nombre pariente",
      render: (record) =>{
        if(record?.relations) {
          return camelizerHelper(record.relations[0].name)
        }
      }
    },
    {
      title: "Rut pariente",
      render: (record) =>{
        if(record?.relations) {
          return camelizerHelper(record.relations[0].rut)
        }
      }
    },
    {
      title: "Parentesco",
      render: (record) =>{
        if(record?.relations) {
          return camelizerHelper(record.relations[0].relation)
        }
      }
    },
    {
      title: t('messages.aml.category'),
      dataIndex: "categoryRelated",
      render: (categoryRelated) => {
        return camelizerHelper(categoryRelated)
      }
    },
    {
      title: t('messages.aml.type'),
      dataIndex: "relRelated",
      render: (relRelated) => {
        return camelizerHelper(relRelated)
      }
    },
    {
      title: "Participación",
      dataIndex: 'partRelated',
      render: (partRelated) => {
        return partRelated ? (partRelated +"%") : null
      }
    },
    {
      title: t('messages.aml.databases'),
      dataIndex: 'basesRelated'
    },
    {
      title:  t('messages.aml.rules'),
      dataIndex: 'ruleId'
    },
    {
      title: t('messages.aml.risk'),
      dataIndex: 'risk',
      render: (risk) => {
        return(
          <div className={ 'child-risk risk-' + risk }>
            { risk === null && 'N/A'}
            { risk === 'BLACK' && 'C' }
            { risk === 'RED' && 'A' }
            { risk === 'ORANGE' && 'M' }
            { risk === 'YELLOW' && 'B' }
            { risk === 'GREEN' && 'N' }
          </div>
        )
      }
    },
    {
      title: t('messages.aml.downloadReport'),
      dataIndex: 'pdfRelated',
      render: (pdfRelated) => {
          if(pdfRelated){
          return (
            <div>
              <Button type="primary" icon="file-pdf" size="small" onClick={ (e) => downloadReportRisk(pdfRelated) }>PDF</Button>
            </div>
          )
        }
      }
    }
  ]

  const tabla4Columns = [
    {
      title: "Nombre pariente",
      render: (record) =>{
        if(record?.relations) {
          return camelizerHelper(record.relations[0].name)
        }
      }
    },
    {
      title: "Rut pariente",
      render: (record) =>{
        if(record?.relations) {
          return camelizerHelper(record.relations[0].rut)
        }
      }
    },
    {
      title: "Parentesco",
      render: (record) =>{
        if(record?.relations) {
          return camelizerHelper(record.relations[0].relation)
        }
      }
    },
    {
      title: "Razón Social",
      dataIndex: "nameRelated",
      render: (name) =>{
        return camelizerHelper(name)
      }
    },
    {
      title: "Rut",
      dataIndex: "rutRelated"
    },
    {
      title: t('messages.aml.category'),
      dataIndex: "categoryRelated",
      render: (categoryRelated) => {
        return camelizerHelper(categoryRelated)
      }
    },
    {
      title: t('messages.aml.type'),
      dataIndex: "relRelated",
      render: (relRelated) => {
        return camelizerHelper(relRelated)
      }
    },
    {
      title: "Participación",
      dataIndex: 'partRelated',
      render: (partRelated) => {
        return partRelated ? (partRelated +"%") : null
      }
    },
    {
      title: t('messages.aml.databases'),
      dataIndex: 'basesRelated'
    },
    {
      title:  t('messages.aml.rules'),
      dataIndex: 'ruleId'
    },
    {
      title: t('messages.aml.risk'),
      dataIndex: 'risk',
      render: (risk) => {
        return(
          <div className={ 'child-risk risk-' + risk }>
            { risk === null && 'N/A'}
            { risk === 'BLACK' && 'C' }
            { risk === 'RED' && 'A' }
            { risk === 'ORANGE' && 'M' }
            { risk === 'YELLOW' && 'B' }
            { risk === 'GREEN' && 'N' }
          </div>
        )
      }
    },
    {
      title: t('messages.aml.downloadReport'),
      dataIndex: 'pdfRelated',
      render: (pdfRelated) => {
          if(pdfRelated){
          return (
            <div>
              <Button type="primary" icon="file-pdf" size="small" onClick={ (e) => downloadReportRisk(pdfRelated) }>PDF</Button>
            </div>
          )
        }
      }
    }
  ]

    return (
      <>
      {alert ?
        <div className='alert-rules-section'>
            <div className="title-section">
                <Row>
                    <Col span={19}>
                        <h3>Reglas</h3>
                    </Col>
                    <Col span={5}>
                        <div style={{textAlign: 'right'}}>
                        <Button type="link" onClick={() => {setSeeMore(seeMore === 0 ? 'auto' : 0)}}>
                            {seeMore ?
                                <>Ver menos <Icon type="minus"/></>
                                :
                                <>Ver más <Icon type="plus"/></>
                            }
                        </Button>
                        </div>
                    </Col>
                </Row>
                <hr/>
            </div>
            {/* {seeMore && */}
            <AnimateHeight 
                duration={ 500 } 
                height={ seeMore } 
                animateOpacity={true}
            >
                <div className='subtitle-section'>
                    <Row>
                        <Col span={24}>
                            Reglas de Persona
                        </Col>
                    </Row>
                </div>
                <div className='body-section-wrapper'>
                  <Row justify='start'>
                      {alert !== null &&
                          alert?.rules.map((rule) => {
                              return (
                              <div className="rules-items">
                                  <Row >
                                      <Col span={12}>
                                          <div>
                                              <strong>{ rule.name }</strong>
                                          </div>
                                      </Col>
                                      <Col span={9}>
                                          <div>
                                          { t('messages.aml.code') }: <strong>{ rule.code }</strong>
                                          </div>
                                      </Col>
                                      <Col span={3}>
                                          <div>
                                          { t('messages.aml.score') }: <strong>{ rule.score }</strong>
                                          </div>
                                      </Col>
                                  </Row>
                              </div>
                              )
                          })
                      }
                  </Row>
                </div>
                <div className='subtitle-section'>
                  <Row>
                      <Col span={24}>
                          Detalle
                      </Col>
                  </Row>
                </div>
                {alert?.description?.rules &&
                  <>
                    <div className="rules-cards-wrapper">
                      { alert?.description?.rules.map((rule) =>
                        <Row type="flex" gutter={[8,16]} style={{padding:'0px 18px'}}>
                          <Col span={10}>
                            <Card bodyStyle={{textAlign: "left", verticalAlign:"center"}}>
                                {alert?.rules.map(alertRule => {
                                  if (alertRule?.code === rule.code){
                                    return <div>
                                      <p style={{marginBottom: 5}}>{alertRule?.name}:</p>
                                      <p>{alertRule?.description}</p>
                                    </div>
                                  }
                                })}
                            </Card>
                          </Col>
                            {rule?.params?.fecCierre ?
                              <>
                                <Col span={7} >
                                    <Card title="Evento Actual" headStyle={{backgroundColor: '#0000004d', color: 'white'}}>
                                      <Row className="data">
                                        { rule.params.operacion &&
                                          <>
                                            <Col span={14}>
                                              Operación:
                                            </Col>
                                            <Col span={10}>
                                                {rule.params.operacion}
                                            </Col>
                                          </>
                                        }
                                        { rule.params.monto !== undefined &&
                                          <>
                                            <Col span={14}>
                                              Monto:
                                            </Col>
                                            <Col span={10}>
                                              ${rule.params.monto?.toLocaleString('cl')}
                                            </Col>
                                          </>
                                        }
                                        { rule.params.fechaOperacion &&
                                          <>
                                            <Col span={14}>
                                              Fecha:
                                            </Col>
                                            <Col span={10}>
                                              {moment(rule.params.fechaOperacion).format('DD/MM/YYYY')}
                                            </Col>
                                          </>
                                        }
                                        { rule.params.aportePatrimonial ?
                                          <>
                                            <Col span={14}>
                                              Aporte Patrimonial:
                                            </Col>
                                            <Col span={10}>
                                                ${rule.params.aportePatrimonial?.toLocaleString('cl')}
                                            </Col>
                                          </>
                                          : rule.params.patAdministradoCurrent &&
                                          <>
                                            <Col span={14}>
                                                Patrimonio Administrado:
                                            </Col>
                                            <Col span={10}>
                                                ${rule.params.patAdministradoCurrent?.toLocaleString('cl')}
                                            </Col>
                                          </>
                                        }
                                        <Col span={14}>
                                          { rule.params.aportePatrimonial ? "Fecha Operación:"
                                          : "Fecha de cierre:"
                                          }
                                        </Col>
                                        <Col span={10}>
                                            {moment(rule.params.fecCierre).format('DD/MM/YYYY')}
                                        </Col>
                                        { rule.params.perfil &&
                                          <>
                                            <Col span={14}>
                                              Perfil:
                                            </Col>
                                            <Col span={10}>
                                              {rule.params.perfil == '3' ? 'Riesgo Alto'
                                              : rule.params.perfil == '2' ? 'Riesgo Medio'
                                              : rule.params.perfil == '1' && 'Riesgo Bajo'
                                              }
                                            </Col>
                                          </>
                                        }
                                        {rule.params.pep && rule.params.pep === 'Y' &&
                                          <>
                                            <Col span={14}>
                                              PEP:
                                            </Col>
                                            <Col span={10}>
                                              Sí, ver detalle
                                            </Col>
                                          </>
                                        }
                                        {rule.params.pais &&
                                          <>
                                            <Col span={14}>
                                              País de riesgo:
                                            </Col>
                                            <Col span={10} style={{textTransform: 'capitalize'}}>
                                              {rule.params.pais.toLowerCase()}
                                            </Col>
                                          </>
                                        }
                                        {rule.params.actividad &&
                                          <>
                                            <Col span={14}>
                                              Actividad de riesgo:
                                            </Col>
                                            <Col span={10} style={{textTransform: 'capitalize'}}>
                                              {rule.params.actividad.toLowerCase()}
                                            </Col>
                                          </>
                                        }
                                      </Row>
                                    </Card>
                                </Col>
                                <Col span={7} >
                                    <Card title="Evento Pasado" headStyle={{backgroundColor: '#0000004d', color: 'white'}}>
                                      <Row className="data">
                                        { rule.params.patAdministrar ?
                                          <>
                                              <Col span={14}>
                                                  Patrimonio por Administrar:
                                              </Col>
                                              <Col span={10}>
                                                  ${rule.params.patAdministrar.toLocaleString('cl')}
                                              </Col>
                                          </>
                                        : rule.params.patAdministradoPrevious &&
                                          <>
                                              <Col span={14}>
                                                  Patrimonio Administrado:
                                              </Col>
                                              <Col span={10}>
                                                  ${rule.params.patAdministradoPrevious.toLocaleString('cl')}
                                              </Col>
                                          </>
                                        }
                                        {rule.params.fecPrevious &&
                                          <>
                                            <Col span={14}>
                                                Fecha de cierre:
                                            </Col>
                                            <Col span={10}>
                                              {moment(rule.params.fecPrevious).format('DD/MM/YYYY')}
                                            </Col>
                                          </>
                                        }
                                      </Row>
                                    </Card>
                                </Col>
                              </>
                            : alert.type === "TRANSACCIONAL" ?
                              <Col span={14} >
                                <Card>
                                  <Row gutter={[16]}>
                                    <Col span={12}>
                                      <Row className="data">
                                        <Col span={12}>
                                            ID Operación:
                                        </Col>
                                        <Col span={12}>
                                          {alert.transaction.id ?? '------------'}
                                        </Col>
                                        <Col span={12}>
                                            Producto:
                                        </Col>
                                        <Col span={12} style={{textTransform: 'capitalize'}}>
                                          {alert.transaction.producto ? alert.transaction.producto.toLowerCase() : '------------'}
                                        </Col>
                                        <Col span={12}>
                                            Operación:
                                        </Col>
                                        <Col span={12} style={{textTransform: 'capitalize'}}>
                                          {alert.transaction.operacion ? alert.transaction.operacion.toLowerCase() : '------------'}
                                        </Col>
                                        <Col span={12}>
                                            Monto:
                                        </Col>
                                        <Col span={12}>
                                          {alert.transaction.monto ? alert.transaction.monto.toLocaleString('cl') + ' ' + alert.transaction.codMoneda : '------------'}
                                        </Col>
                                        <Col span={12}>
                                            Saldo:
                                        </Col>
                                        <Col span={12}>
                                          {alert.transaction.saldo ? alert.transaction.saldo.toLocaleString('cl') + ' ' + alert.transaction.codMoneda : '------------'}
                                        </Col>
                                        <Col span={12}>
                                            Fecha Operación:
                                        </Col>
                                        <Col span={12}>
                                          {alert.transaction.fechaOperacion ? moment(alert.transaction.fechaOperacion).format('DD/MM/YYYY') : '------------'}
                                        </Col>
                                        <Col span={12}>
                                            Método de pago:
                                        </Col>
                                        <Col span={12} style={{textTransform: 'capitalize'}}>
                                          {alert.transaction.medioPago ? alert.transaction.medioPago.toLowerCase() : '------------'}
                                        </Col>
                                        <Col span={12}>
                                            Canal:
                                        </Col>
                                        <Col span={12} style={{textTransform: 'capitalize'}}>
                                          {alert.transaction.canal ? alert.transaction.canal.toLowerCase() : '------------'}
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col span={12}>
                                      { rule.params &&
                                        <Row className="data">
                                          {rule.params.nroTransacciones !== undefined &&
                                            <>
                                              <Col span={12}>
                                                  Historia acumulada:
                                              </Col>
                                              <Col span={12}>
                                                {rule.params.nroTransacciones}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.std !== undefined &&
                                            <>
                                              <Col span={12}>
                                                  Desviación estandar:
                                              </Col>
                                              <Col span={12}>
                                                {rule.params.std}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.avg !== undefined &&
                                            <>
                                              <Col span={12}>
                                                  Promedio:
                                              </Col>
                                              <Col span={12}>
                                                {rule.params.avg}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.situacionLaboral &&
                                            <>
                                              <Col span={12}>
                                                  Situación laboral:
                                              </Col>
                                              <Col span={12} style={{textTransform: 'capitalize'}}>
                                                {rule.params.situacionLaboral}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.umbralPagos !== undefined &&
                                            <>
                                              <Col span={12}>
                                                Umbral de Pagos:
                                              </Col>
                                              <Col span={12}>
                                                {rule.params.umbralPagos?.toLocaleString('cl')}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.umbralRetiros !== undefined &&
                                            <>
                                              <Col span={12}>
                                                Umbral de Retiros:
                                              </Col>
                                              <Col span={12}>
                                                {rule.params.umbralRetiros?.toLocaleString('cl')+ ' ' +alert.transaction.codMoneda}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.sueldoPromedio !== undefined &&
                                            <>
                                              <Col span={12}>
                                                Sueldo promedio:
                                              </Col>
                                              <Col span={12}>
                                                {rule.params.sueldoPromedio.toLocaleString('cl')}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.factorSueldo !== undefined &&
                                            <>
                                              <Col span={12}>
                                                Nro. veces sueldo:
                                              </Col>
                                              <Col span={12}>
                                                {rule.params.factorSueldo}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.ventaPromedio !== undefined &&
                                            <>
                                              <Col span={12}>
                                                Ventas promedio:
                                              </Col>
                                              <Col span={12}>
                                                {rule.params.ventaPromedio.toLocaleString('cl')}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.fechaInicioVigencia &&
                                            <>
                                              <Col span={12}>
                                                Inicio vigencia producto:
                                              </Col>
                                              <Col span={12}>
                                                {rule.params.fechaInicioVigencia}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.porcSobreSaldo &&
                                            <>
                                              <Col span={12}>
                                                % sobre el saldo:
                                              </Col>
                                              <Col span={12}>
                                                {rule.params.porcSobreSaldo}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.pais &&
                                            <>
                                              <Col span={12}>
                                                País de Riesgo:
                                              </Col>
                                              <Col span={12} style={{textTransform: 'capitalize'}}>
                                                {rule.params.pais}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.nroCuenta &&
                                            <>
                                              <Col span={12}>
                                                Nro. de Cuenta:
                                              </Col>
                                              <Col span={12}>
                                                {rule.params.nroCuenta}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.fechaCierre &&
                                            <>
                                              <Col span={12}>
                                                Fecha de Cierre:
                                              </Col>
                                              <Col span={12}>
                                                {moment(rule.params.fechaCierre).format('DD/MM/YYYY')}
                                              </Col>
                                            </>
                                          }
                                          {rule.params.bloqueado && rule.params.bloqueado === 'Y' && 
                                            <>
                                              <Col span={12}>
                                                Estado de Cuenta:
                                              </Col>
                                              <Col span={12}>
                                                Bloqueado
                                              </Col>
                                            </>
                                          }
                                          {rule.params.pep && rule.params.pep === 'Y' &&
                                          <>
                                            <Col span={12}>
                                              PEP:
                                            </Col>
                                            <Col span={12}>
                                              Sí, ver detalle
                                            </Col>
                                          </>
                                          }
                                        </Row>
                                      }
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            :
                              <Row>
                                <Col span={11}>
                                  <Card>
                                      País Riesgoso
                                  </Card>
                                </Col>
                                <Col span={13}>
                                  <Card>
                                      Corea del Norte
                                  </Card>
                                </Col>
                              </Row>
                            }
                          </Row>
                      )}
                    </div>
                  </>
                }
                { (alert?.description?.name || alert?.description?.quiebras || alert?.transaction?.msgSwift) &&
                  <Row gutter={[0, 24]}>
                      { alert?.description?.name &&
                        <div style={{padding: 10}}>
                          <Col span={24}>
                            <Descriptions title="Descripción" layout="vertical" size="small" bordered>
                              <Descriptions.Item label="Creado por">
                                { alert?.username ?? "N/A"}
                              </Descriptions.Item>
                              <Descriptions.Item label="Descripción Corta">
                                { alert?.description?.name ?? "N/A"}
                              </Descriptions.Item>
                              { alert?.description?.description &&
                                <Descriptions.Item label="Descripción Larga">
                                  <p dangerouslySetInnerHTML={{__html: sanitize(alert?.description?.description?.replace('\n','<br/>'))}}></p>
                                </Descriptions.Item>
                              }
                            </Descriptions>
                          </Col>
                        </div>
                      }
                      { alert?.description?.quiebras &&
                        <div style={{padding: 10}}>
                          <Table
                            columns={quiebrasColumn}
                            dataSource={alert?.description?.quiebras}
                            size="small"
                            pagination={false}
                          />
                        </div>
                      }
                      { alert?.transaction?.msgSwift &&
                        <div style={{padding: 20}}>
                          <Col span={24}>
                            <Descriptions title={ t('messages.aml.transaction') } layout="vertical" size="small" bordered>
                                <Descriptions.Item label="Mensaje Swift">
                                  { alert?.transaction?.msgSwift ?? "N/A" }
                                </Descriptions.Item>
                                <Descriptions.Item label="Bloque Mensaje">
                                  { alert?.transaction?.blockSwift ?? "N/A" }
                                </Descriptions.Item>
                                <Descriptions.Item label="Line">
                                  { alert?.transaction?.lineSwift ?? "N/A"}
                                </Descriptions.Item>
                            </Descriptions>
                          </Col>
                        </div>
                      }
                  </Row>
                }
                {alert?.risks && alert?.risks.length > 0 &&
                  <div style={{padding: 5}}>
                    <Table
                      columns={alertRiskDetailColumns}
                      dataSource={alert.risks}
                      size="small"
                      pagination={false}
                    />
                  </div>
                }
                {alert?.riskRecords?.length > 0 &&
                <>
                  { tabla1Data?.length > 0 &&
                    <div style={{padding: 5}}>
                      <Table
                        columns={tabla1Columns}
                        dataSource={tabla1Data}
                        size="small"
                        pagination={false}
                        title={() => {
                          return <div className='title-table-section'>
                          <Row>
                              <Col span={24}>
                                {camelizerHelper(alert.record.nombre) + " participa en "+ camelizerHelper(currentUser.cliente.name)}
                              </Col>
                          </Row>
                        </div>
                        }}
                      />
                    </div>
                  }
                  { tabla3Data?.length > 0 &&
                    <div style={{padding: 5}}>
                      <Table
                        columns={tabla3Columns}
                        dataSource={tabla3Data}
                        size="small"
                        pagination={false}
                        title={() => {
                          return <div className='title-table-section'>
                          <Row>
                              <Col span={24}>
                                {camelizerHelper(alert.record.nombre) + " participa en una contraparte de "+ camelizerHelper(currentUser.cliente.name)}
                              </Col>
                          </Row>
                        </div>
                        }}
                      />
                    </div>
                  }
                  { tabla2Data?.length > 0 &&
                    <div style={{padding: 5}}>
                      <Table
                        columns={tabla2Columns}
                        dataSource={tabla2Data}
                        size="small"
                        pagination={false}
                        title={() => {
                          return <div className='title-table-section'>
                          <Row>
                              <Col span={24}>
                                {camelizerHelper(alert.record.nombre) + " tiene relaciones de Parentesco"}
                              </Col>
                          </Row>
                        </div>
                        }}
                      />
                    </div>
                  }
                  { tabla2BData?.length > 0 &&
                    <div style={{padding: 5}}>
                      <Table
                        columns={tabla2BColumns}
                        dataSource={tabla2BData}
                        size="small"
                        pagination={false}
                        title={() => {
                          return <div className='title-table-section'>
                          <Row>
                              <Col span={24}>
                                {camelizerHelper(alert.record.nombre) + " tiene parientes que participan en "+ camelizerHelper(currentUser.cliente.name)}
                              </Col>
                          </Row>
                        </div>
                        }}
                      />
                    </div>
                  }
                  { tabla4Data?.length > 0 &&
                    <div style={{padding: 5}}>
                      <Table
                        columns={tabla4Columns}
                        dataSource={tabla4Data}
                        size="small"
                        pagination={false}
                        title={() => {
                          return <div className='title-table-section'>
                          <Row>
                              <Col span={24}>
                                {camelizerHelper(alert.record.nombre) + " tiene parientes que participan en una contraparte de "+ camelizerHelper(currentUser.cliente.name)}
                              </Col>
                          </Row>
                        </div>
                        }}
                      />
                    </div>
                  }
                </>
                  
                }
                {alert !== null && (alert.estadoFP === 'PENDIENTE' || alert.estadoFP === 'TRATADO') && origin !== "portal" &&
                  <FalsesPositivesBlock alert={alert} refreshHandler={refreshHandler} updateFP={updateFP} view={"new"} />
                }
            </AnimateHeight>
        </div>
        :
        <div style={{textAlign: 'center', padding: 100}}>
          <Spin size="large"/>
        </div>
      }
      </>
    );
};

export default AlertRules;