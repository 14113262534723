import "./Register.scss";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import apiConfig from "../../config/api";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Icon,
  Input,
  List,
  Menu,
  Modal,
  notification,
  Pagination,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
  Upload,
} from "antd";
import {
  Page,
  PageBottomBar,
  PageContent,
  PageFooter,
  PageHeader,
  PageTopBar,
} from "../../layouts/Private/components";
import {
    AdvancedTabs,
    Content,
    ContentTopBar,
    Filters,
    ModalClientCard,
    ModalRequestOpt,
    ModalRisk,
    ModalUbos,
    Sidebar,
} from './components'
import {ReportService} from '../../services'
import {bajaClientesPromise, getClientsPromise, getRiskCLClientesPromise, getFiltersCLClientesPromise, uploadClientsPromise, uploadRelatedPromise, getAreasPromise} from './promises'
import {getParamsPromise} from '../AdminParams/promises'
import {getUsersByClientPromise} from '../../promises'
import uploadIcon from '../Batch/components/ModalAddBatchFile/drag-icon.png'
import excelFileIcon from './excel-file.png'
import ga from './ga'
import moment from 'moment'
import {globalContext} from '../../contexts'
import personIcon from '../Query/components/PersonCard/person-icon.png'
import entityIcon from '../Query/components/PersonCard/entity-icon.png'
import ImageResources from "../../layouts/commonComponent/components/ExternalResources/ImageResources";

const { Dragger } = Upload;

class Register extends Component {
  static contextType = globalContext;
  state = {
    breadcrumbs: this.getBreadcrumbs(),
    clients: [],
    currentPage: 1,
    filters: {},
    resultsPerPage: 10,
    isLoading: true,
    resultsTotalNum: -1,
    colors: {
      total: 0,
      black: 0,
      red: 0,
      orange: 0,
      yellow: 0,
      green: 0,
      na: 0,
    },
    colorsCake: { total: 0 },
    firstLoading: true,
    isModalRiskVisible: false,
    isModalUbosVisible: false,
    isModalUploadVisible: false,
    isModalUploadInactiveVisible: false,
    isModalReportVisible: false,
    selectedRecord: {},
    uploading: false,
    uploaded: false,
    fileList: [],
    parametersClient: null,
    formularioInterno: true,
    processUF: false,
    resultsUpload: [],
    userAsig: null,
    users: [],
    filterFecAlerta: null,
    grupo: null,
    category: "CLIENTE",
    subclienteId: null,
    filterFalsosPositivos: null,
    errorsUpload: null,
    selectedItems: [],
    chkAll: false,
    moreStyle: false,
    isAdvancedSearchVisible: false,
    rut: null,
    recordsAction: [],
    filterCurrentUser: null,
    totalCurrentUser: 0,
    isSendAll: false,
    actionType: null,
    itemTable: null,
    areas: null,
    areasList: []
  };

  localGenerateReport(type) {
    const { filters } = this.state;
    const { generateReport } = this.context;
    return generateReport(type, filters, this.finishReport.bind(this));
  }

  async componentDidMount() {
    const { currentUser, match } = this.props;

    let { filters } = this.state;
    if (match.params && match.params.params) {
      try {
        const p = JSON.parse(atob(match.params.params));
        filters = { ...filters, ...p };
      } catch (e) {
        console.error("Error al decodificar parametros:", e);
      }
    } else {
      const query = new URLSearchParams(this.props.location.search);
      const filterFecAlerta = query.get("fa");

      filters["estados"] = ["ACTIVE"];
      filters["filterFecAlerta"] = filterFecAlerta;
      filters["fecAlerta"] = filterFecAlerta;
    }

    const parametersClient = await getParamsPromise();
    const users = await getUsersByClientPromise();
    this.setState({
      filters,
      userAsig: currentUser.id,
      users,
      parametersClient,
    });
    if (
      currentUser.cliente.outsourcer &&
      currentUser.cliente.clientes !== null &&
      currentUser.cliente.clientes.length > 0 &&
      currentUser.subcliente !== null
    ) {
      this.setState({ subclienteId: currentUser.subcliente.id });
    }

    this.handleApplyFilters();

    getAreasPromise().then(areas => {
      this.setState({ areasList: areas });
    })
  }

  async handleApplyFilters() {
    this.setState({ firstLoading: true });
    const { filters } = this.state;

    const oldColors = JSON.parse(JSON.stringify(this.state.colors));
    getRiskCLClientesPromise(filters).then(risk => {
      const colors = this.renderColors(risk);
      let critical = 0;
      let high = 0;
      let medium = 0;
      let low = 0;
      let n = 0;
      let na = 0;

      if (
        filters["risk"] === null ||
        filters["risk"] === undefined ||
        filters["risk"].length === 0 ||
        filters["risk"].includes("BLACK")
      ) {
        critical = colors.black;
      }
      if (
        filters["risk"] === null ||
        filters["risk"] === undefined ||
        filters["risk"].length === 0 ||
        filters["risk"].includes("RED")
      ) {
        high = colors.red;
      }
      if (
        filters["risk"] === null ||
        filters["risk"] === undefined ||
        filters["risk"].length === 0 ||
        filters["risk"].includes("ORANGE")
      ) {
        medium = colors.orange;
      }
      if (
        filters["risk"] === null ||
        filters["risk"] === undefined ||
        filters["risk"].length === 0 ||
        filters["risk"].includes("YELLOW")
      ) {
        low = colors.yellow;
      }
      if (
        filters["risk"] === null ||
        filters["risk"] === undefined ||
        filters["risk"].length === 0 ||
        filters["risk"].includes("GREEN")
      ) {
        n = colors.green;
      }
      if (
        filters["risk"] === null ||
        filters["risk"] === undefined ||
        filters["risk"].length === 0 ||
        filters["risk"].includes("NA")
      ) {
        na = colors.na;
      }
      let total = critical + high + medium + low + n + na;
      let colorsCake = { total, critical, high, medium, low, n, na };

      this.setState({ colors, colorsCake, firstLoading: false });

      this.handleAnimateNumbers(
        "animated-number-black",
        oldColors.black,
        colors.black,
        650
      );
      this.handleAnimateNumbers(
        "animated-number-red",
        oldColors.red,
        colors.red,
        650
      );
      this.handleAnimateNumbers(
        "animated-number-yellow",
        oldColors.yellow,
        colors.yellow,
        650
      );
      this.handleAnimateNumbers(
        "animated-number-orange",
        oldColors.orange,
        colors.orange,
        650
      );
      this.handleAnimateNumbers(
        "animated-number-green",
        oldColors.green,
        colors.green,
        650
      );
      this.handleAnimateNumbers(
        "animated-number-grey",
        oldColors.na,
        colors.na,
        650
      );
    })

    getFiltersCLClientesPromise(filters).then(filter => {
      filters["filterFalsosPositivos"] = filter.pendingReview;
      this.setState({
        filters,
        groups: filter.groups,
        categories: filter.categories,
        areas: filter.areas,
      });
  
    })

    this.handlePaginationChange(1);
  }

  async handlePaginationChange(currentPage) {
    const { resultsPerPage } = this.state;
    const fromNum = (currentPage - 1) * resultsPerPage;
    this.setState({ isLoading: true, fromNum });

    const { filters, chkAll, isSendAll } = this.state;

    const clients = await getClientsPromise(fromNum, resultsPerPage, filters);

    this.setState({
      isLoading: false,
      clients: clients.records,
      currentPage: currentPage,
      resultsTotalNum: clients.total,
      totalCurrentUser: clients.total,
    });

    if(chkAll && !isSendAll) {
      this.setState({
        chkAll: false
      })
    }
  }

  async handleChangeFilters(
    filterName,
    filterOption,
    filterValue,
    search = true
  ) {
    const { filters } = this.state;
    const { currentUser } = this.props;

    if (
      filterName === "text" ||
      filterName === "fromDate" ||
      filterName === "toDate" ||
      filterName === "fecAlerta" ||
      filterName === "falsosPositivos" ||
      filterName === "falsosPositivosPendientes" ||
      filterName === "subclienteId"
    ) {
      if (filterValue) {
        filters[filterName] = filterOption;
      } else {
        filters[filterName] = null;
      }
    } else {
      let filterValues = filters[filterName];
      if (filterValues === null || filterValues === undefined)
        filterValues = [];

      if (filterValue) {
        if (!filterValues.includes(filterOption)) {
          filterValues.push(filterOption);
        }
      } else if (filterValues.includes(filterOption)) {
        let index = filterValues.indexOf(filterOption);

        filterValues.splice(index, 1);
      }
      filters[filterName] = filterValues;
    }

    await this.setState({ filters });

    if (search) {
      await this.handleApplyFilters();

      if (
        currentUser.cliente.abreviado !== "demostraciones" &&
        currentUser.cliente.abreviado !== "demo"
      ) {
        await ga.saveRiskChanges(filterName, filterOption, filterValue);
        await ga.saveLeftFiltersChanges(filterName, filterOption, filterValue);
      }
    }
  }

  getBreadcrumbs() {
    const { t } = this.props;

    const breadcrumbs = [
      {
        title: t("messages.aml.register"),
        icon: "user-add",
        link: "/registro",
      },
    ];

    return breadcrumbs;
  }

  renderColors(filters) {
    const colors = {
      black: 0,
      red: 0,
      yellow: 0,
      orange: 0,
      green: 0,
      na: 0,
      total: 0,
    };

    if (filters.BLACK) {
      colors.black = filters.BLACK;
    }

    if (filters.RED) {
      colors.red = filters.RED;
    }

    if (filters.ORANGE) {
      colors.orange = filters.ORANGE;
    }

    if (filters.YELLOW) {
      colors.yellow = filters.YELLOW;
    }

    if (filters.GREEN) {
      colors.green = filters.GREEN;
    }

    if (filters.NA) {
      colors.na = filters.NA;
    }
    colors.total =
      colors.black +
      colors.red +
      colors.orange +
      colors.yellow +
      colors.green +
      colors.na;
    return colors;
  }

  handleNewProfile = () => {
    this.setState({ itemTable: {} });
  };

  handleOnCancelModalUbos() {
    this.setState({ isModalUbosVisible: false });
    //const { currentPage } = this.state
    //this.handlePaginationChange(currentPage)
  }

  handleViewUbos = (record) => {
    this.setState({ isModalUbosVisible: true, selectedRecord: record });
  };

  handleOnCancelModalRisk() {
    this.setState({ isModalRiskVisible: false });
  }

  handleModalRisk(record) {
    this.setState({ isModalRiskVisible: true, selectedRecord: record });
  }

  handleUploadBatch = () => {
    this.setState({ isModalUploadVisible: true });
  };

  handleUploadBatchInactive = () => {
    this.setState({ isModalUploadInactiveVisible: true });
  };

  handleUploadRelated = () => {
    this.setState({ isModalUploadRelatedVisible: true });
  };

  handleOnCancelModalUploadRelated() {
    this.setState({
      isModalUploadRelatedVisible: false,
      uploading: false,
      uploaded: false,
    });
  }

  handleOnCancelModalUpload() {
    this.setState({
      isModalUploadVisible: false,
      uploading: false,
      uploaded: false,
    });
  }

  handleOnCancelModalUploadInactive() {
    this.setState({
      isModalUploadInactiveVisible: false,
      uploading: false,
      uploaded: false,
    });
  }

  handleSelectAll = () => {
    if(this.state.isSendAll) {
      this.handleSelectDeselectAll(false)
    }
    this.setState((prevState) => ({
      isSendAll: !prevState.isSendAll,
    }));
  };

  handleUploadClient = () => {
    const {
      fileList,
      userAsig,
      subclienteId,
      category,
      grupo,
      parametersClient,
    } = this.state;
    const { t } = this.props;

    if (userAsig === null) {
      notification.error({
        message: "Error",
        description: t("messages.aml.youMustSelectAUser"),
      });
      return;
    }
    if (category === null) {
      notification.error({
        message: "Error",
        description: t("messages.aml.youMustSelectACategory"),
      });
      return;
    }

    if (parametersClient.gruposNames && parametersClient.gruposNames.length > 1 && grupo === null) {
      notification.error({
        message: "Error",
        description: t("messages.aml.youMustSelectAGroup"),
      });
      return;
    }
    if (fileList.length === 0) {
      notification.error({
        message: "Error",
        description: t("messages.aml.youMustSelectAFile"),
      });
      return;
    }
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("userAsigned", userAsig);
    formData.append("category", category);
    if (grupo !== null) {
      formData.append("grupo", grupo);
    }
    if (subclienteId !== null) {
      formData.append("subclienteId", subclienteId);
    }
    formData.append("processUF", this.state.processUF);
    formData.append("formularioInterno", this.state.formularioInterno);

    this.setState({
      uploading: true,
    });

    uploadClientsPromise(formData).then((results) => {
      if (results.status === "OK") {
        let resultsUpload = [];
        for (let key in results.result) {
          let line = "";
          if (key === "OK") line = t("messages.aml.loadedEntities") + ": ";
          else if (key === "UPD") line = 'Registros modificados' + ": ";
          else if (key === "E01") line = t("messages.aml.entitiesExists") + ": ";
          else line = t("messages.aml.recordsWithErrors") + ": ";
          line += results.result[key];

          resultsUpload.push(line);
        }

        this.setState({
          resultsUpload,
          uploading: false,
          uploaded: true,
          fileList: [],
          errorsUpload: results.errors,
        });
        const { currentPage } = this.state;
        this.handlePaginationChange(currentPage);
      } else {
        notification.error({
          message: "Error",
          description: results.message,
        });
      }
    });
  };

  handleUploadRelatedFile = () => {
    const {
      fileList,
    } = this.state;
    const { t } = this.props;

    if (fileList.length === 0) {
      notification.error({
        message: "Error",
        description: t("messages.aml.youMustSelectAFile"),
      });
      return;
    }
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file);
    });

    this.setState({
      uploading: true,
    });

    uploadRelatedPromise(formData).then((results) => {
      if (results.status === "OK") {
        let resultsUpload = [];
        for (let key in results.result) {
          let line = "";
          if (key === "OK") line = t("messages.aml.updatedRegisters") + ": ";
          else if (key === "E01")
            line = t("messages.aml.entitiesDontExists") + ":";
          else line = t("messages.aml.recordsWithErrors");
          line += results.result[key];
          resultsUpload.push(line);
        }
        this.setState({
          resultsUpload,
          uploading: false,
          uploaded: true,
          fileList: [],
          errorsUpload: results.errors,
        });
        const { currentPage } = this.state;
        this.handlePaginationChange(currentPage);
      } else {
        this.setState({
          uploading: false,
          uploaded: false,
          errorsUpload: results.errors,
        });
        notification.error({
          message: "Error",
          description: results.message,
        });
      }
    });
  };

  handleUploadClientInactive = () => {
    const {
      fileList,
    } = this.state;
    const { t } = this.props;

    if (fileList.length === 0) {
      notification.error({
        message: "Error",
        description: t("messages.aml.youMustSelectAFile"),
      });
      return;
    }
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file);
    });

    this.setState({
      uploading: true,
    });

    bajaClientesPromise(formData).then((results) => {
      if (results.status === "OK") {
        let resultsUpload = [];
        for (let key in results.result) {
          let line = "";
          if (key === "OK") line = t("messages.aml.inactiveEntities") + ": ";
          else if (key === "E01")
            line = t("messages.aml.entitiesDontExists") + ": ";
          else line = t("messages.aml.recordsWithErrors");
          line += results.result[key];

          resultsUpload.push(line);
        }

        this.setState({
          resultsUpload,
          uploading: false,
          uploaded: true,
          fileList: [],
          errorsUpload: results.errors,
        });
        const { currentPage } = this.state;
        this.handlePaginationChange(currentPage);
      } else {
        this.setState({
          uploading: false,
          uploaded: false,
          errorsUpload: results.errors,
        });
        notification.error({
          message: "Error",
          description: results.message,
        });
      }
    });
  };

  handleOnChangeSendMail = (sendMail) => {
    this.setState({ formularioInterno: !sendMail });
  };

  handleOnChangeUF = (processUF) => {
    this.setState({ processUF });
    if (!processUF) {
      this.setState({ formularioInterno: true });
    }
  };

  handleOnChangeGrupo = (grupo) => {
    this.setState({ grupo: grupo });
  };

  getUsersAsig(users) {
    const { currentUser } = this.props;
    let _users = [];
    for (let i = 0; i < users.length; i++) {
      if (
        (users[i].type === "ADMIN" ||
          users[i].type === "SADMIN" ||
          users[i].type === "USUARIO") &&
        ((currentUser.cliente.oficialCto !== null &&
          currentUser.cliente.oficialCto.id === users[i].id) ||
          (users[i].modules !== null && users[i].modules.includes("REGISTRO")))
      ) {
        _users.push(
          <Select.Option value={users[i].id}>{users[i].name}</Select.Option>
        );
      }
    }
    return _users;
  }

  handleReport() {
    this.setState({ isModalReportVisible: true });
  }

  handleOnCancelModalReport() {
    this.setState({ isModalReportVisible: false });
  }

  async handleExcelReport() {
    this.setState({ isLoadingExport: true });
    this.localGenerateReport("RECORDS").then((r) => {
      if (r) this.setState({ isLoadingExport: true });
    });
  }

  async handleRiskReport() {
    this.localGenerateReport("RISK").then((r) => {
      if (r) this.setState({ isLoadingRisk: true });
    });
  }

  finishReport() {
    this.setState({
      isLoadingExport: false,
      isLoadingMp: false,
      isLoadingBfReport: false,
      isLoadingRisk: false,
      isLoadingCertificates: false,
      isLoadingFormReport: false,
    });
  }

  async handlePDFReport() {
    this.localGenerateReport("PDFS").then((r) => {
      if (r) this.setState({ isLoadingCertificates: true });
    });
  }

  async handleBFReport() {
    this.localGenerateReport("UBOS").then((r) => {
      if (r) this.setState({ isLoadingBfReport: true });
    });
  }

  async handleMallasPropiedadReport() {
    this.localGenerateReport("MALLAS").then((r) => {
      if (r) this.setState({ isLoadingMp: true });
    });
  }

  async handleFormsReport() {
    this.localGenerateReport("FORMS").then((r) => {
      if (r) this.setState({ isLoadingFormReport: true });
    });
  }

  handleAnimateNumbers(id, start, end, duration) {
    // assumes integer values for start and end
    var obj = document.getElementById(id);
    var range = end - start;
    // no timer shorter than 50ms (not really visible any way)
    var minTimer = 50;
    // calc step time to show all interediate values
    var stepTime = Math.abs(Math.floor(duration / range));

    // never go below minTimer
    stepTime = Math.max(stepTime, minTimer);

    // get current time and calculate desired end time
    var startTime = new Date().getTime();
    var endTime = startTime + duration;
    var timer;

    function run() {
      if (obj) {
        var now = new Date().getTime();
        var remaining = Math.max((endTime - now) / duration, 0);
        var value = Math.round(end - remaining * range);
        obj.innerHTML = value;
        if (value === end) {
          clearInterval(timer);
        }
      }

      timer = setInterval(run, stepTime);
      run();
    }
  }

  handleOnChangeAsignedUser(user) {
    this.setState({ userAsig: user });
  }

  handleOnChangeCategory(category) {
    this.setState({ category });
  }

  handleOnChangeSubcliente(subclienteId) {
    this.setState({ subclienteId });
  }

  downloadPlantilla(type) {
    if (type === "records") {
      window.open(apiConfig.url + "/../templates/carga_registro.xlsx");
    } else if (type === "related") {
      window.open(
        apiConfig.url + "/../templates/carga_registro_relacionados.xlsx"
      );
    }
  }

  downloadErrors() {
    const { errorsUpload } = this.state;
    ReportService.read(
      "/inputFile",
      { path: "records/" + errorsUpload },
      null,
      "reporte.xlsx"
    );
  }

  handleSelectDeselectAll(checked) {
    const { filters, clients, resultsTotalNum } = this.state;
    this.setState({ chkAll: checked });
    if (!checked) {
      this.setState({
        selectedItems: [],
        filterCurrentUser: null,
        totalCurrentUser: 0,
        isSendAll: false,
      });
    } else {
      let itemsPerPage = [];
      clients.map((i) => itemsPerPage.push({ id: i.id, name: i.nombre, category: i.category })

      );
      this.setState({
        selectedItems: itemsPerPage,
        filterCurrentUser: filters,
        totalCurrentUser: resultsTotalNum,
        isSendAll: false,
      });
    }
  };

  render() {
    const amountOfRegister = 2;
    const { t, currentUser } = this.props;
    const {
      breadcrumbs,
      clients,
      currentPage,
      firstLoading,
      resultsPerPage,
      resultsTotalNum,
      colors,
      colorsCake,
      isLoading,
      filters,
      groups,
      areas,
      areasList,
      categories,
      isModalRiskVisible,
      selectedRecord,
      isModalUbosVisible,
      isModalUploadVisible,
      isModalUploadInactiveVisible,
      isModalUploadRelatedVisible,
      fileList,
      processUF,
      formularioInterno,
      resultsUpload,
      uploaded,
      uploading,
      userAsig,
      users,
      parametersClient,
      isModalReportVisible,
      errorsUpload,
      isLoadingExport,
      isLoadingRisk,
      isLoadingCertificates,
      isLoadingBfReport,
      isLoadingMp,
      isLoadingFormReport,
      selectedItems,
      chkAll,
      isAdvancedSearchVisible,
      recordsAction,
      filterCurrentUser,
      totalCurrentUser,
      isSendAll,
      actionType,
      itemTable,
    } = this.state;

    const propsUpload = {
      accept: ".xlsx",
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        if (this.state.fileList.length === 0) {
          this.setState((state) => ({
            fileList: [...state.fileList, file],
          }));
        }

        return false;
      },
      multiple: false,
      fileList,
    };

    const actionTypeRender = {
      cronForm: [
        <Icon type="clock-circle" />,
        " ",
        "Actualizar periódicamente la ficha",
      ],
      cronCdiForm: [
        <Icon type="clock-circle" />,
        " ",
        "Actualizar periódicamente la declaración",
      ],
      newRequest: [
        <Icon type="mail" />,
        " ",
        " Enviar Formulario de Onboarding",
      ],
      newCdiRequest: [<Icon type="mail" />, " ", " Enviar Declaracion"],
      brokeRegister: [
        <Icon type="clock-circle" />,
        " ",
        " Activar registro de quiebras",
      ],
      uboFinder: [<Icon type="clock-circle" />, " ", " Investigar Ubos"],
      beneficiariesMatchs: [<Icon type="search" />, " ", t("messages.aml.findFinalBeneficiariesMatchs")],
      form57: [
        <Icon type="clock-circle" />,
        " ",
        t("messages.aml.solicitud.formc57"),
      ],
    };

    const renderType = (type) => {
      if (type === "Person") {
        return personIcon;
      } else if (type === "Entity") {
        return entityIcon;
      }
    };

    const cbFilters = (objFilters) => {
      const { filters } = this.state;
      filters.advanced = objFilters;
      this.setState({ filters });
      this.handleApplyFilters();
    };

    const hasServices = (record) => {
      return (
        record.status === "ACTIVE" &&
        (currentUser.cliente.modules.includes("ONBOARDING") ||
          (currentUser.cliente.modules.includes("QUIEBRA") &&
            !record.hasQuiebras) ||
          (record.type === "Entity" &&
            currentUser.cliente.modules.includes("ADMCLI") &&
            (currentUser.cliente.modules.includes("REG-ENTITY") ||
              (!currentUser.cliente.modules.includes("REG-ENTITY") &&
                !currentUser.cliente.modules.includes("REG-PERSON"))) &&
            parametersClient && parametersClient.ubofinder &&
            ((!parametersClient.ubofinder.noInvestigaMalla && !record.processUF) ||
              (!parametersClient.ubofinder.sinFormulario 
                  && (!parametersClient.ubofinder.formularioInterno || !record.formularioInterno)
              )
            )) ||
          (currentUser.cliente.modules.includes("CDI-FORM") &&
            ((currentUser.cliente.modules.includes("CDI-FORM-CDI") &&
              currentUser.modules.includes("CDI-FORM-GD-P") &&
              record.category === "PROVEEDOR") ||
              ((currentUser.cliente.modules.includes("CDI-FORM-CDI") ||
                currentUser.cliente.modules.includes("CDI-FORM-REL")) &&
                (currentUser.modules.includes("CDI-FORM-GD-T") ||
                  currentUser.modules.includes("CDI-FORM-GD")) &&
                record.category === "COLABORADOR") ||
              (currentUser.cliente.modules.includes("CDI-FORM-DIR") &&
                currentUser.modules.includes("CDI-FORM-GDIR") &&
                record.category === "DIRECTOR") ||
              (currentUser.cliente.modules.includes("CDI-FORM-PATR") &&
                currentUser.modules.includes("CDI-FORM-GPATR") &&
                record.category === "CLIENTE"))))
      );
    };
    const getTableColumns = () => {
      const { currentUser } = this.props;
      let columns = [
        {
          title: () => (
            <div>
              <Checkbox onChange={(e) => this.handleSelectDeselectAll(e.target.checked)} checked={chkAll} />
            </div>
          ),
          width: "5%",
          align: "center",
          render: (record) => {
            return (
              hasServices(record) && (
                <Checkbox
                  checked={
                    selectedItems.filter((i) => i.id === record.id).length > 0 ||
                    chkAll
                  }
                  onChange={(e) => {
                    this.setState({ chkAll: false });
                    let checkedItem = [...selectedItems];
                    if (e.target.checked) {
                      checkedItem.push({
                        id: record.id,
                        name: record.nombre,
                        category: record.category,
                        type: record.type
                      });
                    } else {
                      checkedItem = checkedItem.filter(
                        (i) => i.id !== record.id
                      );
                    }
                    this.setState({
                      selectedItems: checkedItem,
                      totalCurrentUser: checkedItem.length,
                    });
                  }}
                />
              )
            );
          },
        },
        {
          title: "Tipo",
          className: "text-center-type-onb",
          width: "5%",
          render: (item) => {
            return (
              <Row>
                <Col span={24} style={{ textAlign: "center", fontSize: "5px" }}>
                  <img src={renderType(item.type)} alt="" width="40" />
                </Col>
              </Row>
            );
          },
          // sorter: (a, b) => a.creationDate - b.creationDate,
        },
        {
          title: "Nombre/Rut",
          width: "26%",
          render: (record) => {
            return (
              <>
                <div className="name-col-table">
                  <Button
                    type="link"
                    onClick={() => this.setState({ itemTable: record })}
                  >
                    {record.nombre}
                  </Button>
                  <Button
                    type="link"
                    onClick={() => this.setState({ itemTable: record })}
                  >
                    {record.rut}
                  </Button>
                </div>
              </>
            );
          },
        },
        {
          title: (
            <>
              <div>Actualización Registro</div>
              {currentUser.cliente.modules.includes("ONBOARDING") && (
                <div>OnBoarding</div>
              )}
            </>
          ),
          width: "10%",
          align: "center",
          dataIndex: "fecLastUpdate",
          render: (text, record) => (
            <>
              <div>{moment(text).format("DD/MM/YYYY")}</div>
              {currentUser.cliente.modules.includes("ONBOARDING") &&
                record.ficha && (
                  <div>
                    {record.ficha.declDate
                      ? moment(record.ficha.declDate).format("DD/MM/YYYY")
                      : "Sin actualizar"}
                  </div>
                )}
            </>
          ),
        },
        {
          title: "Cumplimiento",
          className: "column-cumplimiento",
          children: [
            {
              title: "Listas Obligatorias",
              key: Math.random(),
              dataIndex: "compliance",
              width: "10%",
              align: "center",
              render: (text) => {
                return currentUser.cliente.modules.includes("MONITOR-AML") &&
                  currentUser.cliente.modules.includes("PEPSAN") ? (
                  <div className="item-risk-align">
                    <div
                      className={
                        "child-risk-onb-circle risk-" +
                        (text && text.PEPSAN && text.PEPSAN.color
                          ? text.PEPSAN.color
                          : "GRAY")
                      }
                    >
                      {text && text.PEPSAN && text.PEPSAN.bases
                        ? text.PEPSAN.bases.length
                        : "n/a"}
                    </div>
                  </div>
                ) : (
                  <div className="item-risk-align">
                    <div className="child-risk-onb-circle risk-GRAY">
                      <Icon type="lock" />
                    </div>
                  </div>
                );
              },
            },
            {
              title: "KYC",
              key: Math.random(),
              dataIndex: "compliance",
              width: "5%",
              align: "center",
              render: (text) => {
                return currentUser.cliente.modules.includes("MONITOR-AML") &&
                  currentUser.cliente.modules.includes("KYCAME") ? (
                  <div className="item-risk-align">
                    <div
                      className={
                        "child-risk-onb-circle risk-" +
                        (text && text.KYCAME && text.KYCAME.color
                          ? text.KYCAME.color
                          : "GRAY")
                      }
                    >
                      {text && text.KYCAME && text.KYCAME.bases
                        ? text.KYCAME.bases.length
                        : "n/a"}
                    </div>
                  </div>
                ) : (
                  <div className="item-risk-align">
                    <div className="child-risk-onb-circle risk-GRAY">
                      <Icon type="lock" />
                    </div>
                  </div>
                );
              },
            },
            {
              title: "UBO & Companies",
              key: Math.random(),
              dataIndex: "compliance",
              width: "15%",
              align: "center",
              render: (text, record) => {
                return (
                  <Row>
                    <Col span={8}>
                      {currentUser.cliente.modules.includes("MONITOR-AML") &&
                      currentUser.cliente.modules.includes("UBOCOM") ? (
                        <div
                          className={
                            "child-risk-onb-circle risk-" +
                            (text && text.UBOCOM && text.UBOCOM.color
                              ? text.UBOCOM.color
                              : "GRAY")
                          }
                        >
                          {text && text.UBOCOM && text.UBOCOM.bases
                            ? text.UBOCOM.bases.length
                            : "n/a"}
                        </div>
                      ) : (
                        <div className="child-risk-onb-circle risk-GRAY">
                          <Icon type="lock" />
                        </div>
                      )}
                    </Col>
                    {record.type === "Entity" &&
                      currentUser.cliente.modules.includes("ADMCLI") &&
                      parametersClient &&
                      parametersClient.ubofinder &&
                      ((record.processUF &&
                        !parametersClient.ubofinder.noInvestigaMalla) ||
                        (!record.formularioInterno &&
                          !parametersClient.ubofinder.sinFormulario)) && (
                        <Col span={8}>
                          <div style={{ cursor: "pointer" }}>
                            <Tooltip title="Ver beneficiarios finales">
                              <Icon
                                type="team"
                                style={{ fontSize: "20px" }}
                                onClick={() => {
                                  this.handleViewUbos(record);
                                }}
                              />
                            </Tooltip>
                          </div>
                        </Col>
                      )}
                    {record.nroRelated !== null && (
                      <Col span={8}>
                        <Tooltip title="Relacionados">
                          <div className="number-register-wrapper">
                            R {record.nroRelated}
                          </div>
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                );
              },
            },
          ],
        },
        {
          title: () => <div>Riesgo</div>,
          className: "onbRiskTitle",
          width: "10%",
          dataIndex: "risk",
          render: (text, record) => 
              <div
                className={
                  "child-risk-onb risk-" + (text ? text : record.amlStatus ? record.amlStatus : "GRAY")
                }
                onClick={
                  text && record.amlStatus == null
                    ? () => {}
                    : () => {
                        this.handleModalRisk(record);
                      }
                }
                style={text && record.amlStatus && { cursor: "pointer" }}
              >
                {text === null && "N/A"}
                {text === "BLACK" && "Crítico"}
                {text === "RED" && "Alto"}
                {text === "ORANGE" && "Medio"}
                {text === "YELLOW" && "Bajo"}
                {text === "GREEN" && "No Posee"}
              </div>
        },
        {
          title: () => <div className="image"></div>,
          className: "onbLogoTitle",
          width: "7%",
          render: (record) => {
            return (
              hasServices(record) && (
                <div className="ellipsis-icon-onb">
                  <Dropdown overlay={() => menu(record, "ellipsis")}>
                    <Icon
                      type="more"
                      style={{ fontSize: "30px", color: "#FE9F0C" }}
                    />
                  </Dropdown>
                </div>
              )
            );
          },
        },
      ];

      return columns;
    };

    const menu = (record, menuType) => {
      if (menuType === "ellipsis") {
        return (
          <Menu>
            {currentUser.cliente.modules.includes("ONBOARDING") && (
              <Menu.Item
                onClick={() => {
                  this.setState({ actionType: "newRequest" });
                  handleAction(record);
                }}
              >
                <Icon type="mail" /> Enviar formulario de Onboarding
              </Menu.Item>
            )}
            {currentUser.cliente.modules.includes("ONBOARDING") &&
              record.ficha && (
                <Menu.Item
                  onClick={() => {
                    this.setState({ actionType: "cronForm" });
                    handleAction(record);
                  }}
                >
                  <Icon type="clock-circle" /> Actualizar periódicamente el
                  formulario OnBoarding
                </Menu.Item>
              )}
            {currentUser.cliente.modules.includes("CDI-FORM") &&
              ((currentUser.cliente.modules.includes("CDI-FORM-CDI") &&
                currentUser.modules.includes("CDI-FORM-GD-P") &&
                record.category === "PROVEEDOR") ||
                ((currentUser.cliente.modules.includes("CDI-FORM-CDI") ||
                  currentUser.cliente.modules.includes("CDI-FORM-REL")) &&
                  (currentUser.modules.includes("CDI-FORM-GD-T") ||
                    currentUser.modules.includes("CDI-FORM-GD")) &&
                  record.category === "COLABORADOR") ||
                (currentUser.cliente.modules.includes("CDI-FORM-DIR") &&
                  currentUser.modules.includes("CDI-FORM-GDIR") &&
                  record.category === "DIRECTOR") ||
                (currentUser.cliente.modules.includes("CDI-FORM-PATR") &&
                  currentUser.modules.includes("CDI-FORM-GPATR") &&
                  record.category === "CLIENTE")) && (
                <Menu.Item
                  onClick={() => {
                    this.setState({ actionType: "newCdiRequest" });
                    handleAction(record);
                  }}
                >
                  <Icon type="mail" /> Enviar declaración
                </Menu.Item>
              )}
            {currentUser.cliente.modules.includes("CDI-FORM") &&
              ((currentUser.cliente.modules.includes("CDI-FORM-CDI") &&
                currentUser.modules.includes("CDI-FORM-GD-P") &&
                record.category === "PROVEEDOR") ||
                ((currentUser.cliente.modules.includes("CDI-FORM-CDI") ||
                  currentUser.cliente.modules.includes("CDI-FORM-REL")) &&
                  (currentUser.modules.includes("CDI-FORM-GD-T") ||
                    currentUser.modules.includes("CDI-FORM-GD")) &&
                  record.category === "COLABORADOR") ||
                (currentUser.cliente.modules.includes("CDI-FORM-DIR") &&
                  currentUser.modules.includes("CDI-FORM-GDIR") &&
                  record.category === "DIRECTOR") ||
                (currentUser.cliente.modules.includes("CDI-FORM-PATR") &&
                  currentUser.modules.includes("CDI-FORM-GPATR") &&
                  record.category === "CLIENTE")) &&
              record.periodicities &&
              record.periodicities.find((p) => p.tipo !== "KYC") && (
                <Menu.Item
                  onClick={() => {
                    this.setState({ actionType: "cronCdiForm" });
                    handleAction(record);
                  }}
                >
                  <Icon type="clock-circle" /> Actualizar periódicamente la
                  declaración
                </Menu.Item>
              )}
            {currentUser.cliente.modules.includes("QUIEBRA") &&
              record &&
              !record.hasQuiebras && (
                <Menu.Item
                  onClick={() => {
                    this.setState({ actionType: "brokeRegister" });
                    handleAction(record);
                  }}
                >
                  <Icon type="fall" /> Activar registro de quiebras
                </Menu.Item>
              )}
            {record &&
              record.type === "Entity" &&
              currentUser.cliente.modules.includes("ADMCLI") &&
              (currentUser.cliente.modules.includes("REG-ENTITY") ||
                (!currentUser.cliente.modules.includes("REG-ENTITY") &&
                  !currentUser.cliente.modules.includes("REG-PERSON"))) &&
              parametersClient && parametersClient.ubofinder &&
              !parametersClient.ubofinder.noInvestigaMalla &&
              record.processUF === false && (
                <Menu.Item
                  onClick={() => {
                    this.setState({ actionType: "uboFinder" });
                    handleAction(record);
                  }}
                >
                  <Icon type="user" /> Investigar UBOs
                </Menu.Item>
              )}
            {record &&
              record.type === "Entity" &&
              currentUser.cliente.modules.includes("ADMCLI") &&
              (currentUser.cliente.modules.includes("REG-ENTITY") ||
                (!currentUser.cliente.modules.includes("REG-ENTITY") &&
                  !currentUser.cliente.modules.includes("REG-PERSON"))) &&
              parametersClient && parametersClient.ubofinder &&
              !parametersClient.ubofinder.sinFormulario &&
              (!parametersClient.ubofinder.formularioInterno || !record.formularioInterno) &&
              (
                <Menu.Item
                  onClick={() => {
                    this.setState({ actionType: "form57" });
                    handleAction(record);
                  }}
                >
                  <Icon type="mail" theme="filled" />{" "}
                  {t("messages.aml.solicitud.formc57")}
                </Menu.Item>
              )}
          </Menu>
        );
      } else if (menuType === "action") {
        return (
          <Menu>
            {currentUser.cliente.modules.includes("ONBOARDING") && (
              <Menu.Item
                onClick={() => {
                  this.setState({ actionType: "newRequest" });
                  handleAction(selectedItems);
                }}
              >
                <Icon type="mail" /> Enviar formulario de Onboarding
              </Menu.Item>
            )}
            {currentUser.cliente.modules.includes("ONBOARDING") && (
              <Menu.Item
                onClick={() => {
                  this.setState({ actionType: "cronForm" });
                  handleAction(selectedItems);
                }}
              >
                <Icon type="clock-circle" /> Actualizar periódicamente el
                formulario OnBoarding
              </Menu.Item>
            )}
            {currentUser.cliente.modules.includes("CDI-FORM") && (
              <Menu.Item
                onClick={() => {
                  this.setState({ actionType: "newCdiRequest" });
                  handleAction(selectedItems);
                }}
              >
                <Icon type="mail" /> Enviar declaración
              </Menu.Item>
            )}
            {currentUser.cliente.modules.includes("CDI-FORM") && (
              <Menu.Item
                onClick={() => {
                  this.setState({ actionType: "cronCdiForm" });
                  handleAction(selectedItems);
                }}
              >
                <Icon type="clock-circle" /> Actualizar periódicamente de
                declaración
              </Menu.Item>
            )}
            {currentUser.cliente.modules.includes("QUIEBRA") && (
              <Menu.Item
                onClick={() => {
                  this.setState({ actionType: "brokeRegister" });
                  handleAction(selectedItems);
                }}
              >
                <Icon type="fall" /> Activar registro de quiebras
              </Menu.Item>
            )}
            {currentUser.cliente.modules.includes("ADMCLI") &&
              parametersClient &&
              parametersClient.ubofinder &&
              !parametersClient.ubofinder.noInvestigaMalla && (
                <Menu.Item
                  onClick={() => {
                    this.setState({ actionType: "uboFinder" });
                    handleAction(selectedItems);
                  }}
                >
                  <Icon type="user" />
                  Investigar UBOs
                </Menu.Item>
              )}
            {currentUser.cliente.modules.includes("ADMCLI") &&
              parametersClient &&
              parametersClient.ubofinder &&
              !parametersClient.ubofinder.sinFormulario && (
                <Menu.Item
                  onClick={() => {
                    this.setState({ actionType: "form57" });
                    handleAction(selectedItems);
                  }}
                >
                  <Icon type="mail" theme="filled" />
                  {t("messages.aml.solicitud.formc57")}
                </Menu.Item>
              )}
            {selectedItems.length === amountOfRegister && 
              selectedItems.filter((item) => item.type === 'Entity').length === amountOfRegister && (
                <Menu.Item
                  onClick={() => {
                    this.setState({ actionType: "beneficiariesMatchs" });
                    handleAction(selectedItems);
                  }}
                >
                  <Icon type="search" />
                  {t("messages.aml.findFinalBeneficiariesMatchs")}
                </Menu.Item>
              )}
          </Menu>
        );
      }
    };
    //TODO: REVISAR CONDICION ELSE IF NO APLICA AHORA
    const handleAction = (recipients) => {
      if (Array.isArray(recipients)) {
        this.setState({recordsAction: selectedItems});
      } else if (recipients) {
        const recordObj = {
          id: recipients.id,
          name: recipients.nombre,
          category: recipients.category
        };
        this.setState({ recordsAction: [recordObj] });
      }
    };

    const handleCancel = () => {
      this.handlePaginationChange(currentPage, filters);
      this.setState({
        recordsAction: [],
        selectedItems: [],
        chkAll: false,
        itemTable: null,
        selectedMode: "",
        isSendAll: false,
      });
    };

    const getUrlPerson = (id) => {
      return "/getImageClientUser/0/" + id;
      //<img src={ apiConfig.url + '/../getImageClientUser/0/' + item.id } alt="" style={{ height: '15px' }} />
    };

    return (
      <div className="register register-new">
        <PageTopBar breadcrumbs={breadcrumbs} />
        <Page>
          <PageHeader
            title={t("messages.aml.register")}
            description={t("messages.aml.registerDescription")}
            icon="user-add"
          >
            <div className="btn-actions">
              {currentUser.type !== "AUDIT" && (
                <div className="top-register-buttons">
                  <Button
                    type="primary"
                    onClick={this.handleNewProfile.bind(this)}
                    icon="plus"
                  >
                    {t("messages.aml.individualRegister")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={this.handleUploadBatch.bind(this)}
                    icon="plus"
                  >
                    {t("messages.aml.entityUpload")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={this.handleUploadBatchInactive.bind(this)}
                    icon="pause"
                  >
                    {t("messages.aml.entityUploadInactivate")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={this.handleUploadRelated.bind(this)}
                    icon="upload"
                  >
                    {t("messages.aml.related")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={this.handleReport.bind(this)}
                    icon="file-excel"
                  >
                    {t("messages.aml.export")}
                  </Button>
                </div>
              )}
            </div>
          </PageHeader>
          <PageContent>
            <Sidebar>
              <Filters
                currentUser={currentUser}
                parametersClient={parametersClient}
                filters={filters}
                groups={groups}
                areas={areas}
                categories={categories}
                onChangeFilters={this.handleChangeFilters.bind(this)}
                origin="new"
              />
            </Sidebar>
            <Content>
              {currentUser.cliente.modules.includes("MONITOR-AML") && (
                <ContentTopBar
                  isLoading={isLoading}
                  firstLoading={firstLoading}
                  colors={colors}
                  colorsCake={colorsCake}
                  filters={filters}
                  onChangeFilters={this.handleChangeFilters.bind(this)}
                />
              )}

              <Row style={{ margin: 5 }}>
                <Col span={10}>
                  <Input.Search
                    value={filters?.text}
                    placeholder={t("messages.aml.searhcDniNameEmail")}
                    onChange={(e) =>
                      this.handleChangeFilters(
                        "text",
                        e.target.value,
                        e.target.value !== "",
                        false
                      )
                    }
                    onSearch={(value) =>
                      this.handleChangeFilters("text", value, value !== "")
                    }
                    allowClear
                  />
                </Col>
                <Col span={1}>
                  {(currentUser.modules.includes("ONBOARDING") ||
                    currentUser.modules.includes("CDI-FORM") ||
                    currentUser.modules.includes("QUIEBRA")) && (
                    <div
                      className={
                        isAdvancedSearchVisible
                          ? "advanced-search on"
                          : "advanced-search"
                      }
                      onClick={
                        !isAdvancedSearchVisible
                          ? () => {
                              this.setState({ isAdvancedSearchVisible: true });
                            }
                          : () => {
                              this.setState({ isAdvancedSearchVisible: false });
                            }
                      }
                    >
                      <Icon
                        style={{
                          fontSize: "20px",
                          marginTop: 5,
                          marginLeft: 10,
                          cursor: "pointer",
                        }}
                        type={!isAdvancedSearchVisible ? "filter" : "close"}
                      />
                    </div>
                  )}
                </Col>
                <Col span={4} offset={9}>
                  <Dropdown
                    overlay={() => menu(selectedItems, "action")}
                    disabled={selectedItems.length === 0}
                  >
                    <Button style={{ float: "right" }}>
                      Acción <Icon type="down" />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <div
                    className={
                      isAdvancedSearchVisible === null
                        ? "filters-wrapper null"
                        : isAdvancedSearchVisible
                        ? "filters-wrapper show"
                        : "filters-wrapper hide"
                    }
                  >
                    <div className="filters-wrapper-inner">
                      <AdvancedTabs
                        cbFilters={cbFilters}
                        filters={filters}
                        currentUser={currentUser}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="register-table">
                {chkAll && (
                  <Row>
                    <div className="select-all-container">
                      {isSendAll
                        ? `Todos los ${totalCurrentUser} Registros están seleccionados. `
                        : `Los Registros de esta página están seleccionados. `}
                      <span onClick={this.handleSelectAll.bind(this)}>
                        {isSendAll
                          ? "Limpiar selección"
                          : `seleccionar todos los ${totalCurrentUser} Registros`}
                      </span>
                    </div>
                  </Row>
                )}
                <Row>
                  <Table
                    size="small"
                    loading={isLoading}
                    pagination={false}
                    columns={getTableColumns()}
                    dataSource={clients}
                    indentSize={30}
                    rowKey="id12"
                  />
                </Row>

                {resultsTotalNum > 0 && (
                  <PageFooter>
                    <Pagination
                      current={currentPage}
                      total={resultsTotalNum}
                      onChange={this.handlePaginationChange.bind(this)}
                      pageSize={resultsPerPage}
                    />
                  </PageFooter>
                )}
              </div>
            </Content>
            <div style={{ clear: "both" }}></div>
          </PageContent>
        </Page>
        <PageBottomBar breadcrumbs={breadcrumbs} />

        {itemTable && (
          <Modal
            wrapClassName="modal-fichaCliente-register"
            style={{ top: "10px" }}
            title="Registro"
            visible={true}
            onCancel={handleCancel}
            cancelText="Cerrar"
            footer={null}
            width={"95vw"}
          >
            <ModalClientCard
              item={itemTable}
              handleCancel={handleCancel}
              currentUser={currentUser}
              record={selectedRecord}
              areas={areasList}
              users={users}
              parametersClient={parametersClient}
            />
          </Modal>
        )}
        {isModalUploadVisible && (
          <Modal
            title={t("messages.aml.uploadFile")}
            className="modal-cliente"
            visible={isModalUploadVisible}
            onCancel={this.handleOnCancelModalUpload.bind(this)}
            footer={[
              uploaded ? (
                <Button onClick={this.handleOnCancelModalUpload.bind(this)}>
                  {t("messages.aml.btnClose")}
                </Button>
              ) : (
                <>
                  <div className="plantilla">
                    <a onClick={() => this.downloadPlantilla("records")}>
                      Plantilla
                    </a>
                  </div>
                  <Button onClick={this.handleOnCancelModalUpload.bind(this)}>
                    {t("messages.aml.cancel")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={this.handleUploadClient.bind(this)}
                    loading={uploading}
                  >
                    {t("messages.aml.save")}
                  </Button>
                </>
              ),
            ]}
          >
            {uploading ? (
              <Spin spinning={true} size="large" />
            ) : uploaded ? (
              <List
                header={<h3>{t("messages.aml.results")}</h3>}
                bordered
                dataSource={resultsUpload}
                renderItem={(item) => <List.Item>{item}</List.Item>}
                footer={
                  errorsUpload !== null &&
                  errorsUpload !== "" && (
                    <Button
                      onClick={this.downloadErrors.bind(this)}
                      icon="file-excel"
                    >
                      {" "}
                      {t("messages.aml.downloadReport")}
                    </Button>
                  )
                }
              />
            ) : (
              <Form layout="vertical">
                {currentUser.cliente.modules.includes("MONITOR-AML") && (
                  <Form.Item label={t("messages.aml.asignedUser")}>
                    <Select
                      value={userAsig}
                      onChange={(value) =>
                        this.handleOnChangeAsignedUser(value)
                      }
                    >
                      {this.getUsersAsig(users)}
                    </Select>
                  </Form.Item>
                )}
                {currentUser.cliente.outsourcer &&
                  currentUser.cliente.clientes !== null &&
                  currentUser.cliente.clientes.length > 0 &&
                  currentUser.subcliente === null && (
                    <Form.Item label={t("messages.aml.subclient")}>
                      <Select
                        onChange={(value) =>
                          this.handleOnChangeSubcliente(value)
                        }
                        placeholder="Seleccionar Empresa"
                        className="subclient-dropdown-uploadclient"
                        allowClear
                      >
                        {currentUser.cliente.clientes.map((item) => (
                          <Select.Option
                            className="subclient-option"
                            value={item.id}
                          >
                            <div className="subclient-option-inner">
                              <figure className="subclient-logo">
                                <ImageResources
                                  url={getUrlPerson(item.id)}
                                  styleResource={{ height: "15px" }}
                                ></ImageResources>
                              </figure>
                              <span
                                className="subclient-name"
                                style={{ paddingLeft: "10px" }}
                              >
                                {item.name}
                              </span>
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                <Form.Item label={t("messages.aml.category")}>
                  <Select
                    onChange={(value) => this.handleOnChangeCategory(value)}
                    defaultValue="CLIENTE"
                  >
                    <Select.Option value="CLIENTE">
                      {t("messages.aml.category.CLIENTE")}
                    </Select.Option>
                    <Select.Option value="PROVEEDOR">
                      {t("messages.aml.category.PROVEEDOR")}
                    </Select.Option>
                    <Select.Option value="COLABORADOR">
                      {t("messages.aml.category.COLABORADOR")}
                    </Select.Option>
                    <Select.Option value="DIRECTOR">
                      {t("messages.aml.category.DIRECTOR")}
                    </Select.Option>
                  </Select>
                </Form.Item>
                {parametersClient !== null &&
                  parametersClient.gruposNames &&
                  parametersClient.gruposNames.length > 0 && (
                    <Form.Item label={t("messages.aml.group")}>
                      <Select
                        onChange={(value) => this.handleOnChangeGrupo(value)}
                      >
                        {parametersClient.gruposNames.map((grupo) => (
                          <Select.Option value={grupo}>{grupo}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                {currentUser.cliente.modules.includes("ADMCLI") &&
                  (currentUser.cliente.modules.includes("REG-ENTITY") ||
                    (!currentUser.cliente.modules.includes("REG-ENTITY") &&
                      !currentUser.cliente.modules.includes("REG-PERSON"))) &&
                  parametersClient &&
                  parametersClient.ubofinder &&
                  !parametersClient.ubofinder.noInvestigaMalla && (
                    <Row>
                      <Col xs={12}>
                        <Form.Item label={t("messages.aml.ubosInvestigate")}>
                          <Switch
                            checked={processUF}
                            onChange={(checked) =>
                              this.handleOnChangeUF(checked)
                            }
                          />
                        </Form.Item>
                      </Col>
                      {parametersClient.ubofinder.formularioInterno &&
                        !parametersClient.ubofinder.sinFormulario && (
                          <Col xs={12}>
                            <Form.Item label={t("messages.aml.sendMail")}>
                              <Switch
                                checked={!formularioInterno}
                                disabled={!processUF}
                                onChange={(checked) =>
                                  this.handleOnChangeSendMail(checked)
                                }
                              />
                            </Form.Item>
                          </Col>
                        )}
                    </Row>
                  )}
                {fileList.length === 0 ? (
                  <Form.Item>
                    <Dragger {...propsUpload}>
                      <img
                        src={uploadIcon}
                        alt=""
                        style={{ height: "100px" }}
                      />
                      <p className="ant-upload-text">
                        {t("messages.aml.clickHereOrDragExcel")}.
                      </p>
                    </Dragger>
                  </Form.Item>
                ) : (
                  <div className="file-wrapper">
                    <div className="file-inner">
                      <div className="image-wrapper">
                        <div className="remove">
                          <Icon
                            type="close"
                            onClick={() => this.setState({ fileList: [] })}
                          />
                        </div>
                        <img src={excelFileIcon} alt="" />
                      </div>
                      <p className="file-name">{fileList[0].name}</p>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Modal>
        )}

        {isModalUploadInactiveVisible && (
          <Modal
            title={t("messages.aml.uploadFile")}
            className="modal-cliente"
            visible={isModalUploadInactiveVisible}
            onCancel={this.handleOnCancelModalUploadInactive.bind(this)}
            footer={[
              uploaded ? (
                <Button
                  onClick={this.handleOnCancelModalUploadInactive.bind(this)}
                >
                  {t("messages.aml.btnClose")}
                </Button>
              ) : (
                <>
                  <Button
                    onClick={this.handleOnCancelModalUploadInactive.bind(this)}
                  >
                    {t("messages.aml.cancel")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={this.handleUploadClientInactive.bind(this)}
                    loading={uploading}
                  >
                    {t("messages.aml.save")}
                  </Button>
                </>
              ),
            ]}
          >
            {uploading ? (
              <Spin spinning={true} size="large" />
            ) : uploaded ? (
              <List
                header={<h3>{t("messages.aml.results")}</h3>}
                bordered
                dataSource={resultsUpload}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            ) : (
              <Form layout="vertical">
                {fileList.length === 0 ? (
                  <Form.Item>
                    <Dragger {...propsUpload}>
                      <img
                        src={uploadIcon}
                        alt=""
                        style={{ height: "100px" }}
                      />
                      <p className="ant-upload-text">
                        {t("messages.aml.clickHereOrDragExcel")}.
                      </p>
                    </Dragger>
                  </Form.Item>
                ) : (
                  <div className="file-wrapper">
                    <div className="file-inner">
                      <div className="image-wrapper">
                        <div className="remove">
                          <Icon
                            type="close"
                            onClick={() => this.setState({ fileList: [] })}
                          />
                        </div>
                        <img src={excelFileIcon} alt="" />
                      </div>
                      <p className="file-name">{fileList[0].name}</p>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Modal>
        )}
        {isModalUploadRelatedVisible && (
          <Modal
            title={t("messages.aml.uploadFile")}
            className="modal-cliente"
            visible={isModalUploadRelatedVisible}
            onCancel={this.handleOnCancelModalUploadRelated.bind(this)}
            footer={[
              uploaded ? (
                <Button
                  onClick={this.handleOnCancelModalUploadRelated.bind(this)}
                >
                  {t("messages.aml.btnClose")}
                </Button>
              ) : (
                <>
                  <div className="plantilla">
                    <a onClick={() => this.downloadPlantilla("related")}>
                      Plantilla
                    </a>
                  </div>
                  <Button
                    onClick={this.handleOnCancelModalUploadRelated.bind(this)}
                  >
                    {t("messages.aml.cancel")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={this.handleUploadRelatedFile.bind(this)}
                    loading={uploading}
                  >
                    {t("messages.aml.save")}
                  </Button>
                </>
              ),
            ]}
          >
            {uploading ? (
              <Spin spinning={true} size="large" />
            ) : uploaded ? (
              <List
                header={<h3>{t("messages.aml.results")}</h3>}
                bordered
                dataSource={resultsUpload}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            ) : (
              <Form layout="vertical">
                {fileList.length === 0 ? (
                  <Form.Item>
                    <Dragger {...propsUpload}>
                      <img
                        src={uploadIcon}
                        alt=""
                        style={{ height: "100px" }}
                      />
                      <p className="ant-upload-text">
                        {t("messages.aml.clickHereOrDragExcel")}.
                      </p>
                    </Dragger>
                  </Form.Item>
                ) : (
                  <div className="file-wrapper">
                    <div className="file-inner">
                      <div className="image-wrapper">
                        <div className="remove">
                          <Icon
                            type="close"
                            onClick={() => this.setState({ fileList: [] })}
                          />
                        </div>
                        <img src={excelFileIcon} alt="" />
                      </div>
                      <p className="file-name">{fileList[0].name}</p>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Modal>
        )}
        {isModalRiskVisible && (
          <Modal
            title={t("messages.aml.risk")}
            visible={true}
            onCancel={this.handleOnCancelModalRisk.bind(this)}
            width={900}
            footer={[
              <Button onClick={this.handleOnCancelModalRisk.bind(this)}>
                {t("messages.aml.btnClose")}
              </Button>,
            ]}
          >
            <ModalRisk
              key={Math.floor(Math.random() * 100 + 1)}
              record={selectedRecord}
            />
          </Modal>
        )}
        {isModalUbosVisible && (
          <Modal
            key={Math.floor(Math.random() * 100 + 1)}
            title={t("messages.aml.finalBeneficiaries")}
            visible={true}
            onCancel={this.handleOnCancelModalUbos.bind(this)}
            width={1100}
            footer={[
              <Button onClick={this.handleOnCancelModalUbos.bind(this)}>
                {t("messages.aml.btnClose")}
              </Button>,
            ]}
          >
            <ModalUbos record={selectedRecord} currentUser={currentUser} />
          </Modal>
        )}
        {isModalReportVisible && (
          <Modal
            title={t("messages.aml.export")}
            visible={true}
            onCancel={this.handleOnCancelModalReport.bind(this)}
            width={500}
            footer={[
              <Button onClick={this.handleOnCancelModalReport.bind(this)}>
                {t("messages.aml.btnClose")}
              </Button>,
            ]}
          >
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={this.handleExcelReport.bind(this)}
                disabled={
                  isLoadingExport ||
                  isLoadingRisk ||
                  isLoadingCertificates ||
                  isLoadingBfReport ||
                  isLoadingMp ||
                  isLoadingFormReport
                }
                icon={isLoadingExport ? "loading" : "file-excel"}
              >
                {t("messages.aml.export")}
              </Button>
              <br />
              <br />
              {currentUser.cliente.modules.includes("MONITOR-AML") && (
                <>
                  <Button
                    onClick={this.handleRiskReport.bind(this)}
                    disabled={
                      isLoadingExport ||
                      isLoadingRisk ||
                      isLoadingCertificates ||
                      isLoadingBfReport ||
                      isLoadingMp ||
                      isLoadingFormReport
                    }
                    icon={isLoadingRisk ? "loading" : "file-excel"}
                  >
                    {t("messages.aml.riskLevels")}
                  </Button>
                  <br />
                  <br />
                </>
              )}
              <Button
                onClick={this.handlePDFReport.bind(this)}
                disabled={
                  isLoadingExport ||
                  isLoadingRisk ||
                  isLoadingCertificates ||
                  isLoadingBfReport ||
                  isLoadingMp ||
                  isLoadingFormReport
                }
                icon={isLoadingCertificates ? "loading" : "file-pdf"}
              >
                {t("messages.aml.certificates")}
              </Button>
              {currentUser.cliente.modules.includes("ADMCLI") &&
                parametersClient &&
                parametersClient.ubofinder &&
                !parametersClient.ubofinder.noInvestigaMalla && (
                  <>
                    <br />
                    <br />
                    <Button
                      onClick={this.handleBFReport.bind(this)}
                      disabled={
                        isLoadingExport ||
                        isLoadingRisk ||
                        isLoadingCertificates ||
                        isLoadingBfReport ||
                        isLoadingMp ||
                        isLoadingFormReport
                      }
                      icon={isLoadingBfReport ? "loading" : "profile"}
                    >
                      {t("messages.aml.finalBeneficiaries")}
                    </Button>
                    <br />
                    <br />
                    <Button
                      onClick={this.handleMallasPropiedadReport.bind(this)}
                      disabled={
                        isLoadingExport ||
                        isLoadingRisk ||
                        isLoadingCertificates ||
                        isLoadingBfReport ||
                        isLoadingMp ||
                        isLoadingFormReport
                      }
                      icon={isLoadingMp ? "loading" : "profile"}
                    >
                      {t("messages.aml.ownershipTree")}
                    </Button>
                    {!parametersClient.ubofinder.sinFormulario && (
                      <>
                        <br />
                        <br />
                        <Button
                          onClick={this.handleFormsReport.bind(this)}
                          disabled={
                            isLoadingExport ||
                            isLoadingRisk ||
                            isLoadingCertificates ||
                            isLoadingBfReport ||
                            isLoadingMp ||
                            isLoadingFormReport
                          }
                          icon={isLoadingFormReport ? "loading" : "file-pdf"}
                        >
                          {t("messages.aml.ubofinder.forms")}
                        </Button>
                      </>
                    )}
                  </>
                )}
            </div>
          </Modal>
        )}
        {recordsAction.length > 0 && (
          <Modal
            title={actionTypeRender[actionType]}
            centered={true}
            width={500}
            footer={false}
            onCancel={handleCancel}
            visible={true}
          >
            <ModalRequestOpt
              type={actionType}
              recipients={recordsAction}
              handleCancel={handleCancel}
              currentUser={currentUser}
              isSendAll={isSendAll}
              totalCurrentUser={totalCurrentUser}
              filterCurrentUser={filterCurrentUser}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(Register));
