import { GoogleOAuthProvider } from "@react-oauth/google"
import { LoginGooglePage } from '..';
import Logo from '../../layouts/commonComponent/components/Logo/Logo';
import { Row, Col } from 'antd'

export default function AuthLogin({client, initAuth, successHandler, config, authResponse}) {

    return (
        <GoogleOAuthProvider clientId={config.clientId}>
            <div className="login-header">
                <Row>
                    <Col xs={ 8 } offset={8} style={{textAlign:'center', padding:20, alignContent:'center'}}>
                    { client?.id &&
                        <div style={{maxWidth:'fit-content', margin: 'auto'}}>
                            <Logo currentUser={{clientId: client?.id}} isForm={false}/>
                        </div>
                    }
                    </Col>
                </Row>
            </div>
            <LoginGooglePage client={client} initAuth={initAuth} successHandler={successHandler} authResponse={authResponse} />
        </GoogleOAuthProvider>
    )
}