import 'antd/dist/antd.css'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import i18nextConfig from './config/i18next'
import moment from 'moment'
import 'moment/locale/es'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {LayoutPrivate, LayoutPublic} from './layouts'
import {Loading, ModalChangePassword} from './layouts/Private/components'
import {
    AdminPage,
    BatchPage,
    CdiInvestigacionPage_v2,
    HomePage,
    MfaPage,
    InterestConflictsPage,
    InterestConFlictsPage_v2,
    LoginPage,
    GoogleLoginPage,
    AuthLoginPage,
    MonitoringPage,
    NewRegisterPage,
    NotAuthorizedPage,
    NotFoundPage,
    OnBoardingPage,
    ProfilePage,
    Query2Page,
    QueryPage,
    QuiebrasPage,
    ReportHistoryPage,
    StakeholderPage,
    StakeholderPortalPage,
    QueryRiskPage,
    InsidePage
} from './pages'
import {
    GenFormDirPage,
    GenFormTrabPage,
    GenFormSocPage,
    GenFormProvPage,
    GenFormFpPage,
    GenFormGiftPage,
    GenFormPepPage,
    GenFormTravelPage,
    GenFormValuesPage
} from './pages/InterestConflicts/forms/GenericForms'
import {
    FormDirPage as FormDirPageSmu,
    FormProvPage as FormProvPageSmu,
    FormTrabPage as FormTrabPageSmu
} from './pages/InterestConflicts/forms/smu'
import {
    FormProvPage as FormProvPagePF,
    FormTrabPage as FormTrabPagePF
} from './pages/InterestConflicts/forms/pfalimentos'
import {
    FormDirPage as FormDirPageNC,
    FormProvPage as FormProvPageNC,
    FormTrabPage as FormTrabPageNC
} from './pages/InterestConflicts/forms/nuevocapital'
import {
    FormDirPage as FormDirPageCLA,
    FormProvPage as FormProvPageCLA,
    FormTrabPage as FormTrabPageCLA,
    FormTravelPage as FormTravelPageCLA,
    FormGiftPage as FormGiftPageCLA,
    FormFPPage as FormFPPageCLA,
    FormSocPage as FormSocPageCLA
} from './pages/InterestConflicts/forms/cajalosandes'
import {FormPatrPage as FormPatrPageBtg, FormTrabPage as FormTrabPageBtg} from './pages/InterestConflicts/forms/btg'
import {FormTrabPage as FormTrabPageSC} from './pages/InterestConflicts/forms/santanderConsumer'
import {FormTrabPage as FormTrabPageFam} from './pages/InterestConflicts/forms/famae'

import { FormTrabPage as FormTrabAguasAndinas,
         FormProvPage as FormProvAguasAndinas,
         FormDirPage as FormDirAguasAndinas 
        }  from './pages/InterestConflicts/forms/aguasandinasform'
import {
    FormDirPage as FormDirPageREA,
    FormProvPage as FormProvPageREA,
    FormTrabPage as FormTrabPageREA,
    FormTravelPage as FormTravelPageREA,
    FormGiftPage as FormGiftPageREA,
    FormFPPage as FormFPPageREA,
    FormSocPage as FormSocPageREA
} from './pages/InterestConflicts/forms/reale'
import {FormKycPage} from './pages/OnBoarding/forms/FormKyc'
import {FormKycPageAraucoMalls} from './pages/OnBoarding/forms/arauco-malls'
import {FormKycCliPage as FormKycCliPageParauco, FormKycProvPage as FormKycProvPageParauco} from './pages/OnBoarding/forms/parauco'
import {LocalStorageService, ReportService} from './services'
import {authTokenRenewerHelper, authTokenValidatorHelper, sessionStorageCleanerHelper, authTokenSessionStorageSaverHelper} from './helpers'
import {
    animateLogoutPromise,
    changePasswordPromise,
    deleteReportPromise,
    generateReportPromise,
    getCurrentUserPromise,
    getReportByUserPromise,
    getReportPromise,
    logoutPromise,
    removeLoginAnimationsPromise,
    getAuthEmailTokenPromise
} from './promises'
import { getDomainPromise, getOauthConfigPromise } from './pages/Stakeholder/promises'
import { decodeAuthToken } from './helpers/decodeAuthToken'

import {Icon, message, Modal, Tooltip} from 'antd'
import {globalContext} from "./contexts";
import { GenFormColabPage } from './pages/InterestConflicts/forms/GenericForms'

class App extends Component {
    state = {
        userParams: {},
        currentUser: {},
        isActivated: true,
        changePasswordForced: false,
        isLoading: true,
        isLoggedIn: false,
        //loginOauthConfig: {provider:'oauth2', authority:'https://login.microsoftonline.com/147b2161-64ba-4232-9eb3-f1045ef9d339/v2.0', clientId:'0451d04d-424d-4185-9b82-e8e454b7a087'},
        isModalChangePasswordVisible: false,
        isModalWantToChangePwdVisible: false,
        reportId: null,
        intervalReportId: null,
        finishReportCB: null,
        mfaAuthenticated: false,
        //client: {id: '0c1458a7-2256-4711-88dc-93024c8c2911', name: 'test'},
        externalAuth: null,
        authResponse: null
    }

    async initUser(validatePwd) {
        const language = LocalStorageService.read('i18nextLng')

        const userData = await this.getCurrentUser()
        const currentUser = userData.user
        const userType = LocalStorageService.read("userType")

        if (!(userType && (userType === currentUser.type))) {
            message.error("Error, acceso vulnerado ");
        } else {
            const isActivated = currentUser.feActivacion !== null || !validatePwd
            const isPasswordBlocked= (LocalStorageService.read("isPasswordBlocked") === "true")

            if ((!isActivated) || isPasswordBlocked) {
                const messageToModal= (!isActivated) ?  "por cambio de contraseña"  : "por contraseña caducada";
                if(!isActivated){
                    this.setState({changePasswordForced: true})
                }else {
                    this.setState({changePwdMessage: messageToModal})
                }
                this.handleOpenModalChangePassword()
            } else {
                this.setState({
                    userParams: userData,
                    mfaAuthenticated: currentUser.authenticatedWith2FA
                })
                const isPwdNextToExpired = currentUser.pwdNextToExpired && validatePwd
                if( isPwdNextToExpired) {
                    this.handleOpenModalWantToChangePwdModal()
                }
                //if(!currentUser.hasMfaKey && !document.location.pathname.startsWith('/mfa') &&
                //    (userData?.system?.mfa === 'requerido'
                //    || (userData?.system?.mfa === 'opcional' && currentUser.cliente?.modules?.includes('MFAOPT') && currentUser.cliente?.modules?.includes('MFAREQ')))) {
                //    document.location.href = '/mfa'
                //}else {
                    this.setState({
                        currentUser,
                        isLoggedIn: true
                    })
                    new authTokenRenewerHelper(this.handleLogout.bind(this))
                    removeLoginAnimationsPromise()

                    if (currentUser.cliente.pais !== 'CHI') {
                        i18nextConfig.changeLanguage(language.substring(0, 2) + currentUser.cliente.pais)
                    }

                    this.loadUser()
                //}
            }
        }
    }

    async initAuth(externalAuth) {
        this.setState({externalAuth})
    }

    async componentDidMount() {
        const language = LocalStorageService.read('i18nextLng')
        moment.locale(language.substring(0, 2))

        this.handleThemeCheck()

        let verifySubdomain = false;
        let loginOauthConfig = null;
        if(process.env.REACT_APP_MAIN_DOMAIN && document?.location?.pathname && !document.location.pathname.startsWith('/forms/') && !document.location.pathname.startsWith('/portal/')) {
            const hosts = process.env.REACT_APP_MAIN_DOMAIN.split(",")
            if(!hosts.includes(document.location.hostname)) {
                const hostname = document.location.hostname.split(".")
                const subdomain = hostname[0]
                verifySubdomain = true
                const rDomain = await getDomainPromise(subdomain);
                if(rDomain?.data?.id) {
                    this.setState({client: rDomain.data})
                    const rOauth = await getOauthConfigPromise(rDomain.data.id)
                    if(rOauth?.provider) {
                        loginOauthConfig = rOauth
                    }
                    this.setState({isLoading: false})
                }else {
                    this.setState({client: {error: 404}})
                    this.setState({isLoading: false})
                }
            }
        }
        if(loginOauthConfig) this.setState({loginOauthConfig})

        const isValidAuthToken = await authTokenValidatorHelper()
        if (isValidAuthToken) {
            await this.initUser(!(loginOauthConfig || this.state.loginOauthConfig))
        }
        if(!verifySubdomain) this.setState({isLoading: false})
    }

    handleThemeCheck() {
        const theme = LocalStorageService.read('theme')

        if (theme !== null) {
            document.body.className = theme
        } else {
            document.body.className = 'theme-metal'
        }
    }

    async handleLogin() {
        this.initUser(true)
    }

    async handleLoginEmail(token, email) {
        const {client} = this.state

        const authToken = await getAuthEmailTokenPromise(client.id, email, token);
        if (!authToken.error) {
            if (authToken && authToken.token) {
                await authTokenSessionStorageSaverHelper(authToken)
        
                const decodedToken = decodeAuthToken(authToken.token);
                LocalStorageService.create("userType",decodedToken.type);
                LocalStorageService.create("isPasswordBlocked",decodedToken.isPasswordBlocked);
        
                await this.initUser(false)
            }
        }
        authToken.email = email
        this.setState({authResponse: authToken})
    }

    loadUser() {
        getReportByUserPromise().then(report => {
            if (report) {
                this.setState({reportId: report.id})
                const intervalId = setInterval(() => {
                    this.checkStatusReport()
                }, 2000);
                this.setState({intervalReportId: intervalId})
            }
        })
    }

    async checkStatusReport() {
        const report = await getReportPromise(this.state.reportId)
        if (report) {
            if (report.status === 'FINISHED' || report.status === 'DOWNLOADED') {
                if (report.status === 'FINISHED') {
                    let fileName = ''
                    if (report.type === 'RECORDS') fileName = 'registros.xlsx'
                    else if (report.type === 'MALLAS') fileName = 'propietarios.csv'
                    else if (report.type === 'UBOS') fileName = 'Beneficiarios_Finales.csv'
                    else if (report.type === 'RISK') fileName = 'risks.xlsx'
                    else if (report.type === 'PDFS') fileName = 'certificados.zip'
                    else if (report.type === 'FORMS') fileName = 'formularios.zip'
                    else if (report.type === 'ALERT') fileName = 'monitoreo.xlsx'
                    else if (report.type === 'NEG') fileName = 'negatividad.txt'
                    else if (report.type === 'DECL') fileName = 'Declaraciones.xlsx'
                    else if (report.type === 'ONB') fileName = 'Declaraciones.xlsx'
                    else if (report.type === 'DECL_REC') fileName = 'Registros.xlsx'

                    clearInterval(this.state.intervalReportId)
                    const {finishReportCB, reportId} = this.state
                    if (finishReportCB) finishReportCB(report.type)
                    this.setState({intervalReportId: null, reportId: null, finishReportCB: null})

                    message.loading({content: 'Descargando reporte...', duration: 0, key: 'msg-report'})
                    await ReportService.read('/getFileReporte', {id: reportId}, null, fileName, {
                        onDownloadProgress: (progressEvent) => {
                            const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                            if (progressEvent.total > 0) {
                                message.loading({
                                    content: 'Descargando reporte...' + percentCompleted + '%',
                                    duration: 0,
                                    key: 'msg-report'
                                })
                            }
                        }
                    })
                    message.destroy()
                }
            } else {
                let content = (<>
                    Generando reporte...{report.processed === 0 ? 0 : parseInt(report.processed * 100 / report.lines)}%
                    &nbsp;&nbsp;
                    <Tooltip title="Cancelar reporte"><Icon type="close"
                                                            style={{cursor: 'pointer', color: 'rgba(240,0,0,.7)'}}
                                                            onClick={() => {
                                                                deleteReportPromise(report.id)
                                                            }}/></Tooltip>
                </>)
                message.loading({content, duration: 0, key: 'msg-report'})
            }
        } else {
            clearInterval(this.state.intervalReportId)
            message.destroy()

            const {finishReportCB} = this.state
            if (finishReportCB) finishReportCB()

            this.setState({intervalReportId: null, reportId: null, finishReportCB: null})
        }
    }

    async handleLogout() {
        logoutPromise().then(r => {
            console.log("Logout exitoso");
        })
        .catch(e => {
            console.log("Error de Logout");
        })
        const {externalAuth, loginOauthConfig} = this.state
        if(externalAuth && externalAuth.logout && loginOauthConfig) {
            externalAuth.logout()
        }

        await sessionStorageCleanerHelper()
        animateLogoutPromise()

        await this.setState({
            currentUser: {},
            isActivated: true,
            isLoading: false,
            isLoggedIn: false
        })
    }

    async getCurrentUser() {
        const currentUser = await getCurrentUserPromise()
        this.setState({isLoading: false})
        return currentUser
    }

    async handleOpenModalChangePassword() {
        this.setState({isModalChangePasswordVisible: true})
    }

    async handleCloseModalChangePassword() {
        this.setState({isModalChangePasswordVisible: false})
    }

    async handleOpenModalWantToChangePwdModal() {
        this.setState({isModalWantToChangePwdVisible: true})
    }

    async handleCloseModalWantToChangePwdModal() {
        this.setState({isModalWantToChangePwdVisible: false})
    }

    async handleSaveChangePassword(passwordCurrent, passwordNew, passwordNewConfirm) {
        await changePasswordPromise(passwordCurrent, passwordNew, passwordNewConfirm)

        this.setState({isModalChangePasswordVisible: false})
    }

    async generateReport(type, filters, cb) {
        const {reportId} = this.state
        if (reportId === null) {
            const id = await generateReportPromise(type, filters)
            this.setState({reportId: id, finishReportCB: cb})
            const intervalId = setInterval(() => {
                this.checkStatusReport()
            }, 2000);
            this.setState({intervalReportId: intervalId})
            return true
        } else {
            message.warn({content: 'Ya existe un reporte activo', duration: 3})
            return false
        }
    }

    validaMfaAuthenticated() {
        return this.state.mfaAuthenticated
    }

    validaMfaRequired() {
        const {currentUser, userParams} = this.state
        if(userParams?.system?.mfa === 'requerido' || (userParams?.system?.mfa === 'opcional' && currentUser.cliente.modules?.includes('MFAOPT'))) {
            if(!currentUser.hasMfaKey) {
                if(userParams?.system?.mfa === 'requerido') {
                    return true
                }else if(userParams?.system?.mfa === 'opcional' && currentUser.cliente.modules?.includes('MFAOPT') && currentUser.cliente.modules?.includes('MFAREQ')) {
                    return true
                }
            }else if(!currentUser.authenticatedWith2FA) {
                return true
            }
        }
        return false
    }

    renderComponent(CurrentPage, protectedContent = null) {
        const {isLoggedIn, loginOauthConfig, currentUser, userParams, client, authResponse} = this.state

        if (protectedContent !== null && currentUser.cliente && currentUser.modules ) {
            if (!currentUser.cliente.modules.includes('AML2')) {
                CurrentPage = NotAuthorizedPage
            } else {
                if(protectedContent !== 'mfa' && this.validaMfaRequired()) {
                    CurrentPage = MfaPage
                }else {
                    switch (protectedContent) {
                        case 'mfa':
                            CurrentPage = ((userParams?.system?.mfa === 'opcional' && currentUser.cliente.modules?.includes('MFAOPT')) || userParams?.system?.mfa === 'requerido' ) ? CurrentPage : NotAuthorizedPage
                            break
                        case 'query':
                            CurrentPage = (currentUser.cliente.planHist !== null && currentUser.cliente.modules.includes('CONSULTA') && currentUser.modules.includes('CONSULTA')) ? CurrentPage : NotAuthorizedPage
                            break

                        case 'query2':
                            CurrentPage = (currentUser.cliente.planHist !== null && currentUser.cliente.modules.includes('CONSULTA2')) ? CurrentPage : NotAuthorizedPage
                            break

                        case 'queryrisk':
                            CurrentPage = (currentUser.cliente.planHist !== null && currentUser.cliente.modules.includes('QUERY-RISK') && currentUser.modules.includes('QUERY-RISK')) ? CurrentPage : NotAuthorizedPage
                            break

                        case 'monitoring':
                            CurrentPage = (currentUser.cliente.modules.includes('MONITOR') && currentUser.modules.includes('MONITOR')) ? CurrentPage : NotAuthorizedPage
                            break

                        case 'batch':
                            CurrentPage = (currentUser.cliente.planBatch !== null && currentUser.cliente.planBatch.tipo === 'ABIERTO' && currentUser.cliente.modules.includes('BATCH') && currentUser.modules.includes('BATCH')) ? CurrentPage : NotAuthorizedPage
                            break

                        case 'register':
                            CurrentPage = (currentUser.cliente.modules.includes('REGISTRO') && currentUser.modules.includes('REGISTRO')) ? CurrentPage : NotAuthorizedPage
                            break

                        case 'conflict':
                            CurrentPage = (currentUser.cliente.modules.includes('CDI-MATCH') || currentUser.cliente.modules.includes('CDI-FORM')) && (currentUser.modules.includes('CDI-MATCH') || currentUser.modules.includes('CDI-FORM')) ? CurrentPage : NotAuthorizedPage
                            break

                        case 'bankruptcy':
                            CurrentPage = (currentUser.cliente.modules.includes('QUIEBRA') && currentUser.modules.includes('QUIEBRA')) ? CurrentPage : NotAuthorizedPage
                            break

                        case 'onboarding':
                            CurrentPage = (currentUser.cliente.modules.includes('ONBOARDING') && currentUser.modules.includes('ONBOARDING')) ? CurrentPage : NotAuthorizedPage
                            break

                        case 'admin':
                            CurrentPage = (currentUser.type === 'SADMIN' || currentUser.type === 'ADMIN' || currentUser.type === 'AUDIT') ? CurrentPage : NotAuthorizedPage
                            break
                         case 'inside-compliance':
                            CurrentPage = (currentUser.cliente.modules.includes('INSIDE') && currentUser.modules.includes('INSIDE'))  ? CurrentPage : NotAuthorizedPage
                            break    

                        default:
                    }
                }
            }
        }

        let CurrenComponent
        if(loginOauthConfig?.provider === 'oauth2') {
            CurrenComponent = <AuthLoginPage client={client} initAuth={this.initAuth.bind(this)} config={loginOauthConfig} authResponse={authResponse} successHandler={this.handleLoginEmail.bind(this)}>
                        { isLoggedIn && <CurrentPage currentUser={currentUser}/> }
                    </AuthLoginPage>
        }else if(loginOauthConfig?.provider === 'google-oauth2') {
            CurrenComponent = <GoogleLoginPage client={client} initAuth={this.initAuth.bind(this)} config={loginOauthConfig} authResponse={authResponse} successHandler={this.handleLoginEmail.bind(this)}>
                        { isLoggedIn && <CurrentPage currentUser={currentUser}/> }
                    </GoogleLoginPage>
        }else if(isLoggedIn) {
            CurrenComponent = <CurrentPage currentUser={currentUser} />
        }else if(client?.error === 404) {
            CurrenComponent = <NotFoundPage />
        }else if(client?.error === 403) {
            CurrenComponent = <NotAuthorizedPage />
        }else {
            CurrenComponent = <LoginPage successHandler={this.handleLogin.bind(this)} client={client} />
        }
        return CurrenComponent
    }

    render() {
        const {currentUser, userParams, isLoading, isLoggedIn, isModalChangePasswordVisible, isModalWantToChangePwdVisible, changePasswordForced} = this.state
        const Layout = isLoggedIn ? LayoutPrivate : LayoutPublic

        if (isLoading) {
            return <Loading/>
        } else {
            return (
                <I18nextProvider i18n={i18nextConfig}>
                    <globalContext.Provider value={{currentUser, userParams, validaMfaAuthenticated: this.validaMfaAuthenticated.bind(this), generateReport: this.generateReport.bind(this)}}>
                        <Router>
                            <Layout currentUser={currentUser} logoutHandler={this.handleLogout.bind(this)}>
                            <Switch>
                                <Route path="/" exact render={ () => this.renderComponent(HomePage, 'home') } />
                                <Route path="/mfa" exact render={ () => this.renderComponent(MfaPage, 'mfa') } />
                                <Route path="/consulta" exact render={ () => this.renderComponent(QueryPage, 'query') } />
                                <Route path="/consulta2" exact render={ () => this.renderComponent(Query2Page, 'query2') } />
                                <Route path="/query-risk" exact render={ () => this.renderComponent(QueryRiskPage, 'queryrisk') } />
                                <Route path="/monitoreo/eventos" exact render={ () => this.renderComponent(MonitoringPage, 'monitoring') } />
                                <Route path="/monitoreo/:params?" exact render={ () => this.renderComponent(MonitoringPage, 'monitoring') } />
                                <Route path="/consulta/:text" exact render={ () => this.renderComponent(QueryPage, 'query') } />
                                <Route path="/masivos" exact render={ () => this.renderComponent(BatchPage, 'batch') } />
                                <Route path="/perfil/:id" exact render={ () => this.renderComponent(ProfilePage, 'query') } />
                                <Route path="/administracion" exact render={ () => this.renderComponent(AdminPage, 'admin') } />
                                <Route path="/registro/:params?" exact render={ () => this.renderComponent(NewRegisterPage, 'register') } />
                                <Route path="/quiebras" exact render={ () => this.renderComponent(QuiebrasPage, 'bankruptcy') } />
                                <Route path="/conflictos-de-interes" exact render={ () => this.renderComponent(InterestConflictsPage, 'conflict') } />
                                <Route path="/onboarding/" exact render={ () => this.renderComponent(OnBoardingPage, 'onboarding') } />
                                <Route path="/declaraciones/:params?" exact render={ () => this.renderComponent(InterestConFlictsPage_v2, 'cdi') } />
                                <Route path="/cdi-relaciones/" exact render={() => this.renderComponent(CdiInvestigacionPage_v2, "cdi-relaciones")} />
                                <Route path="/cdi-investigacion/" exact render={() => this.renderComponent(CdiInvestigacionPage_v2, "cdi-investigacion")} />
                                <Route path="/inside-compliance/" exact render={() => this.renderComponent(InsidePage, "inside-compliance")} />
                                <Route path="/historial-de-reportes" exact render={() => this.renderComponent(ReportHistoryPage, "historial-de-reportes")}/>
                                <Route path="/callback-oauth2" exact render={() => this.renderComponent(HomePage, 'callback')}/>


                                {/* Formularios CDI - SMU */}
                                <Route path="/forms/1/formTrab/:id/:view?" exact render={ () => <FormTrabPageSmu/>} />
                                <Route path="/forms/1/formProv/:id/:view?" exact render={ () => <FormProvPageSmu/>} />
                                <Route path="/forms/1/formDir/:id/:view?" exact render={ () => <FormDirPageSmu/>} />

                                {/* Formularios CDI - PF Alimentos */}
                                <Route path="/forms/pfalimentos/formTrab/:id/:view?" exact render={ () => <FormTrabPagePF/>} />
                                <Route path="/forms/pfalimentos/formProv/:id/:view?" exact render={ () => <FormProvPagePF/>} />
                                
                                {/* Formularios CDI - Aguas Andinas */}
                                <Route path="/forms/aguasandinas/formTrab/:id/:view?" exact render={ () => <FormTrabAguasAndinas/>} />
                                <Route path="/forms/aguasandinas/formProv/:id/:view?" exact render={ () => <FormProvAguasAndinas/>} />
                                <Route path="/forms/aguasandinas/formDir/:id/:view?" exact render={ () => <FormDirAguasAndinas/>} />

                                {/* Formularios CDI - Caja los Andes */}
                                <Route path="/forms/2/formTrab/:id/:view?" exact render={ () => <FormTrabPageCLA/>} />
                                <Route path="/forms/2/formProv/:id/:view?" exact render={ () => <FormProvPageCLA/>} />
                                <Route path="/forms/2/formDir/:id/:view?" exact render={ () => <FormDirPageCLA/>} />
                                <Route path="/forms/2/formTravel/:id/:view?" exact render={ () => <FormTravelPageCLA/>} />
                                <Route path="/forms/2/formFP/:id/:view?" exact render={ () => <FormFPPageCLA/>} />
                                <Route path="/forms/2/formSoc/:id/:view?" exact render={ () => <FormSocPageCLA/>} />
                                <Route path="/forms/2/formGift/:id/:view?" exact render={ () => <FormGiftPageCLA/>} />

                                 {/* Formularios CDI - Reale */}
                                 <Route path="/forms/reale/formTrab/:id/:view?" exact render={ () => <FormTrabPageREA/>} />
                                <Route path="/forms/reale/formProv/:id/:view?" exact render={ () => <FormProvPageREA/>} />
                                <Route path="/forms/reale/formDir/:id/:view?" exact render={ () => <FormDirPageREA/>} />
                                <Route path="/forms/reale/formTravel/:id/:view?" exact render={ () => <FormTravelPageREA/>} />
                                <Route path="/forms/reale/formFP/:id/:view?" exact render={ () => <FormFPPageREA/>} />
                                <Route path="/forms/reale/formSoc/:id/:view?" exact render={ () => <FormSocPageREA/>} />
                                <Route path="/forms/reale/formGift/:id/:view?" exact render={ () => <FormGiftPageREA/>} />

                                {/* Formularios CDI - Nuevo Capital */}
                                <Route path="/forms/nuevocapital/formTrab/:id/:view?" exact render={ () => <FormTrabPageNC/>} />
                                <Route path="/forms/nuevocapital/formProv/:id/:view?" exact render={ () => <FormProvPageNC/>} />
                                <Route path="/forms/nuevocapital/formDir/:id/:view?" exact render={ () => <FormDirPageNC/>} />

                                {/* Formularios CDI - BTG */}
                                <Route path="/forms/btgpactual/formTrab/:id/:view?" exact render={ () => <FormTrabPageBtg/>} />
                                <Route path="/forms/btgpactual/formPatr/:id/:view?" exact render={ () => <FormPatrPageBtg/>} />

                                {/* Formularios CDI - Santander Consumer */}
                                <Route path="/forms/santanderconsumer/formTrab/:id/:view?" exact render={ () => <FormTrabPageSC/>} />

                                {/* Formularios CDI - Famae */}
                                <Route path="/forms/3/formTrab/:id/:view?" exact render={ () => <FormTrabPageFam/>} />

                                {/* Rutas genericas CDI*/}
                                <Route path="/forms/:cliente/formTrab/:id/:view?" exact render={ () => <GenFormTrabPage/>} />
                                <Route path="/forms/:cliente/formProv/:id/:view?" exact render={ () => <GenFormProvPage/>} />
                                <Route path="/forms/:cliente/formDir/:id/:view?" exact render={ () => <GenFormDirPage/>} />

                                <Route path="/forms/:cliente/formFP/:id/:view?" exact render={ () => <GenFormFpPage/>} />
                                <Route path="/forms/:cliente/formSoc/:id/:view?" exact render={ () => <GenFormSocPage/>} />
                                <Route path="/forms/:cliente/formGift/:id/:view?" exact render={ () => <GenFormGiftPage/>} />
                                <Route path="/forms/:cliente/formTravel/:id/:view?" exact render={ () => <GenFormTravelPage/>} />
                                <Route path="/forms/:cliente/formValues/:id/:view?" exact render={ () => <GenFormValuesPage/>} />
                                <Route path="/forms/:cliente/formPep/:id/:view?" exact render={ () => <GenFormPepPage/>} />

                                <Route path="/forms/:cliente/formColab/:id/:view?" exact render={ () => <GenFormColabPage/>} />

                                {/* Formularios KYC - OnBoarding */}
                                <Route path="/forms/parauco/formKyc/:id/:view?" exact render={ () => <FormKycCliPageParauco/>} />
                                <Route path="/forms/parauco/formKycCli/:id/:view?" exact render={ () => <FormKycCliPageParauco/>} />
                                <Route path="/forms/parauco/formKycProv/:id/:view?" exact render={ () => <FormKycProvPageParauco/>} />
                                <Route path="/forms/arauco-malls/formKyc/:id/:view?" exact render={ () => <FormKycPageAraucoMalls/>} />
                                
                                <Route path="/forms/:client/formKycProv/:id/:view?" exact render={ () => <FormKycPage/>} />
                                <Route path="/forms/:client/formKycTrab/:id/:view?" exact render={ () => <FormKycPage/>} />
                                <Route path="/forms/:client/formKycDir/:id/:view?" exact render={ () => <FormKycPage/>} />
                                <Route path="/forms/:client/formKycCli/:id/:view?" exact render={ () => <FormKycPage/>} />
                                <Route path="/forms/:client/formKyc/:id/:view?" exact render={ () => <FormKycPage/>} />
                                {/****************************/}
 
                                <Route path="/portal/cl" exact render={ () => <StakeholderPortalPage countryCode="CHI"/>} />
                                <Route path="/portal/co" exact render={ () => <StakeholderPortalPage countryCode="COL"/>} />
                                <Route path="/portal/cr" exact render={ () => <StakeholderPortalPage countryCode="CR"/>} />
                                <Route path="/portal/pe" exact render={ () => <StakeholderPortalPage countryCode="PER"/>} />

                                <Route path="/portal/:domain" exact render={ () => <StakeholderPage/>} />
                                <Route render={ () => <NotFoundPage /> } />
                                </Switch>
                                {isModalChangePasswordVisible &&
                                    <ModalChangePassword
                                        visible={true}
                                        onOk={this.handleSaveChangePassword.bind(this)}
                                        onCancel={
                                        this.handleCloseModalChangePassword.bind(this)}
                                        isForced = {changePasswordForced}
                                        isExpiredPassword = {LocalStorageService.read("isPasswordBlocked") === "true"}
                                        isPwdNextToExpired = {isModalWantToChangePwdVisible}  //!== null
                                    />
                                }
                                {isModalWantToChangePwdVisible &&
                                    <Modal
                                        title="¡Atención!"
                                        visible={true}
                                        onOk= {() => {
                                            this.handleOpenModalChangePassword();
                                            this.handleCloseModalWantToChangePwdModal();
                                        }}
                                        onCancel={this.handleCloseModalWantToChangePwdModal.bind(this)}
                                        okText="cambiar"
                                        cancelText="más tarde"
                                    >
                                        <p>Tu contraseña expirará en {currentUser.daysToExpiredPwd} días. Para garantizar el acceso continuo a tu cuenta, recomendamos que actualices tu contraseña antes de que expire el período.</p>
                                    </Modal>
                                }
                            </Layout>
                        </Router>
                    </globalContext.Provider>
                </I18nextProvider>
            )
        }
    }
}

ReactDOM.render(<App />, document.getElementById('app'))
