import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

const cdi = {
  upload: (fileData) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/uploadNewRequest',
      method: 'post',
      body: fileData,
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    })
  },
  newSendRequest: (type, bdParams) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/cdi/newSendRequest/' +type,
      method: 'post',
      body: bdParams
    })
  },
  getParamsUboFinder: (clientId) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/cdi/getParamsUboFinderByClient',
      method: 'post',
      body: {
        clientId : clientId
      }
    })
  }
}

export default cdi