import apiConfig from '../config/api'
import { apiRequestorHelper } from '../helpers'

export default {
  create: (username, password, time) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/auth/authenticate',
      method: 'post',
      body: {
        username,
        password,
        time
      }
    })
  },

  renew: () => {
    return apiRequestorHelper({
      url: apiConfig.url + '/auth/refreshToken',
      method: 'post',
      body: {
        time: new Date().getTime()
      }
    })
  },
  loginTokenEmail: (clientId, email, token) => {
    return apiRequestorHelper({
      url: apiConfig.url + '/auth/loginTokenEmail',
      method: 'post',
      body: {
        clientId,
        email,
        token
      }
    })
  },
}
