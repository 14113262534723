import './UboFinder.scss'
import React, {useState, useEffect} from 'react';
import {Row, Col, Icon, Button, Input, Table, Modal, Spin, Tabs, notification} from 'antd';
import {getClientTreePromise, getClientDetailPromise, sendFormC57Promise } from './promises';
import {getC57FormHistoryPromise} from '../../../../../AdminParams/promises'
import { useTranslation } from 'react-i18next'
import { camelizerHelper } from '../../../../../../helpers';
import { ReportService } from '../../../../../../services';
import apiConfig from '../../../../../../config/api'
import TextArea from 'antd/lib/input/TextArea';
import { validateFormPromise, saveDDAValidatedPromise } from '../../../../promises';
import moment from "moment/moment";


const UboFinder = ({currentUser, itemRecord, parametersClient}) => {
    const itemTable = itemRecord.clCliente
    const [seeMore, setSeeMore] = useState(false);
    const [seeMore2, setSeeMore2] = useState(false);
    const [sendFormComment, setSendFormComment] = useState(null);
    const [hasFormComment, setHasFormComment] = useState(false);
    const [details, setDetails] = useState({});
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState(null);
    const [tree, setTree] = useState([]);
    const [isModalUbosVisible, setisModalUbosVisible] = useState(false);
    const [loadingTree, setLoadingTree] = useState(false);
    const [isAttachmentVisible, setIsAttachmentVisible] = useState(false);
    const [c57FormHistory, setC57FormHistory] = useState(false);
    const { TabPane } = Tabs;


    useEffect(() => {
        getClientTreePromise(itemTable.id).then(response => {
            setTree(response)
            setLoadingTree(false)
        })

       getC57FormHistoryPromise(itemTable.id).then( response => {
           setC57FormHistory(response.filter(record => record.id !== itemTable?.form?.id));
       })

        init(itemTable.id)
    }, []);

    useEffect(() => {
      if(hasFormComment) sendForm57();
    }, [hasFormComment]);

    const init = async (id) => {
        setLoading(true)
        let tmpDetails = itemRecord
        if(tmpDetails.form !== null && tmpDetails.form.ddaValidate !== null) {
            tmpDetails = setErrors(tmpDetails, tmpDetails.form.ddaValidate)
        }
        setDetails(tmpDetails)
        setRecord(tmpDetails.clCliente)
        
        setLoading(false)
    }

    const exportTree = async () => {
        await ReportService.read('/exportPropietarios/'+itemTable.id, null, null, 'propietarios.txt')
    }

    const exportUbos = async () => {
    await ReportService.read('/ubo/exportUBOS/'+itemTable.id, null, null, 'ubos.txt')
    }

    const setErrors = (details, ddaValidate) => {
        if(ddaValidate !== null && ddaValidate.errors !== null) {
          for(let i in ddaValidate.errors) {
            let error = ddaValidate.errors[i]
            if(error.code === 'E03' || error.code === 'E05') {
              if(details.ubosForm !== null) {
                for(let f in details.ubosForm) {
                  if(details.ubosForm[f].rut === error.rut) {
                    details.ubosForm[f].error = error
                  }
                }
              }
            }
            if(error.code === 'E04' || error.code === 'E05') {
              if(details.entity !== null && details.entity.propietarios !== null) {
                for(let f in details.entity.propietarios) {
                  if(details.entity.propietarios[f].rut === error.rut) {
                    details.entity.propietarios[f].error = error
                  }
                }
              }
            }
          }
        }
        return details
    }

    const getMallaPropiedad = (ubos) => {
        let records = []
        if(ubos !== null) {
          for(let i=0;i<ubos.length;i++) {
            if(ubos[i].origenMallaPropiedad === 'CLIENTE') {
              records.push(ubos[i])
            }
          }
        }
        return records
    }

    const getDataTree = (propietarios, level) => {
        if( propietarios !== undefined && propietarios !== null && propietarios.length > 0) {
          const list = []
          for (let i = 0; i < propietarios.length; i++) {
            list.push({ key: i+propietarios[i].id+level, rut: (propietarios[i].paisId === 1 ? propietarios[i].formatRut : propietarios[i].pais), nombre: propietarios[i].name, porcentaje: propietarios[i].participacionDirecta, type: propietarios[i].type, tipo: propietarios[i].relation, children: getDataTree(propietarios[i].propietarios, level+1) })
          }
          return list
        }
    }
    const openCertificate = () => {
      window.open(apiConfig.url + '/ubo/pdfUF?idPj='+itemTable.id+'&userId='+currentUser.id)
    }

    const completeForm = () => {
      window.open(apiConfig.urlAml + '/ubo/formC57FI?formId=' + itemTable.form.id + '&userId=' + currentUser.id)
  }

  const closeConfirmSend = () => {
      notification.close('confirm-send-form')
  }

  const handleAddCommentClick = () => {
    setHasFormComment(true)
  }

  const sendForm57 = () => {
      let btn =
          <div className="message-buttons-confirm">
              <Button type="ghost" onClick={ closeConfirmSend }>{ t('messages.aml.btnClose') }</Button>&nbsp;
              <Button type="primary" onClick={ sendFormClient }>{ t('messages.aml.ubofinder.sendForm') }</Button>
          </div>
      let description = <div>
          { t('messages.aml.ubofinder.confirmSendForm') }<br/>
          { !hasFormComment ?
              <div className="addComment" onClick={ handleAddCommentClick }>
                  <span>Agregar comentario</span>
                  <Button type="primary" shape="circle" icon="plus" size="small" />
              </div>
          :
            <TextArea id="add-comment" rows={2} onChange={ (e) => setSendFormComment(e) }/> 
          }
          </div>

      notification.info({
          key: 'confirm-send-form',
          message: t('messages.aml.confirm'),
          description,
          duration: 10,
          btn
      })
  }

  const sendFormClient =  async () => {
      await sendFormC57Promise(itemTable.id, sendFormComment)

      notification.close('confirm-send-form')
  }

  const closeConfirm = () => {
    notification.close('confirm-validate')
  }

  const confirmValidate = async (id, updateMalla) => {
    await saveDDAValidatedPromise(id, updateMalla)
    notification.close('confirm-validate')
    init(id)
  }

  const saveValidated = (id, mensaje, updateMalla) => {
    let btn =
        <div className="message-buttons-confirm">
          <Button type="ghost" onClick={ closeConfirm }>{ t('messages.aml.btnClose') }</Button>&nbsp;
          <Button type="primary" onClick={ () => confirmValidate(id, updateMalla) }>{ t('messages.aml.accept') }</Button>
        </div>
    notification.info({
      key: 'confirm-validate',
      message: t('messages.aml.confirm'),
      description: mensaje,
      duration: 10,
      btn
    })
  }

  const validateForm = async() => {
      const validate = await validateFormPromise(itemTable.id)
      if(validate.status === 'OK') {
          saveValidated(itemTable.id, '¿Desea aprobar el formulario y pasar a Monitoreo?', 'N')
      }else if(validate.status === 'WARNING') {
          let d = await getClientDetailPromise(itemTable.id)
          d = setErrors(d, validate)
          setDetails(d)
          saveValidated(itemTable.id, '¿Desea aprobar igualmente con diferencia de porcentajes?', 'N')
      }else { //ERROR
          if ( validate.errors[0].code === 'E01' || validate.errors[0].code === 'E02' ) {
            notification.error({
                message: t('messages.aml.notifications.anErrorOcurred'),
                description:  validate.errors[0].message
            })
          }else {
            let d = await getClientDetailPromise(itemTable.id)
            d = setErrors(d, validate)
            setDetails(d)

            let msg = <div><p className="confirm-message warning-message">Hay inconsistencias en declaracion de formulario con Malla de propiedad.</p>
                    <p className="confirm-message">¿Desea aceptar el formulario y pasar a monitoreo?</p></div>
            saveValidated(itemTable.id, msg, 'Y');
          }
      }
  }
  const handleDownloadAttachment = (fileName, formId) => {
    ReportService.read('/inputFile', {path: 'dda/c57forms/'+formId+'/'+fileName}, null, fileName)

    // '/inputFile', {path: 'tmp/' + errorsUpload}, null, 'reporte.xlsx'
  }

    const mallaTableColumns = [
        {
          title: t('messages.aml.rut'),
          dataIndex: 'rut',
          render: (text, record) => {
            if(record.pais === 'NACIONAL') {
              return text
            }
          }
        },
        {
          title: t('messages.aml.name'),
          dataIndex: 'name',
          render: (text) => camelizerHelper(text),
          sorter: (a, b) => a.name.localeCompare(b.name)
        },
        {
          title: t('messages.aml.participation'),
          dataIndex: 'participacionDirecta',
          render: (text) => {
            if (text !== null) {
              return (Math.round(text * 100)/100) + '%'
            }
          },
          sorter: (a, b) => a.participacionDirecta - b.participacionDirecta
        },
        {
          title: t('messages.aml.citizenship'),
          dataIndex: 'nacionalidad',
          render: (text) => camelizerHelper(text)
        },
        {
          title: 'UBO',
          dataIndex: 'ubo',
          render: (text) => text ? 'Si' : 'No'
        },
        {
          title: t('messages.aml.differences'),
          dataIndex: 'error',
          whiteSpace: 'nowrap',
          render: (error) => {
            if(error !== undefined && error !== null) {
              return {
                  props: {
                    className: 'error-' + error.code,   // there it is!
                  },
                  children: <div className="nowrap">{ error.message }</div>
              }
            }
          }
        }
    ]

    const formTableColumns = [
      {
        title: t('messages.aml.rut'),
        dataIndex: 'rut'
      },
      {
        title: t('messages.aml.name'),
        dataIndex: 'nombre',
        render: (text) => camelizerHelper(text),
        sorter: (a, b) => a.nombre.localeCompare(b.nombre)
      },
      {
        title: t('messages.aml.participation'),
        dataIndex: 'participacion',
        render: (text) => {
          if (text !== null) {
            return (Math.round(text * 100)/100) + '%'
          }
        },
        sorter: (a, b) => a.participacion - b.participacion
      },
      {
        title: t('messages.aml.title.country'),
        dataIndex: 'pais'
      },
      {
        title: t('messages.aml.title.city'),
        dataIndex: 'ciudad',
        render: (text) => camelizerHelper(text)
      },
      {
        title: t('messages.aml.address'),
        dataIndex: 'direccion',
        render: (text) => camelizerHelper(text)
      },
      {
        title: t('messages.aml.differences'),
        dataIndex: 'error',
        whiteSpace: 'nowrap',
        render: (error) => {
          if(error !== undefined && error !== null) {
            return {
                props: {
                  className: 'error-' + error.code,   // there it is!
                },
                children: <div className="nowrap">{ error.message }</div>
            }
          }
        }
      }
    ]

    const tableColumnsTree = [
      {
        title: t('messages.aml.rut'),
        dataIndex: 'rut',
        render: (text, record) => {
          return {
              props: {
                className: 'entity-type-'+record.type,   // there it is!
              },
              children: text
          }
        }
      },
      {
        title: t('messages.aml.name'),
        dataIndex: 'nombre',
        render: (text, record) => {
          return {
              props: {
                className: 'entity-type-'+record.type,   // there it is!
              },
              children: camelizerHelper(text)
          }
        },
        sorter: (a, b) => a.nombre.localeCompare(b.nombre)
      },
      {
        title: t('messages.aml.percent'),
        dataIndex: 'porcentaje',
        render: (text) => {
          if (text !== null) {
            return (Math.round(text * 100)/100) + '%'
          }
        },
        sorter: (a, b) => a.porcentaje - b.porcentaje
      },
      {
        title: t('messages.aml.type'),
        dataIndex: 'tipo',
        render: (text) => camelizerHelper(text)
      }
    ]

      const tableColumnsUbos = [
        {
          title: t('messages.aml.rut'),
          dataIndex: 'ubo.rut',
          render: (text, record) => {
            if(record.ubo.pais === 'NACIONAL') {
              return text
            }
          }
        },
        {
          title: t('messages.aml.name'),
          dataIndex: 'ubo.name',
          render: (text) => camelizerHelper(text),
          sorter: (a, b) => a.ubo.name.localeCompare(b.ubo.name)
        },
        {
          title: t('messages.aml.national'),
          dataIndex: 'ubo.pais',
          render: (text) => camelizerHelper(text)
        },
        {
          title: t('messages.aml.participation'),
          dataIndex: 'participacion',
          render: (text) => {
            if(text !== null) {
              return (Math.round(text * 100)/100) + '%'
            }
          },
          sorter: (a, b) => a.participacion - b.participacion
        },
        {
          title: 'Pactos',
          dataIndex: 'pactos',
          render: (pactos) => {
            if(pactos !== null) {

            }
          }
        },
        {
          title: 'UBO',
          dataIndex: 'ubo',
          render: (ubo) => ubo ? 'Sí' : 'No',
          sorter: (a, b) => a.ubo === b.ubo ? 0 : 1
        }
      ]

      const attachmentsColumns = [
        {
          title: "Nombre",
          render: (text) => <Button type="link" onClick={() => handleDownloadAttachment(text, itemTable?.form?.id)}>{text}</Button>
        },
        {
          render: () => <Icon type="vertical-align-bottom" style={{fontSize: 20}} />,
          width: '8%'
        }
      ]


    const openPdfForm = (formId) => {
        const id = (formId !== undefined)? formId : itemTable.form.id
        window.open(apiConfig.url + '/ubo/pdfFormUF/' + id)
    }

    const formatDateColumn = ( text ) => {
        return text ? moment(text).format('DD/MM/YYYY') : "Sin información"
    }

    const formC57HistoryColumns = [
        {
            title: t('messages.aml.status'),
            dataIndex: "status",
            render: (text) => {
                return camelizerHelper(text);
            }
        },

        {
            title: "versión",//t('messages.aml.version'),
            dataIndex: "version",
            render: (text) => {
                return text;
            }
        },
        {
            title: t('messages.aml.shippingDate'),
            dataIndex: "fecEnvio",

            render: (text) => {
                return formatDateColumn(text)
            }
        },
        {
            title: t('messages.aml.receiveDate'),
            dataIndex: "fecRecibido",
            render: (text) => {
                return formatDateColumn(text)
            }
        },
        {
            title: t('messages.aml.form57LastMailDate'),
            dataIndex: "fecEnvioMail",
            render: (text) => {
                return formatDateColumn(text)
            }
        },
        {
            title: t('messages.aml.deadlineDate'),
            dataIndex: "fecPlazo",
            render: (text) => {
                return formatDateColumn(text)
            }
            },
        {
            title: `${t('messages.aml.warningDays')} / ${t('messages.aml.termDays')}`,
            dataIndex: "clCliente.plazo",
            render: (text) => {
                return text;
            }
        },
        {
            title: `${t('messages.aml.download')}`,
            dataIndex: "id",
            render: (text) => <Button icon="file-pdf" onClick={() => openPdfForm(text)} size="small" />
        }
    ];


    return (
        <div className='div-modal-ubofinder-record'>
        {parametersClient && parametersClient.ubofinder && !parametersClient.ubofinder.noInvestigaMalla && itemTable.processUF &&
          <>
            <Row>
                <Col span={22}>
                    <h3>Mallas de Propiedad</h3>
                </Col>
                <Col span={2} style={{textAlign:'right'}}>
                    <Button type="link" onClick={() => {setSeeMore(!seeMore)}}>
                        {seeMore ?
                        <>Ver menos <Icon type="minus"/></>
                        :
                        <>Ver más <Icon type="plus"/></>
                        }
                    </Button>
                </Col>
            </Row>
            <hr/>
            {seeMore &&
              <div className='mallas-propiedad-main-wrapper'>
                  <Row gutter={[50]}>
                      <Col span={6}>
                          Estado
                          <Input value={t('messages.aml.malla.' + record.ddaStatus)} readOnly/>
                      </Col>
                      <Col span={6}>
                          Fecha recepción de la malla
                          <Input value={record.strDdaDate} readOnly/>
                      </Col>
                  </Row>
                  <Row style={{marginTop:20}}>
                      <Col span={8}>
                          Beneficiarios finales
                      </Col>
                      <Col span={3} offset={10} style={{textAlign: 'right'}}>
                          {record?.ddaStatus === 'ENTREGADO' &&
                            <Button onClick={() => setisModalUbosVisible(true)}>Ver Malla</Button> 
                          }
                      </Col>
                      <Col span={3} style={{textAlign: 'right'}}>
                          <Button icon="file-pdf" onClick={openCertificate}>Certificado</Button>
                      </Col>
                  </Row>
                  <Row style={{marginTop:20}}>
                      <Col span={24}>
                          { details?.entity?.propietarios?.length > 0 ?
                            <Table dataSource={getMallaPropiedad(details.entity.propietarios)}
                                  columns={mallaTableColumns} pagination={true} size='small'/>
                            :
                            <>
                              {record?.ddaStatus === 'ENTREGADO' ?
                                  <h3 className="no-info-red">{t('messages.aml.noInfoPossibleAboutFinalBeneficiaries')}</h3>
                                  :
                                  <h3 className="no-info">{t('messages.aml.noInfoAboutFinalBeneficiaries')}</h3>
                              }
                            </>
                          }
                      </Col>
                  </Row>
              </div>
            }
          </>
        }
        {!itemTable.formularioInterno && parametersClient && parametersClient.ubofinder && !parametersClient.ubofinder.sinFormulario &&
          <>
            <Row>
                <Col span={22}>
                    <h3>{t('messages.aml.ubofinder.title.form')}</h3>
                </Col>
                <Col span={2} style={{textAlign:'right'}}>
                    <Button type="link" onClick={() => {setSeeMore2(!seeMore2)}}>
                        {seeMore2 ?
                        <>Ver menos <Icon type="minus"/></>
                        :
                        <>Ver más <Icon type="plus"/></>
                        }
                    </Button>
                </Col>
            </Row>
            <hr/>

            {seeMore2 &&
                <div className='mallas-propiedad-main-wrapper'>

                  { c57FormHistory && c57FormHistory.length > 0 &&
                  <>
                    <Row>
                        <h3>{t('messages.aml.c57FormHistory')}</h3>
                        <Col span={24}>
                          <Table
                              columns={formC57HistoryColumns}
                              dataSource={c57FormHistory}
                              size="small"
                              pagination={true}>
                          </Table>
                        </Col>
                    </Row>
                    <hr className='separator-line'/>
                  </>
                  }
                  <h3>{t('messages.aml.ubofinder.current.form')}</h3>
                  <Row gutter={[50,20]}>
                      <Col span={4}>
                          { t('messages.aml.status') }
                          <Input value={ itemTable?.form?.status } readOnly/>
                      </Col>
                      <Col span={4}>
                          { t('messages.aml.shippingDate') }
                          <Input value={itemTable?.form?.strFecEnvio} readOnly/>
                      </Col>
                      <Col span={4}>
                          { t('messages.aml.form57LastMailDate') }
                          <Input value={moment(itemTable?.form?.fecEnvioMail).format("DD-MM-YYYY HH:mm")} readOnly/>
                      </Col>
                      <Col span={4}>
                          { t('messages.aml.receiveDate') }
                          <Input value={itemTable?.form?.strFecRecibido ?? "N/A"} readOnly/>
                      </Col>
                      <Col span={4}>
                          { t('messages.aml.deadlineDate') }
                          <Input value={itemTable?.form?.strShortFecPlazo} readOnly/>
                      </Col>
                      <Col span={4}>
                          { t('messages.aml.warningDays') } / { t('messages.aml.termDays') }
                          <Input value={itemTable?.alerta +" / "+ itemTable?.plazo} readOnly/>
                      </Col>
                  </Row>

                  <Row type="flex" justify="end" style={{marginTop:20}}>
                        { itemTable.form !== null && itemTable.form.status === 'ENVIADO' && parametersClient.ubofinder.formularioInterno === true &&
                          <Col span={3} style={{textAlign: 'right'}}>
                            <Button icon="form" onClick={ completeForm }>{ t('messages.aml.ubofinder.completeForm') }</Button>
                          </Col>
                        }
                        {  !parametersClient.ubofinder.noInvestigaMalla && itemTable.processUF && itemTable.form && itemTable.form.status === 'RECIBIDO' &&
                          <Col span={3} style={{textAlign: 'right'}}>
                            <Button icon="check-square" onClick={ validateForm }>{ t('messages.aml.ubofinder.validateForm') }</Button>
                          </Col>
                        }
                        {itemTable?.form?.files?.length > 0 &&
                          <Col span={3} style={{textAlign: 'right'}}>
                            <Button icon='file' onClick={() => setIsAttachmentVisible(true)}>Adjuntos</Button>
                          </Col>
                        }
                        <Col span={3} style={{textAlign: 'right'}}>
                            <Button icon="mail" onClick={ sendForm57 }>{ t('messages.aml.ubofinder.sendForm') }</Button>
                        </Col>

                        {itemTable.form !== null && (itemTable.form.status === 'RECIBIDO' || itemTable.form.status === 'VALIDADO' || itemTable.form.version > 1) &&
                          <Col span={3} style={{textAlign: 'right'}}>
                              <Button icon="file-pdf" onClick={() => {openPdfForm()}}> { t('messages.aml.download') } </Button>
                          </Col>
                        }
                  </Row>

                  <br/>
                  <h3>{t('messages.aml.finalBeneficiaries')}</h3>
                  <Row style={{marginTop:20}}>
                    <Col span={24}>
                      { details.ubosForm !== undefined && details.ubosForm !== null && details.ubosForm.length > 0 ?
                      <Table 
                        dataSource={ details.ubosForm }  
                        columns={ formTableColumns } 
                        pagination={ true } 
                        size='small' 
                      />
                      :
                      <h3 className="no-info">{ t('messages.aml.noInfoAboutFinalBeneficiaries') }</h3>
                      }
                    </Col>
                  </Row>
                </div>
            }
          </>
        }
       
          <Modal 
            title="Adjuntos"
            visible={isAttachmentVisible}
            width={ 900 }
            onCancel={ () => setIsAttachmentVisible(false) }
            footer={ [
              <Button onClick={ () => setIsAttachmentVisible(false) }>{ t('messages.aml.btnClose') }</Button>
            ] }
          >
            <Table dataSource={itemTable?.form?.files} columns={attachmentsColumns}/>
          </Modal>
        
        {isModalUbosVisible &&
            <Modal
              title={ t('messages.aml.ownershipTree') }
              visible={ true }
              onCancel={ () => setisModalUbosVisible(false) }
              width={ 900 }
              className="modal-tree"
              footer={ [
                <Button onClick={ () => setisModalUbosVisible(false) }>{ t('messages.aml.btnClose') }</Button>
              ] }
            >
              { loadingTree ?
                <Spin spinning={ true } size="large" />
                :
                <Tabs type="card">
                  <TabPane tab={ t('messages.aml.ownershipTree') } key="1">
                    <Button icon="file-text" style={{float: 'right', marginBottom: '5px'}} onClick={ exportTree }>{ t('messages.aml.export') }</Button>
                    <br/><br/>
                    <Table columns={ tableColumnsTree } dataSource={ getDataTree(tree.propietarios, 0) } size="small" pagination={ false } />
                  </TabPane>
                  <TabPane tab={ t('messages.aml.finalBeneficiaries') } key="2">
                    <Button icon="file-text" style={{float: 'right', marginBottom: '5px'}} onClick={ exportUbos }>{ t('messages.aml.export') }</Button>
                    <br/><br/>
                    <Table columns={ tableColumnsUbos } dataSource={ tree.ubos } size="small" pagination={ true } />
                  </TabPane>
                </Tabs>
              }
            </Modal>
          }
        </div>
    );
};

export default UboFinder;